import React from 'react'
import { useTranslation } from "react-i18next";

import styled from 'styled-components';
function TermsAndCond({terms, callback}) {
    const { t } = useTranslation();

    // const [terms, setTerms] = React.useState(state)
    const checkboxTerms = React.useRef();
// console.log(terms)
    // function checkboxTermsClick(e){
        // console.log(e)
        // terms = e;
    // }
console.log(terms)
    function clickConfirmTerms() {
        var value = checkboxTerms.current.checked;
        console.log(value);
        if(value){
            callback(value);
        } else {
            alert(t('ToUAlert'));
        }
    }

    function hyperlinkText(text, urls){
        const reactStringReplace = require('react-string-replace');
    
        var regex = /%&(.*?)%&/g;
        var reg = [...text.matchAll(regex)];
        
        function Anchor(string, url){
            return <a href = {url}>{string}</a>
        }
        for(let index in reg){
            var text = reactStringReplace(text,reg[index][0], (match, i)=>(
                Anchor(reg[index][1], urls[index])
            ))
        }
        return <>
        {text}
        </>;
    }
    return (<>   
        {
            terms ? <></> :
            <div style={{
                position:'fixed', 
                width:'100%', height:'100%', 
                left: 0, top:0, 
                zIndex: 10, 
                backgroundColor:'rgba(29, 78, 96, 0.25)'}} 
                id="termsPopupBg">
            
                <TermsModal id="termsPopup">
                    <div className={'mgb30'} style={{fontWeight: 700, fontSize:'1.3em'}}>{t("Terms of Use")}</div>
                    {/* <div className="" style={{margin:'20px 0', fontSize:'1em', color:'#5A5A59'}}>{t('policies2')}</div> */}
                    <div className="termsOfUseText" > 
                        <p>{t("ToU paraph 1")}</p>
                        <p><span>{t("ToU art 1 header 1")}</span></p>
                        <p><span>{t("ToU art 1 header 1.1")}</span></p>
                        <p><a href={t("ToU art 1 content 1.1 url")} target ="_blank">{t("ToU art 1 content 1.1 url-text")}</a> {t("ToU art 1 content 1.1 text")}</p>
                        <p><span>{t("ToU art 1 header 1.2")}</span></p>
                        <p>{t("ToU art 1 content 1.2")}</p>
                        <p><span>{t("ToU art 1 header 1.3")}</span></p>
                        <p>{t("ToU art 1 content 1.3")}</p>
                        <p><span>{t("ToU art 2 header 2")}</span></p>
                        <p><span>2.1.</span>{t("ToU art 2 content 2.1")}</p>
                        <p><span>2.2.</span>{t("ToU art 2 content 2.2")}</p>
                        <p><span>2.3.</span>{t("ToU art 2 content 2.3")}</p>
                        <p><span>2.4.</span>{t("ToU art 2 content 2.4")}</p>
                        <p><span>{t("ToU art 3 header 3")}</span></p>
                        <p><span>3.1.</span>{t("ToU art 3 content 3.1")}</p>
                        <p><span>3.2.</span>{t("ToU art 3 content 3.2")}</p>
                        <p><span>3.3.</span>{t("ToU art 3 content 3.3")}</p>
                        <p><span>3.4.</span>{t("ToU art 3 content 3.4")}</p>
                        <p><span>3.5.</span>{t("ToU art 3 content 3.5")}</p>
                        <p><span>3.6.</span>{t("ToU art 3 content 3.6")}</p>
                        <p><span>3.7.</span>{t("ToU art 3 content 3.7")}</p>
                        <p><span>{t("ToU art 4 header 4")}</span></p>
                        <p><span>4.1.</span>{t("ToU art 4 content 4.1")}</p>
                        <p><span>4.2.</span>{t("ToU art 4 content 4.2")}</p>
                        <p><span>4.3.</span>{t("ToU art 4 content 4.3")}</p>
                        <p><span>4.4.</span>{t("ToU art 4 content 4.4")}</p>
                        <p><span>4.5.</span>{t("ToU art 4 content 4.5")}</p>
                        <p><span>4.6.</span>{t("ToU art 4 content 4.6")}</p>
                        <p><span>{t("ToU art 5 header 5")}</span></p>
                        <p>{t("ToU art 5 content 5")}</p>
                        <p><span>{t("ToU art 6 header 6")}</span></p>
                        <p><span>6.1.</span>{t("ToU art 6 content 6.1")}</p>
                        <p><span>6.2.</span>{t("ToU art 6 content 6.2")}</p>
                        <p><span>6.3.</span>{t("ToU art 6 content 6.3")}</p>
                        <p><span>6.4.</span>{t("ToU art 6 content 6.5")}</p>
                        <p><span>6.5.</span>{t("ToU art 6 content 6.5")}</p>
                        <p><span>6.6.</span>{t("ToU art 6 content 6.6")}</p>
                        <p><span>{t("ToU art 7 header 7")}</span></p>
                        <p><span>7.1.</span>{t("ToU art 7 content 7.1")}</p>
                        <p><span>7.2.</span>{t("ToU art 7 content 7.2")}</p>
                        <p><span>7.3.</span>{t("ToU art 7 content 7.3")}</p>
                        <p><span>{t("ToU art 8 header 8")}</span></p>
                        <p>{hyperlinkText(t("ToU art 8 content 8"), [
                            t("ToU art 8 content 8 url 1"),
                            ])}</p>
                        <p><span>{t("ToU art 9 header 9")}</span></p>
                        
                        <p>{hyperlinkText(t("ToU art 9 content 9"), [
                            t("ToU art 8 content 9 url 1"),
                            ])}</p>
                        <p><span>{t("ToU art 10 header 10")}</span></p>
                        <p>{t("ToU art 10 content 10")}</p>
                        <p><span>{t("ToU art 11 header 11")}</span></p>
                        <p>{hyperlinkText(t("ToU art 11 content 11"), [
                            t("ToU art 11 content 11 url 1"),
                            ])}</p>
                        
                    </div>
                    <div className={'tbl full'} style={{padding:10}}>
                            <div className={'td l m'} style={{fontSize:'1em', color:'#5A5A59', cursor:'pointer'}}>
                                <input ref={checkboxTerms} type="checkbox" id="chckx_terms" style={{marginRight:15}}/>
                                <label htmlFor="chckx_terms">{t("I read and I accept the Terms of Use")}</label>.
                                </div>
                            <div className={'td r m'}>
                                <span style={{color:'#1fabe7', fontWeight:700, cursor:'pointer'}} 
                                onClick={clickConfirmTerms.bind(this)}>
                                    {t("Confirm")}
                                    </span>
                            </div>
                        </div>
                </TermsModal>
            
            </div>
        }
        </>  
    )
        
}


const TermsModal = styled.div`
    position:fixed;
    width:80%;
    height: 80vh;
    left:10%;
    top:10vh;
    padding:30px;
    margin:auto;
    z-index: 11;
    background-color:#fff;
    border-radius: 8px;
    box-shadow : 0px 25px 50px rgba(100, 116, 139, 0.25);
    
    .termsOfUseText{
        height:calc(100% - 90px);
        overflow-y:auto;
        white-space: pre-line;
        font-size: 15px;
        line-height: 150%;
        letter-spacing: 0.01em;
        p{
            margin-bottom:15px;
            span{            
                font-weight:bold;
            }
        }
        .tou.--bold{
            
            font-weight:bold;
        }
    }
`
export default TermsAndCond