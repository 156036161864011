import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { config } from "../../data/config";
import arrow from "../../assets/icons/arrow_down.svg";
import lang from "../../data/lang";
import { definedStyles } from "../../data/definedStyles";
import { withTranslation } from "react-i18next";

export class GeneralInfoTab extends Component {
    constructor(props) {
        super(props);
        //console.log(props);
        this.typeOfBuilding = config.typeOfBuilding;
        this.typeOfProject = config.typeOfProject;
        this.state = {infoToggle: false}
    }

    getBuildingType(id) {
        var name = "";
        this.typeOfBuilding.map((type) => {
            if (type.id === parseInt(id)) {
                name = type.name;
            }
            return name;
        });
        return name;
    }
    getProjectType(id) {
        var name = "";
        this.typeOfProject.map((type) => {
            //console.log(type, id);
            if (type.id === parseInt(id)) {
                name = type.name;
            }
            return name;
        });
        return name;
    }
    render() {
        const { t } = this.props;
        return (
            <DataContainer>
                <TitleBox onClick={()=>{this.setState({infoToggle: !this.state.infoToggle})}}>{t('detailsTitle')}
                    <img className={this.state.infoToggle ? "--open" : "--close"} src={arrow} />
                </TitleBox>
                <ProjDataContainer className = {this.state.infoToggle ? "proj-data-open" : "proj-data-close"}>
                    <Row>
                        <InfoCol>{t('companyName')}</InfoCol>
                        <InfoCol style={{color:definedStyles.color1}}>{this.props.info.company_name}</InfoCol>
                    </Row>
                    <Row>
                        <InfoCol>{t('typeOfBuilding')}</InfoCol>
                        <InfoCol style={{color:'gray'}}>{t(this.getBuildingType(
                                        this.props.info.type_of_building
                                    ))}</InfoCol>
                    </Row>
                    <Row>
                        <InfoCol>{t('typeOfProject')}</InfoCol>
                        <InfoCol style={{color:'gray'}}>{t(this.getProjectType(
                                        this.props.info.type_of_proj
                                    ))}</InfoCol>
                    </Row>
                    <Row>
                        <InfoCol>{t('projectLocation')}</InfoCol>
                        <InfoCol style={{color:'gray'}}>{this.props.info.proj_location}</InfoCol>
                    </Row>
                    <Row>
                        <InfoCol>{t('projectRealizationYear')}</InfoCol>
                        <InfoCol style={{color:'gray'}}>{this.props.info.proj_realization === '' ? '---': this.props.info.proj_realization}</InfoCol>
                    </Row>
                </ProjDataContainer>
                {/* <Row>
                    <Col lg={4} md={6}>
                        <InfoContainer>
                            <Img src={ico_user} alt="ico_user" />
                            <InfoHeader></InfoHeader>
                            <InfoData></InfoData>
                        </InfoContainer>
                    </Col>
                    <Col lg={4} md={6}></Col>
                    <Col lg={4} md={6}>
                        <InfoContainer>
                            <Img src={ico_home} alt="ico_home" />
                            <InfoHeader></InfoHeader>
                            <InfoData>
                                
                            </InfoData>
                        </InfoContainer>
                    </Col>
                    <Col lg={4} md={6}>
                        <InfoContainer>
                            <Img src={ico_location} alt="ico_location" />
                            <InfoHeader></InfoHeader>
                            <InfoData></InfoData>
                        </InfoContainer>
                    </Col>
                    <Col lg={4} md={6}>
                        <InfoContainer>
                            <Img src={ico_project} alt="ico_project" />
                            <InfoHeader></InfoHeader>
                            <InfoData>
                                
                            </InfoData>
                        </InfoContainer>
                    </Col>
                    <Col lg={4} md={6}>
                        <InfoContainer>
                            <Img src={ico_home} alt="ico_home" />
                            <InfoHeader></InfoHeader>
                            <InfoData>
                                
                            </InfoData>
                        </InfoContainer>
                    </Col>
                </Row> */}
            </DataContainer>
        );
    }
}
const Img = styled.img`
    width: 20px;
    position: absolute;
    top: 5px;
    left: 0px;
`;

const DataContainer = styled.div`
    padding: 0px;
    display: inline-block;
    width: 100%;
    @media (max-width: 959px) {
        padding: 8px 5px;
    }
`;
const TitleBox = styled.div`
    padding-top:8px;
    padding-bottom:8px;
    cursor:pointer;
    position: relative;
    img{
        position: absolute;
        right:0px;
        top:50%;
        transform:translate(0px, -50%);
        &.--open{
            transform:translate(0px, -50%) rotate(180deg);
        }
    }
`;
const ProjDataContainer = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align:left;
    overflow: hidden;
    // padding-bottom:8px;

    
    &.proj-data-close{
        max-height:0px;
    }
    &.proj-data-open{
        max-height:unset;
    }

`;
const InfoCol = styled(Col)`
    padding-top:8px;
    padding-bottom:8px;
`;
const InfoHeader = styled.p`
    font-size: 12px;
    margin: 0px;
`;
const InfoData = styled.p`
    font-size: 14px;
    margin: 0px;
    font-weight: 500;
`;
export default withTranslation()(GeneralInfoTab);
