

class UserApi{
    // _url = './users.php';
    // _url = window.location.protocol+'//test.hybrid.pl/knauf_insulation/carbon/users.php';
    // _url = window.location.protocol+'//localhost/knauf/carbon/public/users.php';
    _url = window.location.protocol+'//ki-impact.knaufinsulation.fr/users.php';
    
    token = ''
    user = {}
    
    get_data(token){
        return new Promise(resolve=>{
            if(token !== undefined && token !==''){
                fetch(this._url+'?co=get_data&token='+token)
                    .then((response) => response.json())
                        .then((data)=>{
                            resolve(data)
                        })
            }else{
                resolve(false);
            }
        })
    }
    refresh(token){
        console.warn('request refres '+token)
        return new Promise(resolve=>{
            // console.log(token)
            if(token !== undefined && token !==''){
                fetch(this._url+'?co=refresh_token&token='+token)
                    .then((response) => response.json())
                        .then((data)=>{    
                            // console.log(data)                    
                            if(!data.ok){
                                resolve(false);
                            } else {
                                resolve(data);
                            }
                        })
            } else {
                resolve(false);
            }
        })
    }

    signIn(login, pass){
        return new Promise((resolve, reject) => {
            var httpReq = new XMLHttpRequest(); 
            httpReq.onreadystatechange = () => {
                if (httpReq.readyState === 4) { 
                    if (httpReq.status === 200) {
                        var data = JSON.parse(JSON.parse(httpReq.responseText, true), true);
                        resolve(data); 
                    } else { 
                        reject(new Error(httpReq.statusText)); 
                    } 
                } 
            }
            httpReq.open("POST", this._url+'?co=sign_in', true); 
            httpReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            httpReq.send("login="+login+"&psswd="+pass); 
            
        });
    }

    // static getData(token){
    //     return new Promise((response2) => {
    //         if(response2) {
    //             console.log(response2);
    //             data.currentToken = response2.token;
    //             setCookie('carbon-user', response2.refresh_token);
    //             setUser({userData:response2.user, rtoken:response2.refresh_token,token:response2.token });
    //         }else{
    //             removeCookie('carbon-user');
    //         }
    //     })
    // }
    register(lang){
        return this._url+'?co=register&lang='+lang;
        
    }
    reminder(lang){
        return this._url+'?co=reminder&lang='+lang;
    }
    setdata(hash, lang){
        return this._url+'?co=dataform&hash='+hash+'&lang='+lang;
    }

}

const userApi = new UserApi();
export { userApi };
