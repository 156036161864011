import React from 'react'

import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import FormField from "./formField";

function ProjectForm({form, formValues, itemCallback, validationCallback, editable=true}) {
    
    
    return <FormBox key={'form-box'}>
        <Row style={{
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            boxShadow: '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)'
        }}>
            {form.map((f) => (
                <Col md={6} key={f.name} style={{ paddingRight: 20 }}>
                    <FormField
                        key={'form-field' + f.name}
                        field={f}
                        value={formValues[f.name]}
                        callback={itemCallback}
                        validationCallback={validationCallback} 
                        editable = {editable}
                        />
                </Col>
            ))}
        </Row>
    </FormBox>;
}


const FormBox = styled.div`
    border-bottom: 0px solid black;
    padding-bottom: 15px;
    margin-bottom: 50px;
`;

export default ProjectForm