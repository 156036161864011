import React from "react";
import { project } from "../../data/project";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import SolutionPage from "./SolutionPage";
import CustomCheckbox from "../shared/CustomCheckbox";
import star from '../../assets/icons/favorite-star.svg'
import star_active from '../../assets/icons/favorite-star-active.svg'

function ProductsList({callback,selectedSolutions}) {
    
    const elements = project.elements;

    const [solutions, setSolutions] = React.useState(sortSolutions(project.solutions));

    function sortSolutions(solutions){
        var favs = solutions.filter((sol)=>{
            return sol.fav
        })
        var other = solutions.filter((sol)=>{
            return !sol.fav
        })
        console.log(favs.concat(other))
        return favs.concat(other);
    }
   
    function checkboxCallback(name, value){
        var id = name.replace('selectedSolutions_','');
        // console.log(selectedSolutions)
        // var selSols = selectedSolutions.map((solution, index) => {
        //     return parseInt(id) === solution.id ? id : solution;
        // });
        
        var selSols = selectedSolutions.filter((selSol)=>{
            return selSol == parseInt(id) ? false : true
        })
        if(value){
            selSols.push(parseInt(id))
        }
        callback(selSols);
    }
    function handleFavSol(e,solution){
        console.log(solution)
        solution.fav = !solution.fav;
        var newSolutions = solutions.map((sol)=>{
            if(solution.id == sol.id){
                return solution;
            }else {
                return sol;
            }
        })
        newSolutions = sortSolutions(newSolutions);
        setSolutions(newSolutions)
    }
    
    return (
        <Row>
            {solutions.map((solution, pos) => (
                <Col xs={12}
                    key={"solutions_list_" + pos}
                    style={{
                        marginBottom: "40px",
                        borderRadius: 8,
                        backgroundColor:'white',
                        

                    }}
                >
                    <Row className="d-flex align-items-center" style={{
                        padding:'16px 12px',
                        backgroundColor:'#F3F4F6'
                    }}>
                        <Col>
                            {solution.name}
                            {/* <CustomCheckbox 
                                value = {selectedSolutions.includes(solution.id)}
                                name = {'selectedSolutions_'+solution.id}
                                labelText = {solution.name}
                                callback = {checkboxCallback}
                            /> */}
                        </Col>
                        <Col xs={'auto'}>
                            <Img src={solution.fav ? star_active : star} onClick={(e)=>{handleFavSol(e,solution)}}/>
                        </Col>
                    </Row>
                    <SolutionPage solution={solution} pos={pos} />                   
                </Col>
                
            ))}
        </Row>
    );
}

const Img = styled.img`
    width:35px;
    height:35px;
    padding:10px;
    border-radius:4px;
    background-color:white;
    cursor:pointer;
    // position: relative;
    // top: -5px;
`;

export default ProductsList;
