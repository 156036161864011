
import { data } from "../../data/data"
import {userApi} from "../../api/userApi";

export default class UserController{
    rtoken = null;
    token = null;
    user = null;
    editDataUrl = '';

    constructor(cookieToken = null){
        this.rtoken = cookieToken;
    }
    setUser(user){
        this.user = user;
    }
    clearUser(){
        this.rtoken = null;
        this.token = null;
        this.user = null;
        this.editDataUrl = '';        
    }


    refreshToken(){
        return new Promise(resolve =>{
            
            if(this.rtoken) {
                console.log(this.user, this.rtoken);
                if(this.user === null) {
                    // console.log(this.rtoken)
                    userApi.refresh(this.rtoken).then((response)=>{
                        if(response) {
                            userApi.get_data(response.token).then((response2)=>{
                                if(response2) {
                                    data.currentToken = response2.token;
                                    data.currentUser = response2.user.id;
                                    this.editDataUrl = userApi.setdata(response2.token, 'fr')
                                    console.log(this.editDataUrl)
                                    // setCookie('carbon-user', response2.refresh_token, {maxAge: 2592000});
                                    this.setUser({userData:response2.user, rtoken:response2.refresh_token,token:response2.token });
                                    this.rtoken = response2.refresh_token;
                                    resolve(response2.refresh_token)
                                }else{
                                    data.currentToken = '';
                                    data.currentUser = null;
                                    this.editDataUrl = "";
                                    this.rtoken = null;
                                    resolve(null)
                                    // removeCookie('carbon-user');
                                }
                            })
                        }else{
                            // console.log(response)
                            data.currentToken = '';
                            data.currentUser = null;
                            this.editDataUrl = "";
                            this.rtoken = null;
                            resolve(null)
                            // removeCookie('carbon-user');
                        }
                        
                    })
                }
            }else{
                // console.log(this.rtoken)
                // console.log(this.user)
                if(this.user!== null){
                    data.currentToken = this.user.token;
                    data.currentUser = this.user.userData.id;
                    this.rtoken = this.user.rtoken;
                    resolve(this.user.rtoken)
                    // setCookie('carbon-user', user.rtoken, {maxAge: 2592000});
                }
            }
        })
        
    }
}