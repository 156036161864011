import React, { Component } from 'react'
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Modal from 'react-modal';
import GeneralInfoTab from './generalInfoTab';
import PotentialTab from './potentialTab';
import ControlTab from './controlTab';
import trash from "../../assets/icons/trash.svg";
import Api from '../../api/api';
import { withTranslation } from 'react-i18next';
import close from '../../assets/icons/close.svg';
import { t } from 'i18next';
import { definedStyles } from '../../data/definedStyles';

export class ProjectBox extends Component {
    name;
    constructor(props) {
		
        super(props);
        this.api = new Api();
        //console.log(props)
        //console.log(props.state)
        this.name = this.props.project.name
        this.callback = this.props.callback;
        this.state = {
            deleteModal: false,
        }
    }

    showModal(){
        this.setState({deleteModal:true})
        console.log('handle delete')
        
    }
    modalCallback(action){
        //console.log(this.proj_id)
        if(action == 'delete'){
            this.callback('delete',this.props.project.id);
            this.api.deleteProject(this.props.project.id);

            this.setState({deleteModal:false})
        } else {
            this.setState({deleteModal:false})

        }

    }

    render() {
        const { t } = this.props;
        return (
            <Col md={6} sm={12} lg={4}>
                <div style={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    boxShadow: '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
                    padding:'24px',
                    marginBottom:'20px',
                }}>
                <TitleBox>
                    {this.name}
                    <Controls>
                        <ControlTab 
                            info={this.props.project} 
                            callback={this.callback}
                            />
                    </Controls>
                </TitleBox>
                <ContentBox>                    
                    <PotentialTab info={this.props.project.gwp}/>
                    <Separator />
                    <GeneralInfoTab info={this.props.project.general_data} />
                    <Separator />
                    <DateInfo className = "d-flex justify-content-between align-items-center">
                        <p>{t('creationDate')} {parseDate(this.props.project.date)} - {t('modificationDate')} {parseDate(this.props.project.m_date)}</p>
                        <Button onClick={()=>this.showModal()} className="ms-auto p-2 bd-highlight">{t('delete')}  <Img src={trash} /></Button>
                        <DeleteModal state={this.state.deleteModal} callback={this.modalCallback.bind(this)} />
                    </DateInfo>
                </ContentBox>
                </div>
            </Col>
        )
    }
}
function DeleteModal({state, callback}){
    function handleCloseModal(){
        callback('close');
    }
    function handleClick(e, action){
        callback(action);
    }
    return (
    <Modal 
            centered
            ariaHideApp={false}
            isOpen={state}
            style={{
                overlay:{
                    zIndex:'100'                    
                },                    
                content: {
                    width: 550,
                    height: 270,
                    borderRadius:8,
                    margin:'auto'
                }
            }}
        >
            
            <div onClick={handleCloseModal} style={{ 
                position:'absolute',
                top:'15px',
                right:'15px',
                cursor: 'pointer'
            }}>
                <img alt='x' src={close} />
            </div>
            <HeaderModal>
                {t('Delete project')}?
            </HeaderModal>
            <TextModal>
                <p>{t("You are about to delete this project.")}</p>
                <p>{t("By deleting this, you won't be able do recover your data.")}</p>
                <p>{t("Are you sure you want to delete it?")}</p>
            </TextModal>
            <Row>
                <Col><ButtonModal onClick={(e)=>handleClick(e, 'delete')}>{t("Delete my project")}</ButtonModal></Col>
                <Col><ButtonModal onClick={(e)=>handleClick(e, 'cancel')} className="--cancel">{t("Cancel")}</ButtonModal></Col>
            </Row>
            
            
        </Modal>
    )
}

function parseDate(date){
    var parts =date.split('-');
    return parts[2]+'/'+parts[1]+'/'+parts[0];
}

const HeaderModal = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: 0.01em;
    margin-bottom:40px;
`;
const TextModal = styled.div`

        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        /* or 22px */
        color: #5A5A59;
        text-align: center;
        letter-spacing: 0.01em;
        margin-bottom:40px;
    p{
        margin:0px;
        text-align:center;
    }
`;
const TitleBox = styled.div`
    // padding: 15px 20px;
    color: black;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
`;
const DateInfo = styled.div`
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.04em;
    color: #969694;
    padding: 0px;

    p{
        display:inline-block;
        vertical-align: top;
        margin:0px;
    }
`;
const Controls = styled.div`
    position:absolute;
    right: 0px;
    top:50%;
    transform: translate(0px, -50%);
`
const Separator = styled.hr`
    color:lightgray;
    margin-top:8px;
    margin-bottom:10px;
`
const Img = styled.img`
    width:12px;
    position: absolute;
    top: 50%;
    right:5px;
    transform: translate(0px, -50%);
`;
const Button = styled.div`
    display:inline-block;
    vertical-align: top;
    color: #D12724;
    border: 1px solid #D12724;
    border-radius: 8px;    
    font-size: 12px;
    padding: 2px 5px;
    padding-right: 20px !important;
    cursor:pointer;
    position:relative;
    @media (max-width: 991px) {
        text-align: center;
    }
    
`;
const ButtonModal = styled(Button)`
    font-weight: 650;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background-color: #D12724;
    padding: 10px 16px;
    width:100%;
    text-align: center;

    &.--cancel{
        color:${definedStyles.color1};
        background-color:white;
        border:1px solid ${definedStyles.color1};
    }
`;
const ContentBox = styled(Row)`
    //padding: 15px 20px;
    margin-left: 0px;
    margin-right: 0px;
`;
const ProjectDetails = styled.div`
`;
const ContentBoxCol2 = styled(Col)`
    // width:25%;
    display: inline-block;
    border: 1px solid gray;
`;

export default withTranslation()(ProjectBox);
