import React from 'react'
import UserContainer from '../user/userContainer'

function SignIn({currentTab, callback}) {
    console.log(currentTab)
    return (
        <div style={{width:'100%', height:'100%'}}>
            <UserContainer mode={currentTab} callback={callback} />
        </div>
    )
}

export default SignIn
