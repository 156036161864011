import React from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { definedStyles } from "../data/definedStyles";
import lang from "../data/lang";

function CookiePop() {
    const [cookiePop, setCookiePop] = useCookies(["carbon-cookie"]);

    const { t } = useTranslation();

    function handleAccept(){
        console.log(cookiePop)
        setCookiePop('carbon-cookie',true, {maxAge: 2592000});
    }

    function CookieBar() {
        console.log(cookiePop['carbon-cookie'])
        return (
            <div
                style={{
                    position: "fixed",
                    width: "100vw",
                    padding:'20px',
                    bottom: "0px",
                    left: "0px",                    
                    backgroundColor:definedStyles.color1,
                    color:'white',
                    boxShadow:'0px 2px 6px gray',
                    zIndex:'100',
                    fontSize:'12px',
                }}
            >
                {t('cookieText')}<a href={t('cookieLink')} rel="noreferrer" target='_blank' style={{color:"white"}}>{t('cookieLinkedText')}</a>.
                <div style={{textAlign: 'center'}}>
                    <button onClick={()=>{handleAccept()}}
                    style={{
                        textAlig:'center',
                        backgroundColor:'white',
                        color:definedStyles.color1,
                        border:'none',
                        borderRadius:'5px',
                        padding:'5px 15px',
                        marginTop:'20px',
                        fontWeight:'bold'
                    }}
                    >{t('cookieConfirm')}</button>
                </div>
            </div>
        );
    }
    return <>{cookiePop['carbon-cookie']!=='true' ? <CookieBar /> : <></>}</>;
}

export default CookiePop;
