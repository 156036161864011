import { Alert, AlertTitle } from '@mui/material';
import React from 'react'
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
// import photo from "../../assets/images/start.png"
import photo from "../../assets/images/start_nature.png"
import { STATES } from '../../data/config';
import { data } from '../../data/data';
import Footer from '../footer';
import Header from '../header';
import Policies from './Policies';
import SignIn from './SignIn';

function LandingPage({setView, customCallback}) {

    const [currentTab, setTab] = React.useState('disclaimer');
    // var userData = null
    const [userData, setUserData] = React.useState(data.userController.user);
    const [cookies, setCookie, removeCookie] = useCookies(["carbon-user"]);
    const [showAlert, setAlert] = React.useState(0);
    const { t } = useTranslation();

        
    function logOut() {        
        data.currentToken = '';
        data.currentUser = null;
        data.userController.clearUser();
        removeCookie('carbon-user',{expires:new Date()});
        console.log(cookies['carbon-user'])
        setUserData(null);
    }

    function callback(response, value){
        console.log(response, value)
        switch(response) {
            case 'start-app':
                console.log(userData)
                if(value){
                    if(data.userController.user !== null){
                        setView(STATES.projects_list)
                    }else{
                        // setView(STATES.projects_list)
                        customCallback('start-guest')
                    }
                }
                break;
            case 'home':
                setTab('disclaimer')
                break;
            case 'sign_in':
                setTab('login')
                break;
            case 'edit':
                setTab(response)
                break;
            case 'sign_up':
                setTab('register')
                break;
            case 'reminder':
                setTab(response)
                break;
            case 'myAssessments':
                customCallback('myAssessments')
                break;
            case 'signed-in':
                console.log(value)
                // setUserData(value);
                setAlert(1);
                setTimeout(()=>{setAlert(0)},3000)

                setTab('disclaimer');
                break;
            case 'logout':
                logOut();
                break;
        }
    }

    return (
        <div>
            
            <Poster>
                <div style={{
                    whiteSpace: 'break-spaces',
                    lineHeight: '4.5rem',
                    fontFamily: "Futura Bold",

                }}>
                    {t('posterText')}
                </div>
            </Poster>
            <Content>
                {showAlert == 1 ? 
                    <StyledAlert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        {t("signIn alert")}
                    </StyledAlert>
                    :
                        (showAlert == 2 ?
                        <StyledAlert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            Your username and/or password is incorrect
                        </StyledAlert>
                        :<></>)
                }
                {/*currentTab == 'disclaimer' ? <Language /> :<></> */}
                <Header headerCallback={callback} mode={currentTab} userData={userData}/>

                {currentTab == 'disclaimer' ?
                    <Policies callback={callback}/>
                    :''}
                {currentTab == 'login' || currentTab == 'register' || currentTab == 'reminder' || currentTab == 'edit' ?
                    <SignIn callback={callback} currentTab={currentTab}/>
                    :''}
                
                <FooterDiv>
                    <Footer mode={'landing'}/>
                </FooterDiv>
            </Content>
        </div>
    )
}


const Poster = styled.div`
    position:relative;
    background-image:url(${photo});
    height: 100vh;
    width:33%;
    display:inline-block;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: top;
    
    @media (max-width:550px) {        
        display:none
        // width: 90%;
        // margin-left:5%;
        // margin-right:5%;
    }

    div
        {position:absolute; left:0; bottom:0; font-size:5em; color:#fff; font-weight:700; margin:0 0 20px 40px;}
    div > span
        {display:block;}
`;


const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`
const FooterDiv = styled.div`
    position:absolute;
    bottom:0px;
    left:0px;
    width:100%;
    background-color:white;
`;

const Content = styled.div`
    background-color:#f9fafc;
    display:inline-block;
    vertical-align: top;
    height: 100vh;
    width: 67%;
    // min-width:350px;
    padding: 140px 10% 90px 10%;
    text-align: justify;
    position:relative;

    @media (max-width:1000px) {
        padding: 140px 6% 90px 6%;
    }
    @media (max-width:550px) {        
        width: 100%;
        padding: 140px 12.5% 90px 12.5%;
    }
    @media (max-height:780px) {
        .policies-col{
            width:100%
        }
    }
`;

export default LandingPage
