import React from 'react';
import styled from "styled-components";
import ElementRow from './elementRow';
import add_icon from '../../assets/images/add.svg';
// import { data } from '../../data/data';
import { GWP_CALC_METHOD, project } from '../../data/project'
import { config, STATES } from '../../data/config';
import parseProducts from '../defineSolutions/parseProducts';
import NavButton from '../shared/NavButton';
import lang from '../../data/lang';
import arrowImg from "../../assets/images/head_arrow.png";
import { definedStyles } from '../../data/definedStyles';
import { data } from '../../data/data';
import SectionHeader from '../shared/SectionHeader';
import { useTranslation } from 'react-i18next';



function ProjectDetails({navCallback}) {
    const [elements, setElements] = React.useState(project.elements);
    const getLastId = (elements)=>{ 
        config.elementsLastId = elements.length>0 ? elements[elements.length-1].id + 1 : 0;
        return config.elementsLastId;        
    }
    const { t } = useTranslation();
    const [last_id, setLastId] = React.useState(getLastId(project.elements)); 
    const [valid, setValid] = React.useState(elements.length>0);
    
    React.useEffect(() => {
        project.elements = elements;
        let valid = true;
        if(elements.length>0)
            valid = true;
        else{
            addElement(last_id, setLastId, setElements)
            valid = true;
        }

        setValid(valid);
        adjustSolution(elements, project.solutions)
        //console.log('effect')
    }, [elements])

    React.useEffect(() => {
        config.elementsLastId = last_id;
    }, [last_id])

    function handleCallback(action, element) {
        switch(action) {
            case 'edit':
                const updatedElements = elements.map((e) =>
                e.id === element.id
                    ? element
                    : e
                );
                setElements(updatedElements);

                break;
            case 'delete':
                const confirmed = window.confirm("Do you want to delete this?");
                if (confirmed) {
                    setElements((prevElements) => {
                        return prevElements.filter((e) => e.id !== element.id);
                    });

                }
                break;
            case 'copy':
                let newEl = Object.assign({}, element);
                setLastId((last_id + 1));
                newEl.id = last_id + 1;
                setElements((prevElements) => {
                    return prevElements.concat(newEl);
                })
                break;
            default:
                break;
            
        }
        //console.log('callback')
    }
    let no = 1;
    return <>
    <DetailsView>
        <SectionHeader navCallback={navCallback} prev={STATES.form} 
        title = {'2/5 '+ t('detailsTitle')}  
        subtitle = {t('detailsSubtitle')}/>
            
        
        <ContentCard>

            <div style={{textAlign:'right', marginBottom:20}}>
                <AddElem last_id={last_id} setLastId={setLastId} setElements={setElements} />
            </div>

            <table className="w-100">
                <tbody>
                    {
                    window.innerWidth > 767 ? 
                        <tr>
                            {/*<Th style={{width:'50px'}}>No</Th>*/}
                            <Th style={{
                                width:'30%',
                                borderTopLeftRadius: 8,
                            }}>{t('appType')}</Th>
                            {/* <Th style={{width:'30%'}}>Subtype</Th> */}
                            <Th style={{minWidth:'100px'}}>{t('area')} <span>(m<sup>2</sup>)</span></Th>                            
                            <Th style={{}}>R <span>(m<sup>2</sup>K/W)</span></Th>
                            <Th style={{}}>R {t('recommended')}</Th>
                            <Th colSpan={2} style={{width:'150px',textAlign:'center'}}>ACTIONS</Th>
                            
                        </tr>
                    :
                        <tr>
                            {/*<Th >No</Th>*/}
                            <Th >{t('application')}</Th>
                            <Th >{t('area')} (m2)</Th>                            
                            <Th >R-Value (Km2/W)</Th>
                            <Th >R rec.</Th>
                            <Th ></Th>
                        </tr>
                    }
                    {elements.map((element) => (
                        <ElementRow 
                            key={element.id}
                            no={no++}
                            element={element} 
                            callback={handleCallback}
                        />
                    ))}
                    <tr>
                        <td>
                            {/*<AddElem last_id={last_id} setLastId={setLastId} setElements={setElements} />*/}
                        </td>
                    </tr>
                    {/* <ElementsList elements={elements} setElements={setElements}/> */}
                </tbody>
            </table>
        </ContentCard>
        
    </DetailsView>
    
    <NavButton canProceed={valid} actions={{
        prev:{id:STATES.form, text:t('back')},
        next:{id:STATES.solutions, text:t('next')},
    }}
    callback={navCallback}/>
    </>;
}

function adjustSolution(elements, solutions) {
    var refreshedSolutions = [];
    for(let solution of solutions) {

        var refreshedSolution = {
            id: solution.id,
            elems: {},
            fav: solution.fav,
            name: solution.name,
        };
        for(let element of elements) {
            if(solution.elems[element.id] !== undefined && solution.elems[element.id] !== null){
                
                refreshedSolution.elems[element.id] = setSolutionElementData(solution.elems[element.id], element);//solution[element.id];
            }else {           
                refreshedSolution.elems[element.id] = getFirstProdData(element, parseProducts())
            }
        }
        refreshedSolutions.push(refreshedSolution);
    }
    project.solutions = refreshedSolutions;
    // setSolutions(refreshedSolutions);
}
function setSolutionElementData(solutionElement, element){    
    var updatedSolution = {};
    const products = parseProducts();
    let product = getProductById(products[element.id], solutionElement.prod_id);
    
    if(product == undefined) {
        updatedSolution =  getFirstProdData(element, products)   
    } else {
        updatedSolution = solutionElement
    }
    return updatedSolution;
}

function getFirstProdData(element, products){
    if(products[element.id] == undefined) {
        return null;
    }
    var firstProdId = products[element.id][0].id_products;
    var selected_sot = products[element.id][0].available_sots[0];
    var thickness = parseFloat(selected_sot['thickness']);
    var length = parseFloat(selected_sot['height']);
    var width = parseFloat(selected_sot['width']);
    var lambda = parseFloat(selected_sot['lambda']);
    var R = parseFloat(selected_sot['thermal_resistance']);
    var creation_method = selected_sot['creation_method'];
    let epd = getEpdFromProdBySot(products[element.id][0], selected_sot['id_systemic_properties_series_of_types']);
    return {
        prod_id: firstProdId,
        thickness: thickness*1000,
        width: width*1000,
        length: length*1000,
        sot_id: selected_sot['id_systemic_properties_series_of_types'],
        lambda: lambda,
        creation_method: creation_method,
        //R: (thickness/lambda).toFixed(3),
        R: R,
        GWP: calculateGWP(epd, element.area),
    }
};

function calculateGWP(epd, area) {
    // console.log(epd)
	/*
    if(project.gwp_calc_method == GWP_CALC_METHOD.static)
        return Math.round((
            epd.A13*area +
            epd.A4*area +
            epd.A5*area +
            epd.C4*area +
            epd.C2*area 
            // + epd.D*area
            )*1)/1;
    else
        return Math.round((
            epd.A13*area +
            epd.A4*area +
            epd.A5*area +
            epd.C4*area*0.578 +
            epd.C2*area*0.578 
            // + epd.D*area
            )*1)/1;
	*/
	return 0;
}
function getEpdFromProdBySot(prod, sot_id) {
    for(let epd of prod.epd.values) {
        if(epd.id_sot == sot_id) {
            return epd;
        }
    }
    return null;
}
function addElement(last_id, setLastId, setElements){
    var types = getStarterTypes();
        setLastId((last_id + 1));
        const newElem = {
            id: last_id+1,
            app_type: types.at_id,
            // subtype: types.sat_id,
            area: 100,
            R_rec: 4,
            R_val: 0,
        }

        setElements((prevElements) => {
            return prevElements.concat(newElem);
        })
}

function AddElem({last_id, setLastId, setElements}) {  
    const { t } = useTranslation();
    function handleAddAction(){
        addElement(last_id, setLastId, setElements)
    }
    return <div style ={{
        display:'inline-block',
        padding:'10px 40px',
        color:definedStyles.color1,
        border:'1px solid '+definedStyles.color1,
        borderRadius:8,
        textAlign:'center',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '22px',
        cursor:'pointer'
    }}
        onClick={handleAddAction}
    >+ {t("Add")}</div>
}

function getStarterTypes(){
    const appTypeOptions = config.appTypeOptions;
    var types = {}
    // appTypeOptions.map((type)=>(
    //     type.parent!=null ?
    //     types = {
    //         at_id: type.parent,
    //         sat_id: type.at_id
    //     }
    //     :{}        
    // ))
    // console.log(appTypeOptions)
    return appTypeOptions[0];
}


function getProductById(products, id) {
    if(products && products.length>0)
        for(let product of products) {
            if (product.id_products === id) {
                return product;
            }
        }
    return null;
};

const Th = styled.th`
    text-align: left;
    font-weight:bold;
    padding:8px 12px;
    /*border-bottom: 1px solid black;*/
    background-color : #f3f4f6;    
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;

    span{
        text-transform: none;
    }
`;
const DetailsView = styled.div`
    padding-bottom: 15px;
    margin-bottom: 50px;
`;
const ContentCard = styled.div`
    background-color:white;
    border-radius:8px;
    padding: 20px;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
`;

export default ProjectDetails;