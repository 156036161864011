
import React from 'react';
import { Col, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import lang from '../../data/lang';
import {TextField, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import { useTranslation } from 'react-i18next';

// export function FormField({callback, title, name, type, required, options, value}) {
const FormField = ({callback, validationCallback, field, value, editable=true}) => {


    const [valid, setValid ] = React.useState(isValid(field,value));
    const [touched, setTouched ] = React.useState(false);

    const { t } = useTranslation();
    React.useEffect(() => {  
        if(touched){
            setValid(isValid(field,value));
        }
    }, [touched])

    React.useEffect(() => {  
        // //console.log(valid)
        if(editable && validationCallback !=undefined)
            validationCallback();
    }, [valid])
   
    function valueCallback(value) {
        
        setValid(isValid(field,value));
        callback(field.name, value)
    }

    function isValid(field,value){
        var valid = true;
        if(field.required){
            if(field.type == 'select' && value == 0 || value==undefined){
                valid = false;
            } else if(value == null || value==undefined || value==''){
                valid = false;
            } else {
                valid = true;
            }
        }
        return valid;
    }
    return (
        <Field key={'field'+ field.name}>

                <Row style={{marginTop:10}}>
                    <Col style={{
                        paddingBottom:'15px', marginRight:'10px',
                        position:'relative'

                    }}>
                        {field.type === 'select'? 
                        <FSelect
                            field = {field}
                            value = {value}
                            setValue={valueCallback}
                            touched={touched}
                            setTouched={setTouched}
                            editable = {editable}
                        /> 
                        :  
                        <Input
                            field = {field}
                            value = {value}
                            setValue={valueCallback}
                            touched={touched}
                            setTouched={setTouched}
                            editable = {editable}
                        />
                        }
                        {/*<ValidationText field={field} touched={touched} valid={valid} />*/}
                    </Col>
                </Row>
                
        </Field>
    )
}

function ValidationText({field, touched, valid}) {
    const { t } = useTranslation();
    if(touched && field.required && !valid){        
        return (
            <div style={{
                color:'red', 
                position: 'absolute',
                bottom: '0px',
                left: '15px'
            }}>{t('requiredField')}</div>
        )
    } else {
        return (
            <div></div>
        )
    }
}

function Input({field, value, setValue, touched, setTouched, editable=true}) {
    const { t } = useTranslation();
    function handleChange(e){
        setValue(e.target.value);
    }
    function handleFocus(){
        if(!touched)
            setTouched(true);
    }
    return  <FormControl fullWidth>
                <TextField 
                id={"input_"+field.name} 
                value={value == null | value == undefined ? '' : value}
                onChange={handleChange}
                onClick={handleFocus}
                disabled = {!editable}
                label = {<>
                    {t(field.title)} {field.required ? <sup style={{color:'red'}}>*</sup> : ''}
                    </>}
                variant="outlined" 
                placeholder={field.placeholder ? field.placeholder : ''}
            />
    </FormControl>
}

function FSelect({field, value, setValue, touched, setTouched, editable=true}){
    const { t } = useTranslation();
    
    function handleChange(e){
        if (e.target.value == 0){
            setValue(null);
        }else{
            setValue(e.target.value);
        }
    }
    
    function handleFocus(){
        if(!touched)
            setTouched(true);
    }
    return (
        
        <FormControl fullWidth>
            {/* <InputLabel id={"input_"+field.name} >
                {field.title} {field.required?<sup style={{color:'red'}}>*</sup>:''}
            </InputLabel> */}
            <TextField
                select
                disabled = {!editable}
                placeholder = {t(field.title)}
                id="demo-simple-select"
                value={value ==  undefined ? null : value}
                label={<>{t(field.title)} {field.required?<sup style={{color:'red'}}>*</sup>:''}</>}
                onChange={handleChange}
                onClick={handleFocus}
                SelectProps={!editable ? { IconComponent: () => null }: {}}
            >
            {field.options.map((option) => (
                <MenuItem key={option.id+'_'+option.name} value={option.id}>{t(option.name)}</MenuItem>
            ))}
            </TextField>
        </FormControl>
        
        // <select 
        //         name = {"input_"+field.name}         
        //         value={value ==  undefined ? undefined : value}
        //         onChange={handleChange}
        //         onClick={handleFocus}
        //         style={{                    
        //             padding:0,
        //             fontSize: '16px',
        //             borderRadius: '4px',
        //             borderWidth: '0px',
        //             width:'100%',
        //             background:'none'
        //         }}              
        // >
        //     {field.options.map((option) => (
        //         <option key={option.id} value={option.id}>
        //             {option.name}
        //         </option>
        //     ))}
        // </select>
        
    )
}

export default React.memo(FormField);

const Field = styled.div`
    margin-bottom:20px;
    width:100%;
`;
// const Label = styled.label`
//     font-weight: bold;
//     font-size: 14px;
//     margin-bottom: 6px;
// `;
// const Input = styled.input`
//     padding:10px 15px;
//     font-size: 16px;
//     border-radius: 4px;
//     border-width: 1px;
//     width:100%;
// `;

// const Select = styled.select`
//     padding:10px 15px;
//     font-size: 16px;
//     border-radius: 4px;
//     border-width: 1px;
//     width:100%;
// `;
