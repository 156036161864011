import React from 'react'
import ProjectBox from './projectBox';
// import styled, { css } from "styled-components";
import {data} from "../../data/data";
import assessments_ico from "../../assets/icons/assessments.svg";
import { Col, Row } from "react-bootstrap";
import { Alert, Select } from '@mui/material';
import styled from 'styled-components';
import DataFormatter from '../../api/dataFormatter';
import { withTranslation } from 'react-i18next';
import Api from '../../api/api';

class ProjectsList extends React.Component {
    constructor(props) {
        super(props);
        this.dataFormatter = new DataFormatter();
        this.state={
            showAlert: 0,
            order:'new-modified',
            projects:[], //this.sortByDate(this.setProjectsList(), 'new-modified')
        };
        if(data.currentUser){
            this.dataFormatter.initSavedProjects(data.currentUser).then((resp)=>{
                console.log(resp)
                console.log(data.user_projects)
                this.setState({
                    projects:this.sortByDate(this.setProjectsList(data.user_projects), 'new-modified')
                })
            })
        }
        this.api = new Api();
        this.callback = this.props.callback;
    }
    sortByDate(projects, order){
        if(order === 'new-modified'){
            return projects.sort((a,b) => (a.m_date < b.m_date) ? 1 : ((b.m_date < a.m_date) ? -1 : 0))
        } else if(order === 'new-created'){
            return projects.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
        } else if(order === 'old-modified'){
            return projects.sort((a,b) => (a.m_date > b.m_date) ? 1 : ((b.m_date > a.m_date) ? -1 : 0))
        } else if(order === 'old-created'){
            return projects.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
        }
    }
    setOrder(order){
        
        var projects = this.sortByDate(this.state.projects, order)
        this.setState({projects: projects, order: order});
    }
    setProjectsList(projectsList){
        //console.log()
        let results = [];
        for(let project_index in projectsList){
            let proj_data = projectsList[project_index];
            results.push(
                {
                    id: proj_data.id,
                    name: proj_data.project.form.projName,
                    general_data: {
                            company_name: proj_data.project.form.companyName,
                            proj_location: proj_data.project.form.projLocation,
                            proj_realization: proj_data.project.form.realizationYear,
                            type_of_proj: proj_data.project.form.typeOfProject,
                            type_of_building: proj_data.project.form.typeOfBuilding,
                    },
                    gwp: proj_data.info.gwp,
                    date: proj_data.info.date,
                    m_date: proj_data.info.mod_date
                } 
            )
        }

        return results;
    }
    projectCallback(action, proj_id){
        const { t } = this.props;
        if(action =='delete'){
            var projects = this.state.projects.filter((proj)=>{
                return proj.id != proj_id;
            })

            this.sortByDate(projects, 'new-modified')
            this.setState({projects: projects});
        } else if( action == 'edit') {
            this.callback('edit', proj_id)        
        } else if( action == 'copy') {
            var proj = JSON.parse(JSON.stringify(data.user_projects[proj_id]));
            var cpy_proj = {
                proj_id: null,
                user_id: proj.user_id,
                elements: proj.project.elements,
                form: proj.project.form,
                solutions: proj.project.solutions,
            }
            cpy_proj.form.projName += ' (' + t('Copy') + ')';

            this.api.saveProject(cpy_proj).then((response)=>{
                if(response) {
                    cpy_proj.proj_id = response
                    proj.id = response
                    proj.info.date = todaysDate();
                    proj.info.mod_date = todaysDate();
                    data.user_projects[proj.id] = proj;
                    this.setState({
                        showAlert: 1,
                        projects:this.sortByDate(this.setProjectsList(data.user_projects), 'new-modified')
                    })
                    setTimeout(()=>{this.setState({showAlert:0})},3000);
                }
            });
            
        }
    }
    
    noAssessments(){
        const { t } = this.props;
        return (
            <Row style={{textAlign:'center'}} className="d-flex align-items-center">
                <Col style={{marginTop:'25px'}}>
                    <img src={assessments_ico}  style={{
                        width:'100px',
                        backgroundColor:'#F4F5F5', 
                        padding:'20px', 
                        borderRadius:"100px"}} />
                    <p style={{fontWeight: 'bold', fontSize:'18px'}}>{t("No assessments yet")}</p>
                    <p>{t("Add a new assessment by clicking on the green button \"New assessment\"")}</p>
                </Col>
            </Row>
        )    

    }

    listProjects() {
        if(this.state.projects == 0) {
            return this.noAssessments()
        }
        let projects = [];
        for(let project of this.state.projects) {
            projects.push(
                <ProjectBox key={project.id} project={project} callback={this.projectCallback.bind(this)}/>
            )
            
        }
        return projects;
    }
    handleChange(e){
        // this.setState({order: e.target.value});
        this.setOrder(e.target.value)
    }
    render() {
        const { t } = this.props;
        return <div className="projects-list">
            {this.state.showAlert == 1 ?
                    <StyledAlert severity="success">
                        {t('projectSaved')}
                    </StyledAlert>
                    :<></>}
            <Row className="d-flex justify-content-between align-items-center"
            style={{
                backgroundColor:'white',
                boxShadow: '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
                borderRadius: '8px',
                paddingTop:10,
                paddingBottom:10,
                marginBottom:20
            }}>
                <Col>
                
                    <p style={{display:'inline-block',margin:0,marginRight:20, fontSize: '12px'}}>{t("Sort by")}: </p>
                    <select 
                        name={'sort_type'} 
                        value={this.state.order}
                        onChange={this.handleChange.bind(this)} 
                        style ={{        
                            // width:"100%",
                            padding: "3px 5px",
                            borderColor:'white',
                            marginTop:'3px',
                            marginBottom:'3px',
                            fontSize: '12px',
                            fontWeight:'bold',
                            fontFamily: 'Futura Std',
                            letterSpacing: '0.01em'
                        }}
                        >
                            <option key={'new-created'} value={'new-created'}>{t("Newest creation date")}</option>
                            <option key={'old-created'} value={'old-created'}>{t("Eldest creation date")}</option>
                            <option key={'new-modified'} value={'new-modified'}>{t("Newest edition date")}</option>
                            <option key={'old-modified'} value={'old-modified'}>{t("Eldest edition date")}</option>
                    
                            
                        </select>
                    
                </Col>
                <Col 
                sm={'auto'}
                style={{
                    fontSize:12,
                    fontWeight:'bold',
                    color:'gray',
                }}>{t("Total projects")}: {this.state.projects.length}</Col>
            </Row>
            <Row style={{marginLeft:-25, marginRight:-25}} className="project-container">
                {this.listProjects()}
            </Row>
        </div>
    }
}

function todaysDate(){
    var d = new Date();
    return d.getFullYear()+'-'+d.getMonth()+'-'+d.getDay();
}
const FSelect = ({options, parent, value, name, callback}) => {
    function onChange(event){
        callback(event.target.name, event.target.value)
    }
    
    return (
    <select 
        name={name} 
        value={value} 
        onChange={onChange} 
        style ={{        
            width:"100%",
            padding: "12px 10px",
            borderRadius: "5px",
            borderWidth:"1px",
            marginTop:'3px',
            marginBottom:'3px',
            fontSize: '15px',
            border: '1px solid lightgray'
        }}
        >
            {options.map((o) => (
                o.parent === parent ?
                <option key={o.at_id} value={o.at_id}>
                    {o.name}
                </option>
                :''
            ))}
            
        </select>
    )
}

const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`

const DSelect = styled(Select)`
    // width: 100%;
    font-size: 12px;
    display:inline-block;
    legend{
        display:none;
    }
`;
export default withTranslation()(ProjectsList);