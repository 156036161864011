import React from 'react'
import { Row, Col, Container } from "react-bootstrap";
import logo from "../../assets/images/logo.svg";
import logo_img from "../../assets/images/logo.png";
import styled from "@emotion/styled";
import { Button } from 'react-bootstrap';
import { withTranslation, useTranslation } from 'react-i18next';
// import Button from '@mui/material/Button';


function Policies({callback}) {    
    const { t } = useTranslation();

    const disclaimerRef = React.useRef();
    const [disclaimer, setDisclaimer] = React.useState(false);
    const [confirmation, setConfirmation ] = React.useState(false);

    function checkboxCallback(response,val){
        console.log(response,val)
        setConfirmation(val);
    }

    function toggleDisclaimer() {
        // disclaimer = !disclaimer;
        setDisclaimer(!disclaimer)
    }

    function handleClick(){
        callback('start-app', true)
    }
    
    return (
        <Row className="policies-row justify-content-center" style={{height:"100%"}}>
            <Col className="policies-col" md={7} sm={10} xs={12} style={{height:"100%"}}>
                <div style={{textAlign:'center'}}>
                    <Logo src={logo_img} style={{cursor:'pointer'}}/>
                </div>

                {/* <h3 style={{textAlign:'center', marginBottom:'50px', fontWeight: 'bold' }}>{t('carbonCalc')}</h3> */}
                <div style={{backgroundColor: '#fff', padding:'20px 10px', borderRadius: 10, height:'calc(100% - 110px)'}}>
                
                    <div style={{overflowY:'auto', maxHeight:'calc(100% - 95px)', paddingLeft:10, paddingRight:10}}>
                        <p style={{fontWeight:'bold'}}>{t('dearUsers')}</p>
                        <p style={{
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '15px',
                            lineHeight:' 150%',
                            whiteSpace: 'pre-line',
                            /* or 22px */
                            
                            letterSpacing: '0.01em'
                        }}>
                            {t('policies')}
                        </p>

                        <p ref={disclaimerRef}
                        style={{
                            overflow:'hidden',
                            whiteSpace: 'pre-line',
                        maxHeight: disclaimer? 'unset':'0px'  
                        }}
                        >{t('policies2')}           
                            </p>
                        
                    </div>
                    <div style={{textAlign:'center', marginTop:'40px'}}>
                        <Button
                            style={{
                                backgroundColor: '#00BD71',                        
                                pointerEvents: 'auto',
                                border: 'none',
                                padding: '14px 34px',
                                borderRadius:'8px',
                                gap: '10px',
                            }}
                            onClick={()=>{handleClick()}}
                        >
                            {t('startCalc')}</Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}


const Logo = styled("img")`
    height: 60px;
    margin:auto;
    text-align:center;
    margin-bottom:50px;
    @media (max-width: 1000px) {
        width: 100px;
        height:auto;
    }
    @media (max-height:780px) {
        margin-bottom:15px;
    }
`;

export default withTranslation()(Policies);
