import React from "react";
import { userApi } from "../../api/userApi";
import { data } from "../../data/data";
import styled from "styled-components";
import { definedStyles } from "../../data/definedStyles";
import { useTranslation } from "react-i18next";
import SectionHeader from "../shared/SectionHeader";
import { Alert, AlertTitle } from "@mui/material";
import ico_eye from "../../assets/icons/eye.svg"

function SignInForm({ userCallback, callback, window }) {
    const email = React.useRef();
    const pass = React.useRef();
    const { t } = useTranslation();
    const [showAlert, setAlert] = React.useState(0);

    // const reminderUrl = userApi.reminder('fr')
    function callbackTitle(response) {
        callback(response)
    }
    function handleSignIn(e) {
        e.preventDefault();
        userApi.signIn(email.current.value, pass.current.value).then((d) => {
            if(d === false) {
                console.warn('wrong credentials')
                setAlert(1);
            } else {
                data.currentToken = d.token;
                data.currentUser = d.user.id;
                console.log(d)
                data.userController.editDataUrl = userApi.setdata(d.token, 'fr')
                userCallback({
                    userData: d.user,
                    rtoken: d.refresh_token,
                    token: d.token,
                });
            }
        });
    }

    function ReminderURL({callback}) {
        function handleClick(){
            callback('reminder')
        }

        return (
            <p style={{ 
                textAlign: 'left',
                color:definedStyles.color1,
                position:'relative',
                top:'-15px',
                fontSize:'12px',
                cursor:'pointer'
            }}
             onClick={handleClick}>{t('forgotPass')}</p>
        );
    }
    function RegisterURL({callback}) {
        function handleClick(){
            callback('sign_up')
        }

        return (
            <p style={{ 
                textAlign: 'left',
                position:'relative',
                marginTop:'50px',
                fontSize:'12px',
            }}
             >{t('dontHaveAcc')} <span style={{ 
                cursor:'pointer', 
                color:definedStyles.color1,
                }} onClick={handleClick}>{t('signUp')}</span>
             </p>
        );
    }
    function InputField({type, placeholder, children}){
        return <div style={{position:'relative'}}>
                <Input ref={pass} type={type} placeholder={placeholder} />
                {children ? children: <></>}
            </div>
    }
    function togglePasswordVisibility(){
        if(pass.current.type === 'password'){
            pass.current.type = 'text';
        } else {
            pass.current.type = 'password'
        }
    }
    return (<>
        {/* <h3>{t('signIn')}</h3> */}
        <SectionHeader title={t('signIn')} prev={window == 'modal' ?  null : 'home'} navCallback={callbackTitle}/>
        <form onSubmit={handleSignIn} style={{ width: "100%" }}>
            <label style={{ fontWeight: "bold", marginBottom: "15px" }}>
                {t('emailAddress')}
            </label>
            <Input
                ref={email}
                type="text"
                placeholder="e.g. alan.smith@mail.com"
                
            />

            <label style={{ fontWeight: "bold", marginBottom: "10px" }}>
                {t('password')}
            </label>
            <InputField type="password" placeholder="password" >
                <InputIcon src={ico_eye} onClick={togglePasswordVisibility}/>
            </InputField>       
            <ReminderURL callback={callback} />
            {showAlert == 1 ?
                <StyledAlert severity="error">
                    {t('Your username and/or password is incorrect')}
                </StyledAlert>
            :<></>}
            <RegisterURL callback={callback} />

            <div style={{textAlign:'center'}}>
                <PanelButton type="submit">{t('signIn')}</PanelButton>
            </div>
        </form>
        </>
    );
}
const StyledAlert = styled(Alert)`
    border-radius: 8px;
`
const PanelButton = styled.button`
    background-color: ${definedStyles.color4};
    padding: 5px;
    width: 100%;
    color: white;
    border: none;
    border-radius:5px;
    padding:10px;
    max-width:200px;
    
`;
const InputIcon = styled.img`
    cursor: pointer;
    position: absolute;
    right:10px;
    top:50%;
    transform:translate(0px, -50%);
`
const Input = styled.input`
    display: block;
    padding: 3px 5px;
    border-radius: 3px;
    border-width: 1px;
    margin-bottom: 25px;
    border-color:gray;
    padding: 10px;
    box-sizing: border-box;
    width:100%;
`;
export default SignInForm;
