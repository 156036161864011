import React from 'react'
import { config } from '../../data/config';
import { data } from '../../data/data';
import lang from '../../data/lang';
import { GWP_CALC_METHOD, project } from '../../data/project';
import CustomCheckbox from '../shared/CustomCheckbox';
import sharedMethods from '../shared/sharedMethods';
import styled from "styled-components";
import info from "../../assets/icons/info.svg"
import lifeCycle from "../../assets/icons/life-cycle.svg"
import applications from "../../assets/icons/applications.svg"
import { useTranslation } from 'react-i18next';
export default function DataPicker({callback, selectedImpact}) {
    const elements = project.elements;
    const [selected, setSelected] = React.useState(initSelectedValues())
    
    const { t } = useTranslation();
    


    function Description({selectedImpact}) {
        return( <div style={{
            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #64B6F7',
            borderRadius: '4px',
            padding:10,
            paddingLeft:56,
            position:'relative',
            fontSize: 15,
            lineHeight: '150%',
            letterSpacing: '0.01em',
            marginBottom: 30,
        }}>
            <img style={{
                position:'absolute',
                left:24,
                top:10,
                width:18
            }}
            src={info} />
        <p style={{fontWeight: 'bold', margin:0,
            fontFamily:'Futura Std'}}>{t('description')}</p>
        <p style={{margin:0}}>{t(config.impactCriteria[selectedImpact].description)}</p>
        </div>)
    }
    function clearSelections(newSelected, type){
        if(type != null) {
            var typeSelection = newSelected[type]
            for(let select_id in typeSelection) {
                typeSelection[select_id] = false;
            }
            newSelected[type] = typeSelection;
        }else {
            for(let type in newSelected) {
                var typeSelection = newSelected[type]
                for(let select_id in typeSelection) {
                    typeSelection[select_id] = false;
                }
                newSelected[type] = typeSelection;
            }
        }
        return newSelected;
    }

    function checkboxCallback(name, value){
        
        name = name.replace('chckbx_','');
        // console.log(name)
        var id = null;
        var type = null;
        if(name.includes('elements')){
            id = name.replace('elements_','');
            type = 'elements';
        } else if(name.includes('prod')){
            id = name.replace('prod_','');
            type = 'prods';
        } else if(name.includes('cycles')) {
            id = name.replace('cycles_','');
            type = 'cycles';
        } else {
            return false;
        }
        
        var newSelected = JSON.parse(JSON.stringify(selected));
        // console.log(type, id)
        if(id == '-1')
            newSelected = clearSelections(newSelected, type);
        else
            newSelected[type]['-1'] = false;

        console.log(newSelected)
        newSelected[type][id] = value;
        // //console.log(name, value)
        setSelected(newSelected);
        // if(value)
        callback(newSelected)
    }
    
    function SelectByApplication(){
        return (
            <div>
                <div>
                    <CustomCheckbox 
                        value = {selected.elements['-1']}
                        name = {'chckbx_elements_-1'}
                        labelText = {t('allApplications')}
                        callback = {checkboxCallback}
                    />
                </div>
                {elements.map((element)=>(
                    <div key={'element_'+element.id}>
                        <CustomCheckbox 
                            value = {selected.elements[element.id]}
                            name = {'chckbx_elements_' + element.id}
                            labelText = {t(sharedMethods.getTypeName(element.app_type))}
                            callback = {checkboxCallback}
                        />
                        {/* <input type='checkbox' id={'chckbx_' + element.id} onChange={callback}/>
                        <label htmlFor={'chckbx_' + element.id}>{getTypeName(element.subtype)}</label> */}
                    </div>
                
                ))}
                
            </div>
        )
    }

    function SelectByLifeCycle({selected, selectedImpact}){
        
        return (
            <div>
                <div>
                    <CustomCheckbox 
                        value = {
                            config.impactCriteria[selectedImpact].cycles ?
                            selected.cycles['-1']
                            : true
                        }
                        name = {'chckbx_cycles_-1'}
                        labelText = {t('step4 checkboxFilter allLifeCycle')}
                        callback = {checkboxCallback}
                    />
                </div>
                {config.impactCriteria[selectedImpact].cycles ?
                    <>
                    {cycles.sort((a,b)=>a.id<b.id ? 1: -1)
                    .map((cycle)=>(
                        <div className="cycle_checkbox" key={'cycles_'+cycle.id}>
                            <CustomCheckbox 
                                value = {selected.cycles[cycle.code]}
                                name = {'chckbx_cycles_' + cycle.code}
                                labelText = {
                                    cycle.name + (t(cycle.text) != '' ? ': ' + t(cycle.text) : '')
                                }
                                callback = {checkboxCallback}
                                style={{
                                    paddingLeft:'25px'
                                }}
                                
                            > 
                            {typeof cycle.color == 'string' ? 
                                <div className="cycle_color"
                                    style={{
                                        width:'15px',
                                        height:'15px',
                                        borderRadius: '8px',
                                        backgroundColor: cycle.color,
                                        position:'absolute',
                                        left:'22px',
                                        top:'5px'
                                    }}>
                                </div>
                                :
                                <>
                                    <div className="cycle_color"
                                        style={{
                                            width:'15px',
                                            height:'15px',
                                            borderRadius: '8px',
                                            backgroundColor: cycle.color[0],
                                            position:'absolute',
                                            left:'22px',
                                            top:'5px'
                                        }}>
                                    </div>
                                    <div className="cycle_color"
                                        style={{
                                            width:'16px',
                                            height:'16px',
                                            borderRadius: '30px 30px 30px 30px',
                                            position:'absolute',
                                            left:'21px',
                                            top:'5px',
                                            border: 'none',
                                            borderBottom:'8px solid '+cycle.color[1],
                                            transform: 'rotate(45deg)',
                                        }}>
                                </div>
                                </>
                            }
                            </CustomCheckbox>
                        </div>
                    
                    ))}
                </>
                :<></>}
            </div>
        )       
    }
    return (
        <div style={{
            marginTop:'50px'
        }}>
            <Description selectedImpact={selectedImpact} />
            <FilterGroup>
                <FilterGroupTitle>
                    <img style={{
                        position:'absolute',
                        left:0,
                        top:0,
                        width:18
                    }}
                    src={lifeCycle} />
                    {t('Life-cycle')}
            </FilterGroupTitle>
                <SelectByLifeCycle selected={selected} selectedImpact={selectedImpact} />
            </FilterGroup>
            <FilterGroup>
                <FilterGroupTitle>
                    <img style={{
                        position:'absolute',
                        left:0,
                        top:0,
                        width:18
                    }}
                    src={applications} />
                     {t('Applications')}
                </FilterGroupTitle>
                <SelectByApplication />
            </FilterGroup>
            {
                project.gwp_calc_method == GWP_CALC_METHOD.static ?                        
                    <LCAInfoLabel>{t('Static LCA')}</LCAInfoLabel>:
                    <LCAInfoLabel>{t('Dynamic LCA')}</LCAInfoLabel>
            }
        </div>
    )
}
const cycles = config.lifeCycle;

function getListOfProducts(solutions){
    var prods_ids = [];
    for(let solution of solutions){
        for(let product_index in solution){
            var product = solution[product_index];
            if(product == undefined || product == null) {
                continue;
            }
            if(prods_ids.indexOf(product.prod_id)<0) {
                prods_ids.push(product.prod_id)
            }
        }
    }
    return prods_ids;
}
function initSelectedValues(){
    const elements = project.elements;
    // const solutions = project.solutions;
    const products = data.products;
    // console.log(products)
    var selected = {prods:{},cycles:{},elements:{}};
    
    selected.elements['-1'] = true;
    selected.cycles['-1'] = true;
    selected.prods['-1'] = true;
    
    elements.map((element)=>{
        selected.elements[element.id] = false;
    })
    products.map((prod)=>{
        selected.prods[prod.id_products] = false;
    })
    cycles.map((cycle)=>{
        selected.cycles[cycle.code] = false;
    })
    return selected;
}

const LCAInfoLabel = styled.div`
    color:white;
    border-radius:8px;
    background-color:gray;
    display:inline;
    padding:4px 12px;
    font-size:12px;
    letter-spacing: 0.1rem;
`;
const FilterGroup = styled.div`
    margin-bottom:15px;
`;
const FilterGroupTitle = styled.p`
    margin-bottom:0px;
    font-weight:bold;
    position: relative;
    padding-left: 25px;
`;

// function getTypeName(type) {
//     // var subtypeObj = getTypeById(subtype)
//     var typeObj = getTypeById(type)
//     if(typeObj === null){
//         return '--';
//     }
//     return typeObj.name;
// }
// function getTypeById(id) {
//     for(let type of config.appTypeOptions) {
//         if(type.at_id == id) {
//             return type;
//         }
//     }
//     return null;
// }
