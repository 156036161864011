import React from "react";
import { Chart, registerables } from "chart.js";
import { Row, Col } from "react-bootstrap";
import DataPicker from "./DataPicker";
import { definedStyles } from "../../data/definedStyles";
import html2canvas from 'html2canvas'
import download_ico from "../../assets/icons/download.svg";
import { config as APP_config } from "../../data/config";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from 'react-tabs-scrollable'
import 'react-tabs-scrollable/dist/rts.css'
import styled from "styled-components";
import { project } from '../../data/project';


const Canvas = ({canvasRef}) => {
    return <canvas
        ref={canvasRef}
        id="gwp_charts"
        width="650"
        height="500"
        style={{maxHeight:500}}
    ></canvas>
}

function Charts({data, labels}) {

	const [solutions_in, setSolutions_in] = React.useState(sortSolutions(project.solutions));
    const canvasRef = React.useRef();
    const legendRef = React.useRef();
    const [myChart, setMyChart] = React.useState(null)
    const [selectedImpact, setSelectedImpact] = React.useState(1)
    var sel = null
    const plugin = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
          const ctx = chart.canvas.getContext('2d');
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      };
      var criterias = Object.keys(APP_config.impactCriteria).map((index) => { return APP_config.impactCriteria[index]})
	  console.log("%%%%%%%%%%%%")
	  console.log(criterias)
	  console.log("%%%%%%%%%%%%")
	  //const criterias = Object.keys(APP_config.impactCriteria).map((order) => { return APP_config.impactCriteria[order]})
    
	
	criterias.sort(function(a,b){
		if(a.order > b.order)
			return 1;
		else if(a.order < b.order)
			return -1;
		else 
			return 0;
	})
	
	  //console.log(criterias)
	  //console.log("%%%%%%%%%%%%")
		
	
		//criterias = [];
		
	//APP_config.impactCriteria
	
      const { t } = useTranslation();
    var config = {
        type: "bar",
        data: {
            labels:[],
            datesets:[],
        },
        plugins: [plugin],
        options: {
            datasets:{
                bar:{
                    maxBarThickness: 80,
                }
            },
            legend: {
                labels: {
                    fontSize: 50
                }
            },
            plugins: {
                title: {
                    display: false,
                    text: "",
                },
                legend: {
                    display: false,
                },
                labels: {
                    fontSize: 50
                }
            },
            responsive: true,
            scales: {
                // xAxes: [{
                //     categoryPercentage: 2.0,
                //     barPercentage: 0.5
                // }],
                x: {
                    categoryPercentage: 0.5,
                    barPercentage: 1.0,
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
            layout: {
                padding: 50
            },
            hover: {
                animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0,
            
        },
    };
    
    React.useEffect(() => {
        if(!myChart) {
            const ctx = canvasRef.current.getContext("2d");
            ctx.fillStyle = "#FFF";
            ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            var chart = initChart(ctx);
            chart = setData(chart, labels, parseData(data, sel, selectedImpact), selectedImpact)
            setMyChart(chart);
        }
    },[])

    function sortSolutions(solutions){
        var favs = solutions.filter((sol)=>{
            return sol.fav
        })
        var other = solutions.filter((sol)=>{
            return !sol.fav
        })
        
        return favs.concat(other);
    }

    function initChart(ctx){
        Chart.register(...registerables);

        var myChart = new Chart(ctx, config);
        return myChart;

    }

    function setData(chart, labels, datasets, sel) {
		//alert(1)
        var sums = calculateSums(datasets, sel);
        var labels = labels.map((label, pos)=>{
            return [label, ' ' + t('num',{val:sums[pos]})+' '+APP_config.impactCriteria[sel].unit]
        })
        const data = {
            labels: labels,
            datasets: datasets,
        };
        chart.data = data;
        //console.log("---------")
        //console.log(data)
		//console.log("---------")
        chart.update()
        const animation = {
            duration: 0,
            onComplete: function() {
            //     var chartInstance = chart;
            //     var ctx = chartInstance.ctx;
      
            //     ctx.textAlign = 'center';
            //     ctx.textBaseline = 'bottom';
            //     ctx.font = '20px Arial';
            //     var total = {};
            //     var colors = {};
            //     var length = this.data.datasets.length
            //     console.log(this.data)
            //   this.data.datasets.forEach(function(dataset, i) {
                    // var meta = chartInstance.getDatasetMeta(i);
                    
                    // meta.data.forEach(function(bar, index) {
                    //     var data = dataset.data[index];
                    //     if(total[index] === undefined)
                    //         total[index] = 0
                    //     if(colors[index] === undefined)
                    //         colors[index] = dataset.backgroundColor[index];

                    //     // console.log(total[index], dataset)
                    //     total[index] += Math.round(data*10)/10;
                        
                    //     if(i == length -1 && canvasRef.current) {                            
                    //         ctx.fillStyle = 'rgb(0,0,0)';
                    //         ctx.font = "bold 15px Arial";
                    //         ctx.fillText(
                    //             Math.round(total[index]*10)/10, 
                    //             bar.x, canvasRef.current.height - 28);
                    //         ctx.font = "normal 11px Arial";
                    //         ctx.fillText(
                    //             APP_config.impactCriteria[sel].unit, 
                    //             bar.x, canvasRef.current.height - 10);
                    //     }
                    // });
            //   });
            }
        };
        chart.options.animation = animation;
        chart.update();
        return chart;

    }


    function updateChart(selected, impact = null){
		// console.log("#################")
        // console.log(data)
		// console.log("#################")
        sel = selected;
        impact = impact ? impact : selectedImpact
        setData(myChart, labels, parseData(data, selected, impact), impact)
    }

    function calculateSums(datasets, impact){
        var solutions = [];
			// console.log("========== CHART =====================")
		// console.log("impact: " + impact)
		// console.log(datasets)

		var i =0;
        for(let dataset of datasets){
			
            for(let data_index in dataset.data) {
                if(solutions[data_index] == undefined) {
                    solutions[data_index] = 0;
                }
				
				//if(impact == 1 && (i == 3 || i == 4)) {
	                //solutions[data_index] += Math.round(0.578 * 10000*dataset.data[data_index]) / 10000;
					// console.log(i + " =##=> " + 0.578*dataset.data[data_index])

				//} else {
					solutions[data_index] += Math.round(100000*dataset.data[data_index]) / 100000;
					// console.log(i + " ====> " + dataset.data[data_index])

				//}
				
				
	            //Math.round(epd.C4*area*0.578*10000)/10000  +
				//Math.round(epd.C2*area*0.578*10000)/10000 +

				
            }
			i+=1;
        }
        var results = [];
		// console.log("========== CHART =====================")
        for(let solution of solutions) {
            //console.log(solution)
            if(solution>0){
				if(solution < 0.1)
					solution = Math.round(solution*100)/100
				else if(solution<1)
					solution = Math.round(solution*100)/100
				else
					solution = Math.round(solution*100)/100
				
				// console.log("##### " + solution)
            }
			//ssolution = 0;
            results.push(solution)
        }
		// console.log("====================================")
        return results;
    }


    // function GetChartImg(){
    //     function handleClick(){
    //         // console.log(document.querySelector('#legendBox'))
    //         html2canvas(document.querySelector('#chartCapture')).then(canvas => {
    //                 // var canvasImg = canvas.toDataURL("image/jpg");
    //                 // console.log(canvas.toDataURL("image/png"))
    //                 var link = document.createElement('a');
    //                 link.download = 'chart.png';
    //                 link.href = canvas.toDataURL("image/png")
    //                 link.click();
    //             })
    //     }

    //     return <div 
    //     style={{
    //         marginTop:'30px',
    //         padding: '10px 15px',
    //         borderRadius:'8px',
    //         backgroundColor: definedStyles.color1,
    //         color:'white',
    //         textAlign:'center', 
    //         cursor:'pointer',
    //     }}
    //     onClick={handleClick}>
    //         {t('downloadChart')}
    //         <img style={{ marginLeft:'8px'}} src={download_ico} />
    //     </div>
    // }

    return (
        <div style={{marginTop:'30px', marginBottom:'80px'}}>
            
                <StyledTabs
                    activeTab={selectedImpact}
                    onTabClick={(event,val) => {
                        setSelectedImpact(criterias[val].id);
                        updateChart(sel, criterias[val].id) 
                    }}
                    isRTL={false}
                    tabsScrollAmount={0}
                    animationDuration={300}
                    navBtnCLickAnimationDuration={300}
                    selectedAnimationDuration={300}
                    rightBtnIcon={'>'}
                    leftBtnIcon={'<'}
                    hideNavBtns={false}
                    hideNavBtnsOnMobile={false}
                    showTabsScroll={false}
                    navBtnsIconColor={definedStyles.color1}
                    style={{ width:'100%'}}
                    >
                    {criterias.map((criteria) => ( 
                        
                        <Tab style={{
                            cursor:'pointer',
                            border:'none',
                            backgroundColor:'white',
                            borderRadius: 0,
                            borderBottom: criteria.id == selectedImpact ? '2px solid '+definedStyles.color1 :'1px solid #ccc',
                            color: criteria.id == selectedImpact ? definedStyles.color1 :'#ccc',
                            padding:'10px',
                            margin:'0px',
                            textAlign:'center',
                            fontWeight: '400',
                            fontSize: '20px',
                            lineHeight: '27px',
                            boxShadow:'none',
                        }} key={criteria.order}>
                            {t(criteria.name)}
                        </Tab>
                        
                    ))}
                </StyledTabs>
                
            <Row id="chartCapture" style={{
            }}>
                <Col  md={4}>                
                    <DataPicker callback={updateChart} selectedImpact={selectedImpact}/>
                </Col>
                <Col md={8}>
                    <div style={{
                        paddingTop: 50,
                        position:'relative',
                    }}>
                        
                        <div style={{
                            position:'absolute',
                            left:'50px',
                            top:'5px',
                            textAlign:'left'
                        }}>
                            <p style={{fontSize:'12px', margin:'0px'}}>{t(APP_config.impactCriteria[selectedImpact].name)}</p>
                            <p style={{fontSize:'10px', margin:'0px', color:'#adadad'}}>({APP_config.impactCriteria[selectedImpact].unit})</p>
                        </div>
                        <Canvas canvasRef={canvasRef} />
                        {/* <Legend legendRef={legendRef} /> */}
                    </div>
					<div  class="mmc_d_03">{disp_info(t('info_01'))}</div>
					
                </Col>
            </Row>
        </div>
    );
	
function disp_info(s) {
	//var s = "aaavvbb " + this.t('info_01');
	
	//solution.elems[element.id].creation_method
	var f_info = false;
	var s_f = "";
	//console.log("++++++++++++++++++++++++++")
	for(var k in solutions_in) {
		for(var kk in solutions_in[k].elems) {
			var o = solutions_in[k].elems[kk];
			var cm = solutions_in[k].elems[kk].creation_method;
			if(!s_f)
				s_f = cm;
			//console.log(s_f + " == " + cm)
			if(s_f == cm) { //tylko jeden typ w liscie
				
			} else {
				f_info = true;
				break;
			}
			s_f = cm;
			//console.log("cm: " + cm + "      f_info: " + f_info);
		}
	}
	
	//console.log("++++++++++++++++++++++++++")

	if(!f_info)
		s = ""
	
	return s;//this.t('info_01');
}

}


function parseData(data, selected, selectedImpact){
    var datas;
    if(APP_config.impactCriteria[selectedImpact].cycles)
        datas = parseDataByFilter(data, selected, selectedImpact)
    else
        datas = parseDataNonCycled(data, selected, selectedImpact)

    return datas;
}
function parseDataNonCycled(chData, selected, selectedImpact){
    var datasets=[]

    for(let sol_pos in chData){
        var set=0;
        var elems_len = 0;
        for(let elem_id in chData[sol_pos]){ 
            //console.log(chData[sol_pos][elem_id][selectedImpact])  
            if(selected == null || selected.elements[elem_id] ||  selected.elements['-1']){
                set += chData[sol_pos][elem_id][selectedImpact];
                elems_len++;
            }
        }
        
        if(elems_len != 0 && APP_config.impactCriteria[selectedImpact].id == 7)
            set = ((set/elems_len)*100);

        datasets.push(set);
    }
    var dataset = [{
            label: APP_config.impactCriteria[selectedImpact].type,
            data: datasets,
            backgroundColor: definedStyles.color1
    }]
    //console.log(dataset)
    return dataset;
}
function parseDataByFilter(chData, selected, selectedImpact){
    var datasets = {
        "A13":[],
        "A4":[],
        "A5":[],
        "C2":[],
        "C4":[],
        "D":[],
    };
    var colors = {
        "A13":[],
        "A4":[],
        "A5":[],
        "C2":[],
        "C4":[],
        "D":[],
    }
    var cycles = APP_config.lifeCycle

    for(let sol_pos in chData){
        var set={
            "A13":0,
            "A4":0,
            "A5":0,
            "C2":0,
            "C4":0,
            "D":0,
        }
        
        
        for(let elem_id in chData[sol_pos]){            
            
            if(selected == null || selected['elements']['-1'] === true || selected['elements'][elem_id] === true){
                
                for(let c in chData[sol_pos][elem_id][selectedImpact]){                
                    if(selected == null || selected['cycles']['-1'] === true || selected['cycles'][c] === true) {
                        set[c]+=chData[sol_pos][elem_id][selectedImpact][c];
                        
                    }
                }  
            }
        }
        for(let cycle of cycles){
            colors[cycle.code].push(
                typeof cycle.color=='string' ? cycle.color : (set[cycle.code] >=0 ? cycle.color[1] : cycle.color[0])
            )
            datasets[cycle.code].push(set[cycle.code]);
        }
    }
    var dataset = [];
    for(let cycle of cycles){
        dataset.push({
            label: cycle.name,
            data: datasets[cycle.code],
            backgroundColor: colors[cycle.code]
        })
    }

    return dataset;
}

const StyledTabs = styled(Tabs)`
    color:red;
    background-color:red !important;

    .rts___nav___btn___container,
    button{        
        cursor: pointer;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid rgb(204, 204, 204);
        border-left: none;
        border-image: initial;
        background-color: white;
        border-radius: 0px;
        color: rgb(204, 204, 204);
        padding: 10px;
        margin: 0px;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
    }
    .rts___btn{
        border-radius:0px !important;
    }
`


export default Charts;
