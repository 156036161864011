
import {config} from './config';


const langs = {
    fr:{
        cookieText:`Notre site `+window.location.hostname+` n’utilise que     des cookies nécessaires à la sécurisation de son accès et à     l’utilisation de ses fonctions. Ces cookies ne requièrent pas     votre consentement`,
        cookieLink:"https://www.knaufinsulation.fr/politique-en-matière-de-cookies",
        cookieLinkedText:`ici`,
        cookieConfirm:`J'ai compris`,
        logout:`Déconnexion`,
        register:`S'inscrire`,
        login:`S'identifier`,
        settings:`Paramètres`,
        remindMe:`Récupérer mon mot de passe`,
        edit:`Modifier`,
        delete:`Supprimer`,
        download:`Télécharger`,
        forgotPass:`Mot de passe oublié ?`,
        emailAddress:`Adresse e-mail`,
        password:`Mot de passe oublié?`,
        creationDate:`Date de création`,
        modificationDate:`Modification date`,
        companyName:`Nom de l'entreprise`,
        projectName:`Nom du projet`,
        typeOfProject:`Type de projet`,
        projectLocation:`Localisation`,
        projectLocation2:`(Ville, Code postal, Pays)`,
        projectRealizationYear:`Date de réalisation`,
        typeOfBuilding:`Type de bâtiment`,
        detailsTitle:`Détails du projet`,
        detailsSubtitle:`Renseignez ci-dessous les besoins fonctionnels pour votre projet`,
        appType:`Type d'application`,
        application:`Application`,
        area:`Surface`,
        recommended:`recommandé`,
        fillFields:`Veuillez compléter les champs obligatoires`,
        generalTitle:`Informations générales`,
        generalSubitle:`Veuillez renseigner les détails de votre projet pour une simulation précise de l'impact du transport`,
        requiredField:`Ce champs est nécessaire`,
        footerLink1:`Site du groupe`,
        footerLink2:`Conditions d'utilisation`,
        footerLink3:`Politique de confidentialité`,
        footerLink4:`Politique en matière de cookies`,
        footerBottomText:`Tous droits réservés`,
        addSolution:`Ajouter une solution`,
        solutionsTitle:`Choix des solutions`,
        noProds:`Il n'existe pas de produit compatible`,
        product:`Produit`,
        thickness:`Épaisseur`,
        solution:`Solution`,
        saveAsNew:`Sauvegarder comme un nouveau projet`,
        exportPdf:`Télécharger en PDF`,
        saveChanges:`Sauvegarder les modifications`,
        saveProject:`Sauvegarder le projet`,
        projectSaved:`aaaaaaaaProjet sauvegardé`,
        policies:`no tr fr`,
        policies2:`no tr fr`,
        dearUsers: `no tr fr`,
        carbonCalc:`no tr fr`,
        disclaimer:`no tr fr`,
        startCalc:`no tr fr`,
        back:'Retour',
        next:'Suivant',
        changeCountry:'Changer de pays',
        myAssessments:'Overview',
        downloadChart:'download chart',
        allApplications: 'All applications',
        appAndSupport: 'Application & support',
        lambda: 'Lambda',
        total: 'Total',
        posterText: 'FOR A BETTER WORLD',
        signUp: 'Sign Up',
        signIn: 'Sign In',
        dontHaveAcc: `Don't have an account?`,
        myAssessments: `My assessments`,
        descBIO: ``,
        descRATIO: ``,
        descNHW: ``,
        descWD: ``,
        descNRE: ``,
        descRE: ``,
        descFW: ``,
        descGWP: `Global warming potential (GWP) is the heat absorbed by any greenhouse gas in the atmosphere, as a multiple of the heat that would be absorbed by the same mass of carbon dioxide (CO2). GWP is 1 for CO2. For other gases it depends on the gas and the time frame.`,
        description: `Description`,
        success: `Succès`,
    },
    en:{
        cookieText: ``+window.location.hostname+`  website only uses cookies that are necessary for secure access and the use of its functions. These cookies do not require your consent, in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27th 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data. For more information on the cookies used please click`,
        cookieLink:"https://www.knaufinsulation.com/cookie-policy",
        cookieLinkedText:`here`,
        cookieConfirm:`I understand`,
        logout:`Logout`,
        register:`Register`,
        login:`Login`,
        settings:`Profile`,
        remindMe:`Remind me`,
        edit:`Edit`,
        delete:`Delete`,
        download:`Download`,
        forgotPass:`Forgot password?`,
        emailAddress:`Email address`,
        password:`Password`,
        creationDate:`Creation date`,
        modificationDate:`Modification date`,
        companyName:`Company name`,
        projectName:`Project name`,
        typeOfProject:`Project type`,
        projectLocation:`Project location'`,
        projectLocation2:`(City, Country)`,
        projectRealizationYear:`Project realization year`,
        typeOfBuilding:`Building type`,
        detailsTitle:`Project details`,
        detailsSubtitle:`Fill below the applications functional needs for your construction`,
        appType:`APPLICATION TYPE`,
        application:`Application`,
        area:`AREA`,
        recommended:`RECOMMENDED`,
        fillFields:`Please fill required fields!`,
        generalTitle:`General information`,
        generalSubitle:`Please specify your project details for an accurate calculation of transportation impacts.
        All those data will only be used for the assessment calculation and will appear in your project report.`,
        requiredField:`This field is required`,
        footerLink1:`Group site`,
        footerLink2:`Terms of use`,
        footerLink3:`Privacy policy`,
        footerLink4:`Cookie policy`,
        footerBottomText:`All rights reserved.`,
        addSolution:`New assessment`,
        solutionsTitle:`Define solutions`,
        noProds:`There is no compatible product`,
        product:`Product`,
        thickness:`Thickness`,
        solution:`Solution`,
        saveAsNew:`Save project as new`,
        exportPdf:`Export pdf`,
        saveChanges:`Save changes`,
        saveProject:`Save project`,
        projectSaved:`Project saved`,
        policies:`This online calculator, based on Enviromental Product Declarations (EPD)
        available for Rock Mineral Wool Lamella Mats (EPD-KIN-20150029-CBB1-EN) 
        and Pipe Sections (EPD-KNA-20150026-CBB1-EN and EPD-KNA-20150027-CBB1-EN),
        allows you to populate project-specific Life Cycle Assessments (LCA) 
        in the form of Environmental Fact Sheets (EFS)`,
        policies2:`We do not accept any liability to you for any loss 
        arising out of, or in connection with your use of 
        the report whether in contract, tort, by statue or 
        otherwise. No representation, warranty, assurance or 
        undertaking (express or implied) is made by us and 
        no responsibility or liability will be accepted by us in
        relation to the accuracy or completeness of the
        information provided in the report. Nothing in this disclaimer excludes liability for,
        or remedy in aspect of, fraudelent misrepresentation or any other liability which cannot be excluded by law`,
        dearUsers: `Dear users`,
        carbonCalc:`Klmpact`,
        disclaimer:`Disclaimer`,
        startCalc:`Start calculator`,
        back:'Back',
        next:'Next',
        changeCountry:'Change country',
        myAssessments:'Overview',
        downloadChart:'Download chart',
        allApplications: 'All applications',
        allProducts: 'All products',
        allLifeCycles: 'All lifecycles',
        appAndSupport: 'Application & support',
        lambda: 'Lambda',
        total: 'Total',
        posterText: 'FOR A BETTER WORLD',
        signUp: 'Sign Up',
        signIn: 'Sign In',
        dontHaveAcc: `Don't have an account?`,
        myAssessments: `My assessments`,
        descBIO: ``,
        descRATIO: ``,
        descNHW: ``,
        descWD: ``,
        descNRE: ``,
        descRE: ``,
        descFW: ``,
        descGWP: `Global warming potential (GWP) is the heat absorbed by any greenhouse gas in the atmosphere, as a multiple of the heat that would be absorbed by the same mass of carbon dioxide (CO2). GWP is 1 for CO2. For other gases it depends on the gas and the time frame.`,
        description: `Description`,
		success: `Success`,
    }
}
// var lang = langs[config.activet('lang')];

// var lang = {};
// t('txt') = langs[config.activet('lang')];

// export var setLang = (language) => {
//     lang = langs[language];
//     console.log(lang)
// }
// export default langs.lang;
// export default langs[config.activet('lang')];