import React from 'react'
import { useCookies } from 'react-cookie';
import Modal from 'react-modal';
import { STATES } from '../data/config';
import { data } from '../data/data';
import Header from './header'
import close from '../assets/icons/close.svg';
import UserContainer from './user/userContainer'
import { Alert, AlertTitle } from '@mui/material';
import styled from 'styled-components';
import { project } from '../data/project';
import { useTranslation } from 'react-i18next';

function FurtherHeader({headerCallback, setView, headerInitState}) {

    const [headerInit, setHeaderInit] = React.useState(headerInitState);
    // const [currentTab, setTab] = React.useState(headerInitState);
    const [userData, setUserData] = React.useState(null);
    const [,, removeCookie] = useCookies(["carbon-user"]);
    // const [showModal, setModal] = React.useState(headerInitState ? true : false);
    const [modalState, setModalState] = React.useState({
        currentTab: headerInitState == 'login-with=save' ? 'login' : null,
        showModal: headerInitState ? true : false,
    });
    const { t } = useTranslation();
    const [showAlert, setAlert] = React.useState(0);
    
    React.useEffect(()=>{
        if(headerInitState != headerInit){
            setHeaderInit(headerInitState);
        }
    })
    React.useEffect(()=>{
        if(headerInit) {
            console.log(headerInit)
            setState({
                currentTab: headerInit == 'login-with-save' ? 'login' : null,
                showModal:  headerInit ? true : false
            })
        }
    },[headerInit])
    
    function setState(state){
        var newState = {
            currentTab: state.currentTab != undefined ? state.currentTab : modalState.currentTab,
            showModal: state.showModal != undefined ? state.showModal : modalState.showModal,
        }
        setModalState(newState);
    }

    const stylesForModal = {
        login: {
            width:'400px',
            height:'480px',
            margin: 'auto',
            paddingTop:'20px'
        },
        register: {
            width:'500px',
            // height:'600px',
            margin: 'auto',
            paddingTop:'20px'
        },
        reminder: {
            width:'400px',
            height:'600px',
            margin: 'auto',
            paddingTop:'20px'
        }

    }
    

    function logOut() {      
        data.currentToken = '';
        data.currentUser = null;
        data.userController.clearUser();
        setUserData(null);
        removeCookie('carbon-user',{expires:new Date()});
        setView(STATES.landing)
        // console.log(cookies['carbon-user'])
    }
    // function handleOpenModal () {
    //     setModal(true);
    // }
      
    function handleCloseModal () {
        // setModal(false);
        setState({showModal:false})
        console.log('close modal')
        headerCallback('sign-in-showed')
    }

    // function setHeight(el) {
    //     iframe.current.height = iframeParent.current.scrollHeight;
    // }
    
    function callback(response, value){
        console.log(response, value)
        switch(response) {            
            case 'sign_in':
                setState({showModal:true, currentTab:'login'});
                break;
            case 'sign_up':
                setState({showModal:true, currentTab:'register'});
                break;
            case 'reminder':
                setState({showModal:true, currentTab: response});
                break;
            case 'edit':
                setState({showModal:true, currentTab: response});
                break;
            // case 'signed-up-to-save':
            //     headerCallback('signed-up-to-save')
                // break;
            case 'signed-up-now-save':
                console.log(headerInit)
                if(headerInit == 'login-with-save')
                    headerCallback('signed-up-now-save', value)
                else{
                    headerCallback('sign-in-showed')
                    setState({showModal:false})
                }
                break;
            case 'signed-in':
                console.log(modalState)
                setState({showModal:false})
                setUserData(value);

                if(headerInit == 'login-with-save')
                    headerCallback('sign-in-showed-now-save')
                else
                    headerCallback('sign-in-showed')

                
                setAlert(1);
                setTimeout(()=>{setAlert(0)},3000)
                break;
            case 'logout':
                logOut();
                break;
            case 'home':
                headerCallback('home');
                break;
            case 'myAssessments':
                headerCallback('myAssessments');
                break;
            default:
                // setTab(null);
                break;
        }
    }
    return (<>
            <Header headerCallback={callback} mode={modalState.currentTab} userData={userData}/>
            <Modal 
                centered
                ariaHideApp={false}
                style={{
                    overlay:{
                        zIndex:'100'                    
                    },
                    content:stylesForModal[modalState.currentTab]
                }}
                isOpen={modalState.showModal}
                contentLabel="User"
            >
                <div onClick={handleCloseModal} style={{ 
                    position:'absolute',
                    top:'15px',
                    right:'15px',
                    cursor: 'pointer'
                }}>
                    <img alt='x' src={close} />
                </div>
                <UserContainer mode={modalState.currentTab} callback={callback} window='modal'/>
            

            </Modal>
            {showAlert == 1 ? 
                <StyledAlert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {t("signIn alert")}
                </StyledAlert>
                :
                    (showAlert == 2 ?
                    <StyledAlert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Your username and/or password is incorrect
                    </StyledAlert>
                    :<></>)
            }
        </>
    )
}

const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`
// function signInFormCallback(response) {
//     // setUser(response);
// }
export default FurtherHeader
