import React, { Component } from 'react'
import styled from "styled-components";
import { definedStyles } from '../../data/definedStyles';
import { Col, Row } from "react-bootstrap";
import ico_co2 from "../../assets/icons/ico_co2.svg";
import { withTranslation } from 'react-i18next';

export class PotentialTab extends Component {
    constructor(props) {
        super(props);
        this.gwp = this.props.info
    }

    render() {
        const { t } = this.props;
        return (
            <DataContainer>
                <Row style={{fontSize:15}}>
                    <Col>{t(this.setResTitleText(this.gwp))}</Col>
                    <Col style={{textAlign:'right'}}>
                        <GWPNumbers style={{ }}>{t('num',{val:this.setResText(this.gwp)})}</GWPNumbers>
                        <p style={{margin:0, fontSize:12, color:' lightgray'}}>kg CO<sub>2</sub>-Eq.</p>
                    </Col>                    
                </Row>
            </DataContainer>
        )
    }
    setResTitleText(gwp){
        return gwp.min != null && gwp.max != null ? (
            gwp.min == gwp.max ? 
            'Global warming Potential':
            'Global warming Potential between' )
        : 'Global warming Potential';
    }
    setResText(gwp){
        var p1 =gwp.min != null && gwp.min != gwp.max ? gwp.min : ''; 
        var p2= gwp.min != null && gwp.max != null && gwp.min != gwp.max ? ' - ': '';
        var p3 =gwp.max != null ? gwp.max : ''; 
        return p1+p2+p3;
    }
}
const DataContainer = styled.div`
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    width:100%;
    @media (max-width: 959px) {
        // padding: 8px 5px;
    }
`;
const GWPLine = styled.p`
    font-size: 12px;
    color: ${definedStyles.color1};
    text-align: center;
`;
const GWPNumbers = styled.p`
    font-size:16px;
    font-weight:bold;
    margin:0; 
    font-family: 'Futura Std';
    color: ${definedStyles.color1};
`;
export default withTranslation()(PotentialTab)
