
import React, { Component } from 'react'
import Cookies from 'js-cookie';
import DataFormatter from "./api/dataFormatter";
import { data } from "./data/data";
import { config } from "./data/config";
import SubApp from "./SubApp";
import lang_en from "./assets/locales/lang_en.json"
import lang_fr from "./assets/locales/lang_fr.json"
import i18n from "i18next";
import { useTranslation, initReactI18next, withTranslation } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: lang_en
      },
      fr: {
        translation: lang_fr
      }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
 

// const userController = new UserController();

class App extends Component {   
    constructor(props) {
        super(props);
        this.dataFormatter = new DataFormatter();
        this.state = {
            cookies: Cookies.get("carbon-user"),
            appData: false,
            prodData: false,
            projData: false,
            language: config.activeLang,
            userHash: null
        }
        console.warn('App')
        if(!this.state.appData || !this.state.prodData){
            console.warn(this.state.appData,this.state.prodData)
            this.init().then((responses) =>{
                this.setState({appData: responses[0],prodData: responses[1]});
            })
        }
        this.refreshUserData();
    }

    handleSetCookie = (name, data, exp) => {
        Cookies.set(name, data, { path: "/" }); // set the cookie
        this.setState({ cookies: Cookies.get(name) });
    };

    handleRemoveCookie = () => {
        Cookies.remove("cookies"); // remove the cookie
        this.setState({ cookies: Cookies.get("cookies") });
    };

    init(){
        var promises = [
            !this.state.appData ? this.dataFormatter.initApplicationData() : true,
            !this.state.prodData ? this.dataFormatter.initProducts() : true,
            // this.state.projData ? this.dataFormatter.initSavedProjects(data.currentUser): true,
        ]
        return Promise.all(promises)
        
    }

    refreshUserData(){
        console.log('refreshUserData', data.userController)
        if(data.userController.user === null ){
            // console.log(data.userController.rtoken, cookies['carbon-user'])
            data.userController.rtoken = this.state.cookies
            var response = data.userController.refreshToken();
            response.then((res) => {
                if(res){
                    data.currentUser = data.userController.user.userData.id
                    this.handleSetCookie('carbon-user', data.userController.rtoken, {maxAge: 2592000})
                    // init();
                }else{
                    this.handleRemoveCookie('carbon-user');
                }
            }) 
        }
        
    }
    render() {
        return (<>
                <div className="app-main-page" style={{
                    position: 'relative',
                    minHeight:'100vh',
                    backgroundColor:'#F9FAFC'
                }}>
                    <SubApp user = {data.userController} />        
                </div>
            </>
        )
    }
}
export default withTranslation()(App);
