import React from 'react'
import { Row, Col } from "react-bootstrap";
import { config, STATES } from '../../data/config';
import { data } from '../../data/data';
import lang from '../../data/lang';
import { GWP_CALC_METHOD,project } from '../../data/project';
import NavButton from '../shared/NavButton';
import Charts from './Charts'
import Info from './Info'
import Summary from './Summary';
import arrowImg from "../../assets/images/head_arrow.png";
import SectionHeader from '../shared/SectionHeader';
import { useTranslation } from 'react-i18next';

function ChartsContainer({navCallback}) {
    const elements = project.elements;
    const solutions = project.solutions; 
    const [valid, setValid] = React.useState(true);

    const { t } = useTranslation();
    
    const datasets = setDatasets(elements, solutions);

    var index = 1;
    const labels = solutions.map((solution) => {
        console.log(solution)
        return solution.name
    });

    return (
        <div>
            <SectionHeader navCallback={navCallback} prev={STATES.solutions} title = {'4/5 '+ t("My results")}  subtitle = {t('Results of the comparison between the solutions.')}/>
            <Info />
            <Charts data={datasets} labels={labels}/>
			
            <Row>
                <Col>
                    <Summary datasets={datasets}/>
                </Col>
            </Row>
            
            <NavButton canProceed={valid} actions={{
                prev:{id:STATES.solutions, text:t('back')},
                next:{id:STATES.export, text:t('next')},
            }}
            callback={navCallback}/>
        </div>
    )
}

function setDatasets(elements, solutions){
    var datasets = {};
    solutions.map((solution, pos)=>{
        datasets[pos] = {};
        elements.map((element)=>{
            // console.log(element)
            var dataset={};
            for(let criteria_id in config.impactCriteria){
                if(config.impactCriteria[criteria_id].cycles)
                    dataset[criteria_id] ={
                        "A13": 0,
                        "A4": 0,
                        "A5": 0,
                        "C2": 0,
                        "C4": 0,
                        "D": 0,
                    };
                else
                    dataset[criteria_id] = 0;
            }

            if(!solution.elems[element.id]){
                return null;
            }
            if(solution.elems[element.id].prod_id == null){
                var prod = null;
                var epd = null;
            } else {
                var prod = getProd(solution.elems[element.id].prod_id);
                // console.log(prod)
                // var epd = epdExistsForSot(prod, solution[element.id].sot_id);
                var epd = solution.elems[element.id].epd;
                // console.log(epd)
            }
            //console.log(epd)
            if(epd != null) {
                for(let criteria_id in epd){
                    if(epd[criteria_id] != null) {
                        
                        // jesli impact criteria jest w cyklach licz cykle

                        if(config.impactCriteria[criteria_id] && config.impactCriteria[criteria_id].cycles) {
							

							var coeficient = 1;
							if(project.gwp_calc_method == GWP_CALC_METHOD.static) {
								coeficient = 1;
							} else {
								coeficient = 0.578;	
							}
							
							
							
							console.log("======================> " + project.gwp_calc_method)
							
                            dataset[criteria_id]['A13'] += Math.round(parseFloat(epd[criteria_id]['A13'])*element.area*10000)/10000
                            dataset[criteria_id]['A4'] += Math.round(parseFloat(epd[criteria_id]['A4'])*element.area*10000)/10000
                            dataset[criteria_id]['A5'] += Math.round(parseFloat(epd[criteria_id]['A5'])*element.area*10000)/10000
                            dataset[criteria_id]['C2'] += Math.round(parseFloat(epd[criteria_id]['C2'])*coeficient*element.area*10000)/10000
                            dataset[criteria_id]['C4'] += Math.round(parseFloat(epd[criteria_id]['C4'])*coeficient*element.area*10000)/10000
                            dataset[criteria_id]['D'] += Math.round(parseFloat(epd[criteria_id]['D'])*coeficient*element.area*10000)/10000
							
							
                        } else {
                            //jesli nie, ustal jedna wartosc
                            //##dataset[criteria_id] =parseFloat(epd[criteria_id]['value'])
							//console.log("%%%%%%%%%%%%")
							//console.log(dataset)
							//console.log("%%%%%%%%%%%%%%%%%%%% " + criteria_id)
							dataset[criteria_id] =parseFloat(epd[criteria_id]['value'])
                        }
                    } else {
						//console.log("%%%%%%%%%%%%")
						//console.log(dataset)
						//dataset[criteria_id] = 111.11
						//console.log(criteria_id)
						//console.log(dataset)
						//console.log("%%%%%%%%%%%%")
					}
                }
            } else {
                // return 0;
            }
			//console.log("%%%%%%%%%%%%%%%%%%%% " + pos + "    " + element.id)
			
            datasets[pos][element.id] = dataset;
			//console.log(dataset)
        })
        
    })
    return datasets;
}
function getProd(prod_id){
    const products = data.products;
    for(let product of products) {
        if (product.id_products === prod_id) {
            return product;
        }
    }
    return null;
}



export default ChartsContainer
