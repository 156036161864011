import React from 'react'
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Solution from './solution';
import { data, APP_TYPE_PROPERTY_TAB_ID } from '../../data/data';
import { definedStyles } from '../../data/definedStyles';
import parseProducts from './parseProducts';
import { config, STATES } from '../../data/config';
import ico_del from '../../assets/icons/trash.svg'
import NavButton from '../shared/NavButton';
import lang from '../../data/lang';
import arrowImg from "../../assets/images/head_arrow.png";
import SectionHeader from '../shared/SectionHeader';
import { GWP_CALC_METHOD, project } from '../../data/project';
import { useTranslation } from 'react-i18next';

function DefineSolutions({navCallback}) {
    
    //console.log(data)

    const elements = project.elements;
    const [solutions, setSolutions ] = React.useState(project.solutions);    
    const [valid, setValid] = React.useState(solutions.length > 0 );

    const { t } = useTranslation();
    function adjustSolution(elements, solutions) {
        var refreshedSolutions = [];
        
        for(let solution of solutions) {
            console.log(solution)
            var refreshedSolution = {
                id: solution.id,
                elems: {},
                fav: solution.fav,
                name: solution.name,
            };
            for(let element of elements) {
                console.log(solution.elems[element.id], solution.elems, element.id)
                if(solution.elems[element.id] != undefined){
                    var el = setSolutionElementData(solution.elems,elements)
                    console.log(el,element.id)
                    // refreshedSolution[element.id] = solution[element.id];                           
                    refreshedSolution.elems[element.id] = el[element.id];
                    // refreshedSolution.elems[element.id] = setSolutionElementData(solution.elems,elements);
                }else {    
                    console.log(getFirstProdData(element, parseProducts()))       
                    refreshedSolution.elems[element.id] = getFirstProdData(element, parseProducts())
                }
                console.log(solution.elems, refreshedSolution.elems)
            }
            refreshedSolutions.push(refreshedSolution);
        }
        setSolutions(refreshedSolutions);
    }


    
    if(didElementsUpdate(elements, solutions)){
        //console.log('element changed')
        adjustSolution(elements, solutions);
    }

    React.useEffect(() => {
        project.solutions = solutions;
        
        
        if(solutions.length == 0 ){
            addSolution()
            // setValid(false).
        }else if(valid == false)
            setValid(true);

    }, [solutions])

    function addSolution(){        
        let newSolution = {
            id: config.solutionsLastId++,
            elems:{},
            fav: false,
            name: 'Solution #'+config.solutionsLastId
        };
        const products = parseProducts();
        for(let element of elements) {
            newSolution.elems[element.id] = {prod_id:null, sot_id:null} 
            // newSolution[element.id] = getFirstProdData(element, products);
        }
        setSolutions((prevSols) => {
            return prevSols.concat(newSolution);
        });

        project.solutions = solutions;
    }
    function DefinitionBox(){
        return <div style={{
            border: '1px solid #F3F4F6',
            paddingLeft:'10px',
            paddingRight:'10px',
            borderRadius:'8px'
            }}>
                <Row className="d-flex align-items-center" style={{
                    fontSize:'11px',
                    lineHeight:'30px',
                    // paddingTop:'14x',
                    // paddingBottom:'14px',
                    borderTopLeftRadius:'8px',
                    borderTopRightRadius:'8px',
                    backgroundColor: '#F3F4F6',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    height: '58px',
                }}>
                    <Col xs={5}><span style={{textTransform:'uppercase'}}>{t('appType')}</span></Col>
                    <Col ><span style={{textTransform:'uppercase'}}>{t('area')}</span> (m<sup>2</sup>)</Col>
                    <Col ><span style={{textTransform:'uppercase'}}>R</span> (m<sup>2</sup>K/W)</Col>
                </Row>
                {elements.map((element) => (
                <ElementBox key={element.id} style={{
                }}>
                    {/* <Col xs={1}>#{++el_pos}</Col> */}
                    <Col xs={5}>{t(getTypeById(element.app_type).name)}</Col>
                    {/* <Col xs={3}>{getTypeById(element.subtype).name}</Col> */}
                    <Col >{element.area}</Col>
                    <Col >{t('num',{val:element.R_val})}</Col>
                </ElementBox>
                ))}
        </div>
    }
    var el_pos = 0;
    return (
        <>
            <SectionHeader navCallback={navCallback} prev={STATES.details} title = {'3/5 '+ t('solutionsTitle')}  subtitle = {t("step3 stepDescription")}/>
            
            <ContentTab style={{marginBottom:'25px'}}>
            <AddSolution addSolution={addSolution} />
            <Row style={{marginBottom:'15px'}}>
                {
                solutions.map((solution, pos) =>  (
                <>
                    <Col  md={5} sm={6} xs={12} className='elements' style={{
                        // borderRadius:'8px',
                        marginBottom:'15px',
                        // padding:'20px 35px 20px 20px',
                        }}>                        
                        <DefinitionBox />
                    </Col>
                    
                    <Col md={7} sm={6} xs={12} className='solutions' style={{
                        // borderRadius:'8px',
                        marginBottom:'15px',
                        overflow: 'auto',
                        // padding:'20px 35px 20px 20px',
                    }}>
                        <RenderSolution solution={solution} solutions={solutions} setSolutions={setSolutions} elements={elements}/>
                    </Col>

                </>
                ))}
                
                </Row>                
            </ContentTab>
			<Row>
				<Col md={9}>
					<div class="mmc_d_03">{t('info_01')}</div>
				</Col>
				<Col md={3}>
					<NavButton canProceed={valid} actions={{
						prev:{id:STATES.details, text:t('back')},
						next:{id:STATES.charts, text:t('next')},
					}}
					callback={navCallback}/>
				</Col>
			</Row>
        </>
    )
}



function didElementsUpdate(elements, solutions) {
    for(let solution of solutions) {
        if(Object.keys(solution.elems).length === elements.length) {
            for(let element of elements) {
                if( solution.elems[element.id] !== undefined) {
                    continue;
                }else{
                    //console.log('different elem')
                    return true;
                }
                
            }
            continue;
        } else {
            return true;
        }
    }
    return false;
}
function RenderSolution({elements, setSolutions, solution, solutions }){
    function solutionCallback(solution) {
        var el = setSolutionElementData(solution.elems,elements)
        solution.elems = el;
        var newSolutions = solutions.map((sol)=>{
            return (solution.id == sol.id) ? solution : sol;
        })
        console.log(newSolutions)
        project.solutions = newSolutions;
        setSolutions(newSolutions);
    }

    function deleteSolutionHandler(id){
        //console.log(pos, solutions)
        
        const confirmed = window.confirm('Are you sure you want to delete this solution?')
        
        if (confirmed) {
            var updatedSolutions = JSON.parse(JSON.stringify(solutions));
            updatedSolutions= updatedSolutions.filter((solution)=>{
                return solution.id != id;
            })
            setSolutions(updatedSolutions);
        }
    }

    return <div className="solutionBox" key={solution.id} style={{ 
            // borderBottom:'1px solid lightgray'
            paddingLeft:'10px',
            paddingRight:'10px',
            border: '1px solid #F3F4F6',
            borderRadius:'8px'
        }}>
            <Row className="d-flex justify-content-between align-items-center" style={{
                // marginBottom:'15px',
                // height:'48px'                    
                fontSize: '12px',
                lineHeight: '30px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                backgroundColor: 'rgb(243, 244, 246)',
                height: '58px',
            }}>
                <Col style={{
                    fontSize:'12px', 
                    textTransform:'uppercase',
                    letterSpacing: '0.05em',                        
                    fontWeight: '600',

                    }}>{solution.name} {/*t('solution')} {++index*/}</Col>
                <Col xd={2} style={{fontSize:'10px', color:'red', textAlign:'right'}}>
                    <DeleteIcon src={ico_del} alt='delete' onClick={()=>{deleteSolutionHandler(solution.id)}} />
                </Col>
            </Row>
            <Row>
                <Solution solution = { solution } callback={solutionCallback} 
                />
            </Row>
        </div>
}

function AddSolution({ addSolution }) {
    const { t } = useTranslation();
    function addSolutionHandler(){
        addSolution();
    }

    return <div className="d-flex justify-content-end"
    style={{
        marginBottom:'20px'
    }}
    >
        <Button
            onClick = {addSolutionHandler}
        >
            + {t("Add solution")}
        </Button>
    </div>
}

function getFirstProdData(element, products){
    //console.log(products)
    if(products[element.id] == undefined) {
        return null;
    }
    var firstProdId = products[element.id][0].id_products;
    var selected_sot = products[element.id][0].available_sots[0];
    var thickness = parseFloat(selected_sot['thickness']);
    let width = parseFloat(selected_sot['width']);
    let length = parseFloat(selected_sot['height']);
    var lambda = parseFloat(selected_sot['lambda']);
    var R = parseFloat(selected_sot['thermal_resistance']);
    var creation_method = selected_sot['creation_method'];
    let epd = getEpdFromProdBySot(products[element.id][0], selected_sot['id_systemic_properties_series_of_types']);
    return {
        prod_id: firstProdId,
        thickness: thickness*1000,
        width: width*1000,
        length: length*1000,
        sot_id: selected_sot['id_systemic_properties_series_of_types'],
        lambda: lambda,
        creation_method: creation_method,
        //R: (thickness/lambda).toFixed(2),
        R: R,
        GWP: calculateGWP(epd[1], element.area),
        epd:epd,
        INIES: isInies(epd),
    }
};
function setSolutionElementData(solutionElements, elements){    
    var updatedSolution = {};
    const products = parseProducts(solutionElements);
    elements.map((element,val) => {
        let key = element.id;
        let solutionElement = solutionElements[key];
        if(products[key] == undefined) {
            //console.log('no prod')
            updatedSolution[key] = null
        }else if(solutionElements[key].prod_id === null){
            console.log('set el data empty')
            updatedSolution[key] = {
                prod_id: null,
                sot_id: null          
            }

        }else {
			
            //console.log('change data')
            let product = getProductById(products[key], solutionElement.prod_id);
            let sotsArr = product['available_sots'];
            let selected_sot = getSotById(sotsArr, solutionElement.sot_id);
            if(selected_sot === null) {
                selected_sot = sotsArr[0];
            }
            let thickness = parseFloat(selected_sot['thickness']);
            let width = parseFloat(selected_sot['width']);
            let length = parseFloat(selected_sot['height']);
            let lambda = parseFloat(selected_sot['lambda']);
            let R = parseFloat(selected_sot['thermal_resistance']);
            let creation_method = selected_sot['creation_method'];
            let epd = getEpdFromProdBySot(product, selected_sot['id_systemic_properties_series_of_types']);
            updatedSolution[key] = {
                prod_id: solutionElement.prod_id,
                thickness: thickness*1000,
                width: width*1000,
                length: length*1000,
                sot_id: selected_sot['id_systemic_properties_series_of_types'],
                lambda: lambda,
                creation_method: creation_method,
                //R: (thickness/lambda).toFixed(2),
                R: R,
                GWP: calculateGWP(epd[1], element.area),
                // LCA: calculateLCA(epd, element.area),
                epd:epd,
                INIES: isInies(epd),
            }
        }
        return key;
    })
    // solution.elems = updatedSolution
    return updatedSolution;
}
function isInies(epds){
    var inies = null;
    for(let epd_id in epds) {
        var epd = epds[epd_id]
        if(epd && epd.INIES_number != null){
            inies = epd.INIES_number;
            continue;
        } else {
            return inies;
        }
    }
    return inies;
}

function calculateGWP(epd, area) {
    // console.log(epd)
    if(project.gwp_calc_method == GWP_CALC_METHOD.static) {
		
		/*
		var w = Math.round((
            Math.round(epd.A13*area*10000)/10000 +
            Math.round(epd.A4*area*10000)/10000 +
            Math.round(epd.A5*area*10000)/10000 +
            Math.round(epd.C4*area*10000)/10000 +
            Math.round(epd.C2*area*10000)/10000 +
            Math.round(epd.D*area*10000)/10000 
            // + epd.D*area
            )*10000)/10000;
		*/
			console.log("--------- GWP --------------------")
            console.log("A13 ++++++>> "+Math.round(epd.A13*area*10000)/10000);
            console.log("A4 ++++++>> "+Math.round(epd.A4*area*10000)/10000);
            console.log("A5 ++++++>> "+Math.round(epd.A5*area*10000)/10000);
            console.log("C4 ++++++>> "+Math.round(epd.C4*area*10000)/10000);
            console.log("C2 ++++++>> "+Math.round(epd.C2*area*10000)/10000);
            console.log("D ++++++>> "+Math.round(epd.D*area*10000)/10000);
            console.log("PH99 ++++++>> " + epd.PH99*area);
			console.log("w: " + w)
			console.log("----------------------------------")
			

		w = Math.round(100*epd.PH99*area)/100;
		
    return w
	
/*	return Math.round((
            epd.A13*area +
            epd.A4*area +
            epd.A5*area +
            epd.C4*area +
            epd.C2*area 
            // + epd.D*area
            )*1)/1;
			
	*/		
			
			
    } else {
        var w = Math.round((
            Math.round(epd.A13*area*10000)/10000 +
            Math.round(epd.A4*area*10000)/10000 +
            Math.round(epd.A5*area*10000)/10000 +
            Math.round(epd.C4*area*0.578*10000)/10000  +
            Math.round(epd.C2*area*0.578*10000)/10000 +
			Math.round(epd.D*area*0.578*10000)/10000 
            // + epd.D*area
            )*100)/100;
			console.log("--------- GWP --------------------")
            console.log("++++++>> epd.A13 * area: "+Math.round(epd.A13*area*10000)/10000);
            console.log("++++++>> epd.A4 * area: "+Math.round(epd.A4*area*10000)/10000);
            console.log("++++++>> epd.A5 * area: "+Math.round(epd.A5*area*10000)/10000);
            console.log("++++++>> epd.C4 * area * 0.578: "+Math.round(epd.C4*area*0.578*10000)/10000);
            console.log("++++++>> epd.C2 * area * 0.578: "+Math.round(epd.C2*area*0.578*10000)/10000);
            console.log("++++++>> epd.D * area: "+Math.round(epd.D*area*0.578*10000)/10000);
			console.log("w: " + w)
			console.log("----------------------------------")
			
		return w;
	}
}


function getEpdFromProdBySot(prod, sot_id) {
    var results = {};
    for(let criteria_id in config.impactCriteria){
        results[criteria_id] = null;
    }
    var repd = null;
    for(let epd of prod.epd.values) {
        // if(epd.type >6){
        //     console.log(epd)
        // }
        if(epd.id_sot == sot_id) {
            // console.log(epd)
            // repd=epd;
            results[epd.type] = epd;
        }
    }
    return results
    // return repd;
}

function getTypeById(id){
    const appTypeOptions = config.appTypeOptions;
    for(let type of appTypeOptions) {
        if(type.at_id == id){
            return type;
        }
    }
    return {name:'---'};
}

function getProductById(products, id) {
    for(let product of products) {
        if (product.id_products === id) {
            return product;
        }
    }
    return null;
};
function getSotById(sots, id) {
    for(let sot of sots) {
        if (sot.id_systemic_properties_series_of_types === id) {
            return sot;
        }
    }
    return null;
};

const Button = styled.button`
	padding: 10px 20px;
    background-color: white;
    color: ${definedStyles.color1};
    border: 1px solid ${definedStyles.color1};
    text-transform: capitalize;
    border-radius: 5px;
    min-width:150px;
`;
const ContentTab = styled.div`
    background-color:white;
    border-radius:8px;
    padding: 20px;
`
const ElementsBox = styled(Col)`
    display:inline-block;
    width: 400px;
`;
const ElementBox = styled(Row)`
    border-bottom:1px solid #E6E8F0;
    font-size:15px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 1px;
    margin-right: 1px;
    
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #111827;

`;
const SolutionsBox = styled(Col)`
    overflow-x: scroll;
    margin-bottom:15px;
    display:inline-block;
    width: calc(100% - 400px);
`;
const DeleteIcon = styled.img`
    padding: 7px;
    gap: 7px;
    cursor: pointer;
    width: 38px;
    height: 38px;
    background: #FEF5F4;
    border: 1px solid rgba(209, 39, 36, 0.15);
    border-radius: 5px;
`;

export default DefineSolutions
