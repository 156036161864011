import React, { Component } from "react";
import Cookies from 'js-cookie';
import {userApi} from "../../api/userApi";
import styled from "styled-components";
import { definedStyles } from "../../data/definedStyles";
import { data } from "../../data/data"
import SignInForm from "./SignInForm";
import { Alert, AlertTitle } from '@mui/material';
import SectionHeader from "../shared/SectionHeader";
import { useTranslation, withTranslation } from "react-i18next";
import { project } from "../../data/project";
import { t } from "i18next";
// const userApi = new UserApi(); 

class UserContainer extends Component{

    constructor(props){
        super(props);

        this.state = {
            // showModal: false,
            // modalContent: '',
            callback:this.props.callback,
            mode: this.props.mode,
            user: data.userController.user,
            cookies: Cookies.get("carbon-user"),
            window: this.props.window,
        }
    // const [cookies, setCookie, removeCookie] = useCookies(["carbon-user"]);
    }

    registerUrl = userApi.register('fr');
    reminderUrl = userApi.reminder('fr');
    editDataUrl = "";


    handler = event => {
        var data = event.data;
        if(data.sender =='userIframe'){
            if(data.msg === 'sendRegister'){
                this.setState({alertState: 1});
                setTimeout(()=>{this.setState({alertState: 0});},3000)
                this.state.callback('signed-up-to-save', data);
            } else if(data.msg === "iframeInit"){
                console.log('iframe init')
            
            } else if(data.msg === "userRegister"){
                console.log('user register')
                console.log(data.user_id)
                
                // project.user_id = data.user_id;
                this.setState({alertState: 1});
                setTimeout(()=>{this.setState({alertState: 0});},3000)
                this.state.callback('signed-up-now-save', data);
            }
        }
    }     

    componentDidMount(){
        console.warn('USER COUNTAINER MOUNTED')
        this.setState({
            mode: this.props.mode,
            user: data.userController.user,
            cookies: Cookies.get("carbon-user"),
            window: this.props.window,
            alertState: 0,
        })
           
        window.addEventListener("message", this.handler)
    }
    componentWillUnmount() {
        console.warn('USER COUNTAINER UNMOUNTED')
        window.removeEventListener("message", this.handler)
      }

    setCookie(name, data, exp){
        Cookies.set(name, data, { path: "/" }); // set the cookie
        this.setState({ cookies: Cookies.get(name) });
    }

    signInFormCallback(response) {

        console.log(response)
        this.setState({user: response});
        data.userController.user=response;
        data.userController.rtoken = response.rtoken;
        data.userController.token = response.token;
        this.state.callback('signed-in', response);
        this.setCookie('carbon-user',response.rtoken, {maxAge: 2592000});
    }
    callback(response){
        this.state.callback(response)
    }

    render(){
        const { t } = this.props;
        return (
            <>
                {this.state.alertState == 1 ?
                    <StyledAlert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        
                    </StyledAlert>
                    :<></>
                }

                {
                    this.props.mode == 'login' ?
                        <SignInForm userCallback={this.signInFormCallback.bind(this)} callback={this.callback.bind(this)} window={this.props.window}/>
                        : (this.props.mode == 'register' ?

                            <RegisterForm url={this.registerUrl} callbackTitle={this.state.callback} window={this.props.window}/>

                            : (this.props.mode == 'reminder' ?
                                <ReminderForm url={this.reminderUrl} callbackTitle={this.state.callback} window={this.props.window}/>
                                :
                                <EditForm url={data.userController.editDataUrl} callbackTitle={this.state.callback} window={this.props.window}/>
                            )
                        )
                }
            </>
        );
    }


}




function RegisterForm({url, callbackTitle, window}) {
    //console.log(url)
    const iframeParent = React.useRef();
    const iframe = React.useRef();
    
    function setHeight(el) {
        iframe.current.height = iframeParent.current.scrollHeight;
    }
    
    const { t } = useTranslation();

    return (
        <div 
            style={{
                paddingTop:'50px',
                height:'100%',
                maxHeight:'auto',
                overflow:'hidden'
            }}> 
            <SectionHeader title={t('signUp')} prev={window == 'modal' ?  null : 'home'} navCallback={callbackTitle}/>
                           
            {/* <iframe ref={iframe} width='100%' height='100%' onLoad={()=>{setHeight()}} sandbox="allow-scripts" src="http://localhost/iframe.php" ></iframe> */}
            
            <div 
                ref={iframeParent}
                style = {{
                    height: 'calc(100% - 80px)',
                }}>
                <iframe ref={iframe} width='100%' height='100%' onLoad={()=>{setHeight()}} src={url} ></iframe>
            </div>
        </div>
    )

}
function ReminderForm({url, callbackTitle, window}) {
    console.log(url)
    const iframeParent = React.useRef();
    const iframe = React.useRef();
    
    const { t } = useTranslation();
    function setHeight(el) {
        iframe.current.height = iframeParent.current.scrollHeight;
    }
    return (
        <div 
            style={{
                paddingTop:'50px',
                height:'100%',
                maxHeight:'auto',
                overflow:'hidden'
            }}> 
                <SectionHeader title={t('remindMe')} prev={window == 'modal' ?  null : 'home'} navCallback={callbackTitle}/>
            
                <div
                    ref={iframeParent} 
                    style = {{
                    height: 'calc(100% - 80px)',
                }}>
                    <iframe ref={iframe} width='100%' height='100%' onLoad={()=>{setHeight()}} src={url} ></iframe>
                </div>
                
        </div>
    )

}
function EditForm({url, callbackTitle, window}) {
    console.log(url)
    const iframeParent = React.useRef();
    const iframe = React.useRef();
    
    const { t } = useTranslation();
    function setHeight(el) {
        iframe.current.height = iframeParent.current.scrollHeight;
    }
    return (
        <div 
            ref={iframeParent}
            style={{
                paddingTop:'50px',
                height:'100%',
                maxHeight:'auto',
                overflow:'hidden'
            }}> 
            <SectionHeader title={t('remindMe')} prev={window == 'modal' ?  null : 'home'} navCallback={callbackTitle}/>
                
                
                <iframe ref={iframe} width='100%' height='100%' onLoad={()=>{setHeight()}} src={url} ></iframe>
        </div>
    )

}



const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`
const PanelButton = styled.button`
    background-color: ${definedStyles.color1};
    padding: 5px;
    width: 100%;
    color: white;
    border: none;
`;
const Input = styled.input`
    display: block;
    padding: 3px 5px;
    border-radius: 3px;
    border-width: 1px;
    margin-bottom: 5px;
`;
const SigningBox = styled.div`
    padding: 18px;
    background-color: ${definedStyles.color1};
    color: white;
    cursor: pointer;
    text-align: center;
    min-width: 200px;
    width: 100%;
`;
// export default withTranslation()(UserContainer);
export default UserContainer;
