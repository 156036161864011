import {config} from './config'
const GWP_CALC_METHOD = {
    static: 1,
    dynamic: 2
}
const project = {
    proj_id: null,
    user_id: null,
    elements: [],
    solutions: [],
    gwp_calc_method:GWP_CALC_METHOD.static,
    form: []
};

const project_template = {
    proj_id: null,
    user_id: null,
    elements: [],
    solutions: [],
    gwp_calc_method:GWP_CALC_METHOD.static,
    form: {
        projName: '',
        companyName: '',
        projLocation: '',
        typeOfProject: '',
        realizationYear: '',
        typeOfBuilding: '',
    }
}

export {project, project_template, GWP_CALC_METHOD};