// import { useTranslation } from 'react-i18next';
import UserController from '../components/shared/userController';
// const { t } = useTranslation();
var typeOfBuilding= [
    { 
        id: 1,
        name: "Individual house"
    },
    { 
        id: 2,
        name: "Collective building"
    },
    { 
        id: 3,
        name: "Non-residential building"
    },
    { 
        id: 4,
        name: "Industrial"
    },
    { 
        id: 5,
        name: "All buildings"
    },
    { 
        id: 6,
        name: "Others"
    },
];
var typeOfProject = [
    {
        id: 1,
        name: "New"
    },
    {
        id: 2, 
        name:"Renovation"
    },
];
var appTypeOptions = [
    {
        id: 2,
        name: 'Wall',
    },
    {
        id: 1,
        name: 'Attic',
    },
]; 
var impactCriteria = {
    1:{
        id: 1,                          // id 
        type: 'GWP',                    // nazwa skrotowa
        name:'Global warming',          // nazwa pelna
        unit:'kg CO\u2082-EQ UF',       // jednostka
        cycles: true,                   // wskaznik czy wartosc jest rozdzielona na cykle
        description: 'descGWP',
    },
    2:{
        id: 2,
        type: 'FW',
        name:'Fresh water',
        unit:'m\u00B3',
        cycles: true,
        description: 'descFW',
    },
    3:{
        id: 3,
        type: 'RE',
        name:'Renewable energy',
        unit:'MJ',
        cycles: true,
        description: 'descRE',
    },
    4:{
        id: 4,
        type: 'NRE',
        name:'Non-renewable energy',
        unit:'MJ',
        cycles: true,
        description: 'descNRE',
    },
    5:{
        id: 5,
        type: 'WD',
        name:'Waste disposal',
        unit:'kg',
        cycles: true,
        description: 'descWD',
    },
    6:{
        id: 6,
        type: 'NHW',
        name:'Non-hazardous waste',
        unit:'kg',
        cycles: true,
        description: 'descNHW',
    },
    7:{
        id: 7,
        type: 'RATIO',
        name:'Ratio of recycled material',
        unit:'%',
        cycles: false,
        description: 'descRATIO',
    },
    8:{
        id: 8,
        type: 'BIOGENIC',
        name:'Biogenic stored carbon',
        unit:'kg C',
        cycles: false,
        description: 'descBIO',
    },
    
    // 'IND19' => 1, //GWP
    // 'IND36' => 2, //Use of net fresh water
    // 'IND30' => 3, //use of renewable primary energy ressources
    // 'IND33' => 4, // use of non-renewable primary energy ressources
    // 'IND38' => 5, //Waste disposal
    // 'IND39' => 6, //Non-hazardous waste
};
var lifeCycle = [
    {
        id: 0, 
        code:'D',
        name: 'Module D',
        text: '',
        color:['#02bd71','#e84b4b'],
        // 'D+' : '#02bd71',
        // 'D-' :'#e84b4b'
    },
    {
        id: 1, 
        code:'C4',
        name: 'C4',
        text:'End of life',
        color:'#ae1d77',
    },
    {
        id: 2, 
        code:'C2',
        name: 'C2',
        text:'Transport',
        color:'#006cb5'
    },
    {
        id: 3, 
        code:'A5',
        name: 'A5',
        text:'Manufacturing',
        color:'#925dbd',
    },
    {
        id: 4, 
        code:'A4',
        name: 'A4',
        text:'Manufacturing',
        color:'#bf79ba',
    },
    {
        id: 5, 
        code:'A13',
        name: 'A1 / A2/ A3',
        text:'Raw minerals extraction',
        color: '#009fed'
    },
];


const data = {
    currentToken: '',
    currentUser: null,
    userController:  new UserController(),
    products: [],
    user_projects: [],
    form: [
        {
            title:'projectName',
            name:"projName",
            key:"projName",
            type:"text",
            placeholder:"Enter..",
            required:true,
            touched:false,
        },
        {
            title:'companyName',
            name:"companyName",
            key:"companyName",
            type:"text",
            placeholder:"Enter..",
            required:true,
            touched:false,
        },
        // {
        //     title:'projectLocation3',
        //     name:"projLocation",
        //     key:"projLocation",
        //     required:true,
        //     type:"text",
        //     placeholder:"e.g. Lyon, 69100, France",
        //     touched:false,
        // },
        {
            title:'typeOfProject',
            name:"typeOfProject",
            key:"typeOfProject",
            type:"select",
            options:typeOfProject,
            required:true,
            touched:false,
        },
        {
            title:'projectRealizationYear',
            name:"realizationYear",
            key:"realizationYear",
            type:"number",
            placeholder:"Enter..",
            required:false,
            touched:false,
        },
        {
            title:'typeOfBuilding',
            name:"typeOfBuilding",
            key:"typeOfBuilding",
            type:"select",
            options:typeOfBuilding,
            required:true,
            touched:false,
        },
    ],
    typeOfBuilding: typeOfBuilding,
    typeOfProject: typeOfProject,
    appTypeOptions: appTypeOptions,
    impactCriteria: impactCriteria,
    lifeCycle: lifeCycle,
}

export const APP_TYPE_PROPERTY_TAB_ID = 22
export {data};