import React from 'react'
import arrowImg from "../../assets/icons/arrow-back.svg";

import { Row, Col } from "react-bootstrap";

function SectionHeader({title, subtitle, prev=null, navCallback=null}) {

    function handleClick(e){
        navCallback(prev);
    }
  return (
       <Row>
           <Col>
                <h3>
                    {prev != null ?
                        <img
                        onClick={handleClick}
                        style={{
                            cursor:'pointer',
                        }}
                    src={arrowImg} />
                    :<></>}
                {title}</h3>
                <p className="descTitle">{subtitle}</p>
            </Col>
        </Row>
  )
}

export default SectionHeader