import React from "react";
import { GWP_CALC_METHOD, project } from "../../data/project";
import { Col, Row } from "react-bootstrap";
import { data } from "../../data/data";
import NavButton from "../shared/NavButton";
import { config, STATES } from "../../data/config";
import lang from "../../data/lang";
import { useCookies } from "react-cookie";
import ProjectForm from "./ProjectForm";
import arrowImg from "../../assets/images/head_arrow.png";
import SectionHeader from "../shared/SectionHeader";
import { useTranslation } from "react-i18next";
import TermsAndCond from "../TermsAndCond";
import CustomRadio from "../shared/CustomRadio";
import { Alert, AlertTitle } from "@mui/material";
import styled from "styled-components";

const DataForm = React.memo(({ navCallback }) => {
    const [formValues, setForm] = React.useState(project.form);
    const form = data.form;
    const [valid, setValid] = React.useState(validateForm());
    const [cookies, setCookie, removeCookie] = useCookies(["carbon-user"]);

    const [terms, setTerms] = React.useState(cookies['carbon-user'] ? true : false)

    const { t } = useTranslation();

    function termsCallback(value){
        setTerms(value)
    }
    function itemCallback(fieldTitle, value) {
        var updatedForm = JSON.parse(JSON.stringify(formValues))
        updatedForm[fieldTitle] = value;
        setForm(updatedForm)
        project.form = updatedForm;
        adjustElements();
    }
    function adjustElements(){
        if(project.elements.length){
            var elements = project.elements.map((element)=>{
                return {
                    ...element,
                    R_rec: getRRec(element)
                }

            })
            project.elements = elements
        }
    }

    function getRRec(element) {
        var typesOfProj = config.appTypeOptions
        for(let type of typesOfProj) {
            if(type.at_id == element.app_type) {
                if (project.form.typeOfProject == 1){
                    return type.r_rec_new;
                }
                if (project.form.typeOfProject == 2){
                    return type.r_rec_renovation;
                }
            }
        }
    }

    function validationCallback(){
        setValid(validateForm());
    }

    function validateForm() {
        var requiredFields = form.filter((field)=>{
            return field.required;
        });
        var valid = true;
        for(let field of requiredFields) {
            if(field.type ==='select' && (formValues[field.name] === 0 || formValues[field.name] === undefined || formValues[field.name] === null)){
                valid = false;
            }else{
                if(formValues[field.name] === '' || formValues[field.name] === undefined || formValues[field.name] === null){ 
                    valid = false;
                }
            }
        }
        return valid;
    }
    function LCATypeContainer(){
        const [currentLCA, setLCA] = React.useState(project.gwp_calc_method);
        const options = [
            {id:GWP_CALC_METHOD.static,label:t('Static LCA method')},
            {id:GWP_CALC_METHOD.dynamic,label:t('Dynamic LCA method')},
        ]
        function radioCallback(name, value){
            console.log(name,value)
            project.gwp_calc_method = value;
            setLCA(project.gwp_calc_method);
        }
        return <Row style = {{marginBottom:40}}>
            <Col xs={5}>
                <p>{t('Which assessment method do you want to use?')}</p>
                <CustomRadio callback={radioCallback} name={'LCA_select'} radio_value={currentLCA} options={options} />
           
            </Col>
            <Col xs={7}>
                <Alert severity="info">
                    <InfoList>
                        <li><span>{t("Static LCA")+t(":")}</span> {t("the results are taken directly from the EPD.")}</li>
                        <li><span>{t("Dynamic LCA")+t(":")}</span> {t("this calculation method complies with the RE2020. The results are obtained by applying to the static values the weighting coefficients available in article 11 of the order of August 4, 2021.")}</li>
                    </InfoList>
                    
                </Alert>
            </Col>
        </Row>
    }
    function ErrMsg({valid}) {
        if (!valid) {
            return (
                <div
                    style={{
                        color: "red",
                        textAlign: "right",
                        position: "relative",
                        top: "-30px",
                    }}
                >
                    {t('fillFields')}
                </div>
            );
        }
        return "";
    }
    return (
        <>
            <SectionHeader navCallback={navCallback} prev={data.userController.user ? STATES.projects_list : STATES.landing} 
            title = {'1/5 '+ t('generalTitle')}  
            subtitle = {t('generalSubitle')}/>

            <TermsAndCond terms={terms} callback={termsCallback}/>
            <ProjectForm form={form} formValues={formValues} itemCallback={itemCallback} validationCallback={validationCallback} />
            <LCATypeContainer />
            {/*<ErrMsg valid={valid} />*/}
            <NavButton canProceed={valid} actions={{
                prev:cookies['carbon-user'] ? {id:STATES.projects_list, text:t('back')} : null,
                next:{id:STATES.details, text:t('next')},
            }}
            callback={navCallback}/>
        </>
    );
});

const InfoList = styled.ul`
    span{
        font-weight:bold;
    }
`;
 export default DataForm;


