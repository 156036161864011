import React from "react";
import { Container } from "react-bootstrap";
import styled from 'styled-components';

import ProjectsList from "./projectsList/projectsList";
import DataForm from "./dataForm/dataForm";
import ProjectDetails from "./projectDetails/projectDetails";
import DefineSolutions from "./defineSolutions/defineSolutions";

import { project_template, project } from "../data/project";
import { config, STATES } from "../data/config";
import { definedStyles } from "../data/definedStyles";
import ChartsContainer from "./charts/ChartsContainer";
import ExportContainer from "./export/ExportContainer";
import { data } from "../data/data";
import LandingPage from "./landingPage/LandingPage";
import lang from "../data/lang"
import { useTranslation } from "react-i18next";

function Content({view, setView, customCallback}) {
    const states = STATES;

    const { t } = useTranslation();
    function setProject(proj){   
        console.log(proj)
        console.log(project)
        for(let field in project){
            console.log(field)
            if(proj[field] == undefined){
                console.log(field)
                project[field] = JSON.parse(JSON.stringify(project_template[field]));
            }else {
                project[field] = JSON.parse(JSON.stringify(proj[field]))
            }
        }
        setLastIds(project);
    }

    function setLastIds(project) {           
        config.elementsLastId = 0;
        config.solutionsLastId = 0;
        for(let sol of project.solutions) {
            if(sol.id > config.solutionsLastId)
                config.solutionsLastId = sol.id + 1
        }

        for(let el of project.elements) {
            if(el.id > config.elementsLastId)
                config.elementsLastId = el.id + 1
        }

    }
    function RenderPage({view}) {
        switch (view) {
            case states.landing:
                return <>
                    <LandingPage />
                </>                
            case states.projects_list:
                return <>
                    <AddSolution />
                    <ProjectsList callback={projectsActionCallback}/>
                </>                
            case states.form:
                return <DataForm 
                    navCallback={navButtonsCallback} 
                    key={'date-form'}
                />;
            case states.details:
                return <ProjectDetails 
                navCallback={navButtonsCallback}/>;
            case states.solutions:
                return <DefineSolutions 
                navCallback={navButtonsCallback}
                // valid={canProceed}
                />;
            case states.charts:
                //console.log('chart')
                return <ChartsContainer navCallback={navButtonsCallback} />;
            case states.export:
                return <ExportContainer navCallback={navButtonsCallback} customCallback={customCallback}/>;
            default:
                return <div>No tab selected</div>;
        }
    };

    var navButtonsCallback = (state_id) => {
        setView(state_id)
        // this.setState({ view: state_id });
    };

    function projectsActionCallback(action, proj_id){
        // console.log(action)
        if(action === 'edit') {
            var projToLoad = data.user_projects[proj_id];
            //console.log(projToLoad)
            var proj = JSON.parse(JSON.stringify(projToLoad.project))
            proj.user_id = projToLoad.user_id;
            proj.proj_id = proj_id;
            console.warn(proj);
            if(proj.solutions.length > 0 && proj.solutions[0].id === undefined) {
                proj.solutions = updateSolutionsWithNewFormat(proj.solutions);
            }
            proj.solutions = setIniesAprove(proj.solutions)
            console.log(proj)
            setProject(proj);
            // project = JSON.parse(JSON.stringify(proj))
            setView(STATES.export);
        } else if(action ==='refresh') {
            customCallback(action);
        } else if(action ==='remove') {
            customCallback(action);
        }
    }
    function setIniesAprove(solutions){
        for(let solution_index in solutions){
            var solution = solutions[solution_index];
            if(solution.elems[Object.keys(solution.elems)[0]].INIES == undefined){
                for(let elem_id in solution.elems){
                    solution.elems[elem_id].INIES = isInies(solution.elems[elem_id].epd)
                }
                solutions[solution_index] = solution;
            }
        }
        return solutions;
    }
    function isInies(epds){
        for(let epd_id in epds) {
            var epd = epds[epd_id]
            if(epd.INIES_number != null){
                continue;
            } else {
                return false;
            }
        }
        return true;
    }

    function updateSolutionsWithNewFormat(solutions){
        var newSolutions = [];
        for(let solution of solutions) {
            newSolutions.push({
                id:config.solutionsLastId++,
                elems: solution,
                fav: false,
                name: 'Solution #'+config.solutionsLastId
            })
        }
        return newSolutions
    }

    function AddSolution(){
        //console.log(project)
        //console.log(user_id)
        function addSolutionHandler(){
            var proj = JSON.parse(JSON.stringify(project_template))
            //console.log(proj)
            // proj.user_id = user_id;
            setProject(proj);
            setView(STATES.form)
        }
        return <div className="d-flex justify-content-between align-items-center"
        style={{
            marginBottom:'20px'
        }}
        >
            <p style={{
                fontWeight:'bold',
                fontSize:'30px',
                margin:'0px',                
                letterSpacing: '0.01em',
                }}>{t('myAssessments')}</p>
            <Button
            onClick={()=>{addSolutionHandler()}}>
               {t('New assessment')}
            </Button>   
        </div> 
    }

    return <div className="container">
            <div style={{marginBottom:'80px',marginTop:'100px'}}  key={'nav'}>
                <RenderPage view={view} key={'render-page'}/>
            </div>
        </div>
    
}
const Button = styled.button`
	padding: 10px 20px;
    background-color: ${definedStyles.color4};
    color: white;
    border: 1px solid ${definedStyles.color4};
    font-weight: bold;
    border-radius: 5px;
    min-width:150px;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
`;
export default Content;
