import React from 'react'
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { GWP_CALC_METHOD, project } from '../../data/project';
import { data, APP_TYPE_PROPERTY_TAB_ID } from '../../data/data';
import { definedStyles } from '../../data/definedStyles';
import parseProducts from './parseProducts';
import gwp_ico from "../../assets/icons/ico_co2.svg"
import lang from '../../data/lang';
import Api from '../../api/api';
import inies_ok_icon from '../../assets/icons/inies_aproved.svg'
import inies_not_ok_icon from '../../assets/icons/inies_not_aproved.svg'
import {TextField, FormControl, InputLabel, MenuItem, Select, Alert, AlertTitle} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export default function Solution({solution, callback, solution_index, style}) {

    
    function elementBoxCallback(el_index, element){
        solution.elems[el_index] = element
        callback(solution)
    }

    const products = parseProducts(solution);
    
    var index = 0;
    return (
        <SolutionBox style={style}> {console.log(solution)}
            {Object.keys(solution.elems).map((key,element, pos) => (
                <ElementBox
                    key={key} 
                    el_index={key} 
                    solutionElement={solution.elems[key]}
                    products = {products[key]}
                    callback={elementBoxCallback}
                    style={{}}
                />
            ))}
        </SolutionBox>
    )
}

function CustomSelect({element, setElement, name, value, options, value_field_name, disp_field_name, label, nullSelection}){
    function handleChange(e) {
        var newEl = JSON.parse(JSON.stringify(element));
        newEl[name]= e.target.value == '' ? null : e.target.value;
        setElement(newEl);
    }
    const { t } = useTranslation();
    return  <DSelect
                displayEmpty
                name={'define_solutions_'+element.prod_id+'_'+name}
                id={'define_solutions_'+element.prod_id+'_'+name}
                value={value == null ? '' : value}
                onChange={handleChange}
                // label={null}
                style={{
                    height:45,
                    fontSize:'15px',
                }}
            >
                {/* {nullSelection ? 
                    <MenuItem 
                        key={'null'} 
                        value={''} 
                    >
                        ---
                    </MenuItem>
                :<></>} */}
                <MenuItem disabled value="">
                    <em>{t(label)}</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem 
                        key={option[value_field_name]} 
                        value={option[value_field_name]} 
                    >
                        {option[disp_field_name]}
                    </MenuItem>
                ))}
            </DSelect>
}
function getExportURL(solutionElement) {
    if(solutionElement.prod_id == '' || solutionElement.prod_id == null) return null;
    
    var prod = getProductById(data.products, solutionElement.prod_id)
    return prod.website != undefined && prod.website != null && prod.website != '' ? prod.website: null;
}
function ExportUrl({element}){
    var url = getExportURL(element);
    if( url != null) {
        return <a style={{
            position:'relative',
            color:definedStyles.color1
        }} href={url} target="_blank">
            {/* <img src={open_icon} onClick={()=>{
                // exportAction(element)
            }}/> */}
            {t("step3 productLink")}
        </a>
    } else {
        return <></>
    }
}


function ElementBox({el_index, products, solutionElement,callback, style}){
    const [element, setElement] = React.useState(solutionElement);    
    const [showAlert, setAlert] = React.useState(0);

    const { t } = useTranslation();
    // const thickness=
    React.useEffect(() => {
        // console.log(element)
        callback(el_index, element)
    }, [element])

    
    function showINIESInfo(event,inies) {
        
        if(inies !== false){
            if(inies) {
                setAlert(1);
            }else {
                setAlert(2);
            }
        }else {
            setAlert(0)
        }
        // setTimeout(()=>{setAlert(0)},3000)
    }
    console.log(products, solutionElement)
    if(products == undefined || products == null ) {
        return (
            
        <ElementBoxRow
        style={style}>
            <Row>
                <Col md={9} style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    }}>
                    {t('noProds')}
                </Col>
                <Col md={3}>
                    <p
                    style={{
                        fontSize: '12px',
                        marginBottom: '0px',
                        // paddingLeft: '35px',
                        position: 'relative',
                        top:'50%',
                        transform:'translate(0px, -50%)'
                        }}>
                            {/* <img src={gwp_ico} alt="gwp_ico" style={{
                                width: '35px',
                                position: 'absolute',
                                left:'0px',
                                top:'-9px'
                            }}/> */}
                            <span>{t("GWP")} (kg CO<sub>2</sub>-Eq){t(":")}</span>
                            <span
                                style={{
                                    color: definedStyles.color1,
                                    fontWeight: 'bold',
                                    display:'block',
                                    fontSize:'15px',
                                    // marginLeft:'5px',
                                }}>
                                --- 
                            </span>
                    </p>
                </Col>
            </Row>
            <Row style={{
                fontSize: '12px',
                marginTop:'5px',
                color: '#5A5A59',
            }}>
                <Col xs={'auto'}>
                    <p>
                            Lambda (λ){t(":")} 
                            <span
                            style={{
                                color: definedStyles.color1,
                                fontWeight: 'bold',
                                marginLeft:'5px'
                            }}>
                                --- </span><span style={{fontWeight: 'bold',}}>W/mK</span>
                    </p>
                </Col>
                <Col xs={'auto'}>
                    <p>
                            R{t(":")} <span
                            style={{
                                color: definedStyles.color1,
                                fontWeight: 'bold',
                            }}>--- </span> <span style={{fontWeight: 'bold',}}>Km<sup>2</sup>/W</span>
                             
                    </p>                     
                </Col>
                <Col xs={'auto'}>                   
                </Col>
            </Row>
        </ElementBoxRow>
        )
    }
    return (
        <ElementBoxRow
        style={{            
            paddingTop: '15px',
            paddingBottom: '5px',
            borderBottom:'1px solid #E6E8F0'
        }}>
            {showAlert == 1 ? 
                <StyledAlert severity="success">
                    <AlertTitle>{t("hoverAlert certifiedByInies Verified Title")}</AlertTitle>
                    {t("hoverAlert certifiedByInies Verified Text")+t(":")} {solutionElement.INIES}
                </StyledAlert>
                :
                    (showAlert == 2 ?
                    <StyledAlert severity="error">
                        <AlertTitle>{t("hoverAlert certifiedByInies Unverified Title")}</AlertTitle>
                    </StyledAlert>
                    :<></>)
            }
            <Row>
                <Col md={5}>
                    {/* <Label>{t('product')}</Label> */}
                    <CustomSelect 
                        key={'prod_id' + el_index}
                        element={element}
                        setElement={setElement}
                        name="prod_id"
                        value = {solutionElement.prod_id}
                        options = {products.sort((a,b)=>{return a.name > b.name ? 1: -1})}
                        value_field_name = 'id_products'
                        disp_field_name = 'name'
                        label = "Select product"
                        nullSelection = {true}
                    />
                </Col>
                <Col md={3}>
                    {solutionElement.prod_id != null ? <>
                        {/* <Label>{t('thickness')} [mm]</Label> */}
                        <CustomSelect
                            key={'sot_id' + el_index}
                            
                            element={element}
                            setElement={setElement}
                            name="sot_id"
                            value = {solutionElement.sot_id}
                            options = {getFormattedThickness(products, element)}
                            value_field_name = 'id_systemic_properties_series_of_types'
                            disp_field_name = 'thickness'
                            label = "step3 thicknessDropDown"

                        />
                        </>
                        : <></>}
                </Col>
                <Col md={3}>
                    <p
                    style={{
                        fontSize: '12px',
                        marginBottom: '0px',
                        // paddingLeft: '35px',
                        position: 'relative',
                        top:'50%',
                        transform:'translate(0px, -50%)'
                        }}>
                            {/* <img src={gwp_ico} alt="gwp_ico" style={{
                                width: '35px',
                                position: 'absolute',
                                left:'0px',
                                top:'-9px'
                            }}/> */}
                            <span>{t("GWP")} (kg CO<sub>2</sub>-Eq){t(":")}</span>
                            <span
                                style={{
                                    color: definedStyles.color1,
                                    fontWeight: 'bold',
                                    display:'block',
                                    fontSize:'15px',
                                    // marginLeft:'5px',
                                }}>
                                    {solutionElement.prod_id != null ? 
                                        <>
                                            {t('num',{val:Math.round(solutionElement.GWP*100)/100}) } 
                                        </>:<>---</>
                                    }
                            </span>
                    </p>
                </Col>
                <Col md={1}>
                    {solutionElement.prod_id != null ? 
                    <div style={{cursor:'pointer'}} 
                    onMouseEnter={(event)=>{showINIESInfo(event,solutionElement.INIES)}}
                    onMouseLeave={(event)=>{showINIESInfo(event,false)}}
                    >{
                    solutionElement.INIES? 
                    <img src={inies_ok_icon} />:
                    <img src={inies_not_ok_icon} />
                    }</div>
                    :<></>}
                </Col>
            </Row>
            <Row style={{
                fontSize: '12px',
                marginTop:'5px',
                color: '#5A5A59',
            }}>
                <Col md={8}>
                    <Row>
                        <Col xs={'auto'}>
                            <p>
                                    Lambda (λ){t(":")} 
                                    <span
                                    style={{
                                        color: definedStyles.color1,
                                        fontWeight: 'bold',
                                        marginLeft:'5px'
                                    }}>
                                         {solutionElement.prod_id != null ? 
                                        <>
                                            {t('num',{val:solutionElement.lambda})}
                                        </>:<>---</>
                                    }
                                         </span><span style={{fontWeight: 'bold',}}>W/mK</span>
                            </p>
                        </Col>
                        <Col xs={'auto'}>
                            <p>
                                    {'R'+t(":")} <span
                                    style={{
                                        color: definedStyles.color1,
                                        fontWeight: 'bold',
                                    }}>
                                         {solutionElement.prod_id != null ? 
                                        <>
                                            {t('num',{val:solutionElement.R, minimumFractionDigits: 2})}
                                        </>:<>---</>
                                    }
                                         </span> <span style={{fontWeight: 'bold',}}>m<sup>2</sup>K/W</span>
                                    
                            </p>                     
                        </Col>
                        <Col xs={'auto'}>                    
                            <ExportUrl element={element} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    {
                    project.gwp_calc_method == GWP_CALC_METHOD.static ?                        
                        t('Static LCA'):
                        t('Dynamic LCA')
                    }
                </Col>
				{(solutionElement.creation_method && solutionElement.creation_method.length > 0) ? (
					<Col md={2}>
						<div style={{cursor:'pointer'}} className={`${solutionElement.creation_method=="+ A2" || solutionElement.creation_method=="+A2" ? 'mmc_d_02' : 'mmc_d_01'}`} title={infoTxt(solutionElement.creation_method)}>
						{solutionElement.creation_method}
						</div>
					</Col>
					):(<Col md={2}></Col>)
					
				}
            </Row>
        </ElementBoxRow>
    )
}

function infoTxt(param) {
	var s = "";
	switch(param) {
		default:
			s = "selon norme NF EN 15804+A1";
			break;
		case "+A2":
		case "+ A2":
			s = "selon norme NF EN 15804+A2";
			break;
	}
		
	return s;
}

function getFormattedThickness(products, element) {
    // console.log(products, element)
    if(element.prod_id == null) { return ['---']}
    var sorted_sots = getProductById(products, element.prod_id).available_sots.sort(
        (a,b)=>{ return a.thickness > b.thickness? 1: -1})
    return sorted_sots.map((sot)=>{
        return {
            ...sot,
            thickness: parseFloat(sot.thickness)*1000+'mm'
        }
    })
}
function getProductById(products, id) {
    for(let product of products) {
        // //console.log(product, id)
        if (product.id_products == id) {
            return product;
        }
    }
    return null;
};


const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
    margin-bottom: 0px;
`

const Label = styled.label`
    width: 100%;
    display: block;
    font-size:12px;
`;
const DSelect = styled(Select)`
    width: 100%;
    font-size: 15px;
    legend{
        display:none;
    }
`;
const Input = styled.input`
    width:100%;
    padding: 3px 3px;
`;

const ElementBoxRow = styled.div`
    
`;
const SolutionBox = styled.div`
    // padding: 15px 15px;
    width:100%;
    display: inline-block;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
`;
