import React, { Component } from 'react'
// import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Col, Modal, Row } from "react-bootstrap";
import ico_edit from "../../assets/icons/ico_edit.svg";
import ico_down from "../../assets/icons/ico_down.svg";
import ico_copy from "../../assets/icons/duplicate.svg";
import Api from '../../api/api';
import { data } from '../../data/data';
import { withTranslation } from 'react-i18next';
export class ControlTab extends Component {
    constructor(props) {
        super(props);
        // console.log(props)
        this.info = this.props.info;
        this.date = this.info.date;
        this.m_date = this.info.m_date;
        this.proj_id = this.props.info.id;
        this.api = new Api();
        this.callback = this.props.callback;
        
    }
    handleEdit(){
        this.callback('edit',this.proj_id);
    }
    handleCopy(){
        this.callback('copy',this.proj_id);
    }

    
    render() {
        return (
            <Row>
                <Col style={{paddingRight:'3px'}}><Button onClick={()=>this.handleCopy()}className="p-2 bd-highlight"><Img src={ico_copy} /></Button></Col>
                <Col style={{paddingLeft:'3px', paddingRight:'3px'}}><Button onClick={()=>this.handleEdit()}className="p-2 bd-highlight"><Img src={ico_edit} /></Button></Col>
                {/* <Col style={{paddingLeft:'3px'}}><DownloadButton api={this.api} data={data} proj_id={this.proj_id}/></Col>                */}
                
            </Row>
            // <DataContainer>
            //     <ButtonsContainer>
            //         <ButtonsRow className="d-flex bd-highlight mb-3">
            //             <Button onClick={()=>this.handleEdit()}className="p-2 bd-highlight">{t('edit')} <Img src={ico_edit} /></Button>
            //             <DownloadButton api={this.api} data={data} proj_id={this.proj_id}/>
            //             {/* <Button className="p-2 bd-highlight">Download  <Img src={ico_down} /></Button> */}
            //             <Button onClick={()=>this.handleDelete()} className="ms-auto p-2 bd-highlight">{t('delete')}  <Img src={trash} /></Button>
            //         </ButtonsRow>
            //     </ButtonsContainer>
            // </DataContainer>
        )
    }
}

function DownloadButton({api,data, proj_id}){
    const formRef = React.useRef();
    const inputRef = React.useRef();
    function handleClick(e){
        e.preventDefault();
        //parse data
        var project = {
            elements: data.user_projects[proj_id].project.elements,
            form: data.user_projects[proj_id].project.form,
            solutions: data.user_projects[proj_id].project.solutions,
            proj_id: proj_id,
            user_id: data.user_projects[proj_id].user_id,
        }
        inputRef.current.value = JSON.stringify(project);
        formRef.current.submit();
        
    }
    function handleSubmit(){

    }

    return <form ref={formRef} target="_blank" method="POST" action={api.printUrl()} onSubmit={handleSubmit}>
                <Button type="submit" className="p-2 bd-highlight"
                onClick={handleClick} 
                >
                    <Img src={ico_down} />
                </Button>
                <input ref={inputRef} hidden name="data" value='' readOnly/>
            </form>
}
function parseDate(date){
    var parts =date.split('-');
    return parts[2]+'/'+parts[1]+'/'+parts[0];
}

const ButtonsRow = styled.div`
    @media (max-width: 991px) {
        display:block !important;
    }
    @media (max-width: 767px) {
        display:flex !important;
    }
    @media (max-width: 439px) {
        display:block !important;
    }
`;
const Button = styled.div`
    width: 35px;
    height: 35px;

    background: #FFFFFF;
    /* KI/Line/ON LightBG */

    border: 1px solid #E6E8F0;
    border-radius: 3px;
    cursor:pointer;
    @media (max-width: 991px) {
        text-align: center;
    }
    
`;
const Img = styled.img`
    width:100%;
    position: relative;
    top: -5px;
`;
const DataContainer = styled.div`
    position: relative;
    padding: 15px;
    height: 100%;
    @media (max-width: 959px) {
        padding: 8px 5px;
    }
    @media (max-width: 439px) {
        padding-bottom:140px
    }
`;

const ButtonsContainer = styled.div`
    position:absolute;
    padding: 0px 15px;
    bottom:0px;
    left:0px;
    width:100%;
    @media (max-width: 959px) {
        padding: 0px 5px;
    }
`;
export default withTranslation()(ControlTab)
