import React, { Component } from "react";
import DataFormatter from "./api/dataFormatter";
import Api from './api/api';
import { data } from './data/data';
import styled from 'styled-components';
import Content from './components/content';
import { STATES } from "./data/config";
import Footer from "./components/footer";
import LandingPage from "./components/landingPage/LandingPage";
import FurtherHeader from "./components/FurtherHeader";
import { project, project_template } from "./data/project";
import CookiePop from "./components/CookiePop";
import { withTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import i18n from "i18next";


class SubApp extends Component {
    constructor(props) {
        super(props);
        this.dataFormatter = new DataFormatter();
        console.log(this.props.user)
        this.api = new Api();
        this.data = data;
        this.project = project;
        this.state = {
            user: this.props.user? this.props.user: null,
            view: STATES.landing,
            isTerms: this.props.user.rtoken ? true: false,
            projData: false,
            headerInitState: null,
            alertState: 0,
        }
    }
    setView(view) {
        this.setState({view: view});
    }

    componentDidMount(){
        if (window && window.OneTrust) {
            console.log(i18n.language)
            window.OneTrust.changeLanguage(i18n.language)
        }
    }


    headerCallback(resp, data = null){
        console.log(resp)
        if(resp === 'sing_in'){            
        
        }else if(resp === 'myAssessments'){
            this.setView(STATES.projects_list)
        }else if(resp === 'home'){
            this.setView(STATES.landing)
        }else if(resp === 'sign-in-showed'){
            console.log(resp)
            this.setState({headerInitState:null})
        }else if(resp === 'sign-in-showed-now-save'){
            console.log(resp)
            console.log(this.data)
            console.log(project)
            project.user_id = this.data.currentUser;
            this.api.saveProject(project).then((response)=>{
                if(response) {
                    console.log(response)
                    project.proj_id = response
                    this.setAlert(1);
                    setTimeout(()=>{this.setAlert(0)},3000)
                }
            });
            this.setState({headerInitState:null})

        }else if(resp === 'signed-up-to-save'){
            console.log('signed-up-to-save');
            console.log(this.data)
            console.log(project)
            
        
        }else if(resp === 'signed-up-now-save'){
            console.log(resp);
            console.log(this.data)
            console.log(project)
            
            project.user_id = data.user_id;
            this.api.saveProject(project).then((response)=>{
                if(response) {
                    console.warn(response)
                    project.proj_id = response
                    this.setAlert(1);
                    setTimeout(()=>{this.setAlert(0)},3000)
                    this.setState({headerInitState:null})
                }
            });
        }
    }
    setAlert(val){
        this.setState({alertState: val});        
    }

    customCallback(action){
        console.log(action)
        if(action ==='refresh') {
            // setInitData([initData[0], initData[1], false]);
        }else if(action === 'myAssessments'){
            this.setView(STATES.projects_list)
        }else if(action === 'start-guest'){
            var proj = JSON.parse(JSON.stringify(project_template))
            setProject(proj);
            this.setView(STATES.form)
        }else if(action === 'sign-in') {
            console.log('sign-in')
            
            this.setState({headerInitState:'login-with-save'})
        }else if(action === 'signed-up-to-save'){
            console.log('signed-up-to-save');
        }
    }

    handleHeaderPrompt = (headerState) => this.setState({headerState})

    render(){
        const { t } = this.props;
        return <>
        
                
            <div className="main-page" style={{
                position: 'relative',
                minHeight:'100vh',
            }}>
                {this.state.alertState == 1 ? 
                    <StyledAlert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Project saved
                    </StyledAlert>
                    :
                        (this.state.alertState == 2 ?
                        <StyledAlert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            There was an error while saving your project. Please try again later
                        </StyledAlert>
                        :<></>)
                }

                {/* <Language setLang={setLanguageCallback}/> */}
                    {
                    this.state.view === STATES.landing ?
                        <>  
                            <LandingPage setView={this.setView.bind(this)} customCallback={this.customCallback.bind(this)}/>                    
                        </>
                        : 
                        <>
                            <div className="header">
                                <FurtherHeader headerInitState={this.state.headerInitState} headerCallback={this.headerCallback.bind(this)} setView={this.setView.bind(this)}/>
                            </div>
                            <ContentContainer>
                                <Content view={this.state.view} setView={this.setView.bind(this)} customCallback={this.customCallback.bind(this)}/>
                                
                            </ContentContainer>
                            <FooterDiv>
                                <Footer />
                            </FooterDiv>
                        </>
                    }                    
            </div>
        </>
    }
}


function setProject(proj){
    for(let proj_key in proj) {
        project[proj_key] = proj[proj_key];
    }
    console.log(project)
    
}


const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`

const ContentContainer = styled.div`
    padding-top:40px;
    padding-bottom:15px;

    @media (max-width:100px) {        
        padding-top:90px;
    }
`;
const FooterDiv = styled.div`
    position:absolute;
    bottom:0px;
    left:0px;
    width:100%;
    background-color:white;
`;
export default withTranslation()(SubApp);