import { config } from "../../data/config";
import { data } from "../../data/data";


class sharedMethods{  
    
    static getProdName(prod_id){
        const products = data.products;
        for(let product of products) {
            if (product.id_products === prod_id) {
                return product.name;
            }
        }
        return null;
    }
    
    static getTypeName(type) {
        
        var typeObj = sharedMethods.getTypeById(type)
        
        if(typeObj === null){
            return '--';
        }
        return typeObj.name 
    }
    static getTypeById(id) {
        for(let type of config.appTypeOptions) {
            if(type.at_id == id) {
                return type;
            }
        }
        return null;
    }
}

export default sharedMethods