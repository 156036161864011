import React from "react";
import logo from "../assets/images/logo.svg";
import logo_img from "../assets/images/logo.png";
import styled from "@emotion/styled";
import { definedStyles } from "../data/definedStyles";
import { data } from "../data/data";
import i18n from "i18next";
import arrow_down from '../assets/icons/arrow_down.svg'
import ico_logout from '../assets/icons/logout.svg'
import ico_overview from '../assets/icons/overview.svg';
import user_icon_active from "../assets/icons/user-circle-logged.svg";
import user_icon_inactive from "../assets/icons/user-circle-inactive.svg";
import { useTranslation } from "react-i18next";
import { COUNTRIES, COUNTRY } from "../data/config";

function Header({headerCallback, mode, userData}){
    const [langDropdownState, setLangDropdownState] = React.useState(false)
    const [activeLang, setActiveLang ] = React.useState(country(i18n.language))
    const { t } = useTranslation();
    function country(lang) {
        var cntr =  COUNTRIES.filter((country)=> {return country.lang==lang})
        return cntr.length ? cntr[0] : null;
    }
    function SignedButton({callback, userData}){
        const dropdownRef = React.useRef();
        const [dropdownState, setDropdownState] = React.useState(false)

        function toggleDropdown(){
            setDropdownState(!dropdownState);
        }

        return <>
        <UserProfileBox 
            className = {dropdownState ? 'user-box-open':'user-box-close'}
            onClick={()=>{
                    // callback('edit')
                    toggleDropdown()
                }}
        > 
            <div className="user-name">                
                <img className='user-icon'
                    alt="icon"
                    src={user_icon_active}
                />
                <p style={{margin:0}}>{getUserName(userData)}</p>
                
            </div>
            <div ref={dropdownRef} style={{
                maxHeight:dropdownState ? 'unset':'0px',
                overflow:'hidden',
                }}>  
                    <Separator />
                    <DropdownTab onClick={()=>{callback('myAssessments')}}>
                            <img
                            alt="icon"
                            src={ico_overview}
                        />{t('myAssessments')}
                    </DropdownTab>
                    <DropdownTab onClick={()=>{callback('edit')}}>
                            <img
                            alt="icon"
                            src={user_icon_inactive}
                        />{t('settings')}
                    </DropdownTab>
                    <Separator />
                    <DropdownTab onClick={()=>{callback('logout')}}>
                            <img
                            alt="icon"
                            src={ico_logout}
                        />{t('logout')}
                    </DropdownTab>
            </div>
            
        </UserProfileBox>        
        </>
    }
    
    function LoginButton({callback}){

        function handleLoginClick(){
            console.log('sign in click') 
            callback('sign_in')
        }
        function handleRegisterClick(){
            console.log('sign up click')
            callback('sign_up')
        }

        return <div style = {{
            display:'inline-block',
            marginLeft:'25px',
            lineHeight: '64px',
            paddingRight:'25px',
            cursor:'pointer',            
            verticalAlign: 'top'

        }}   
            >
                <span style = {{cursor:'pointer'}} onClick={handleLoginClick}>{t("header SignIn")}</span> / <span  style = {{cursor:'pointer'}} onClick={handleRegisterClick}>{t("header SignUp")}</span>
            <img
                    alt="icon"
                    src={user_icon_inactive}
                    style={{
                        display: "inline-block",
                        width: "34px",
                        position: "relative",
                        top:'-2px',
                        left:'15px',
                        borderRadius: '30px',
                    }}
                />
        </div>

    }

    function LanguageButton({i18n}){
        const dropdownRef = React.useRef();
        function toggleDropdown(){
            setLangDropdownState(!langDropdownState);
        }
        function setLang(lang){ 
            setActiveLang(country(lang))  
            i18n.changeLanguage(lang)
            if (window) {
                window.OneTrust.changeLanguage(lang)
            }
        }
        return (<LanguageBox style={{
            display:"inline-block",}}
            className = {langDropdownState ? 'language-box-open':'language-box-close'}
            >
                <div style={{
                    display:"inline-block",            
                    verticalAlign: 'top',
                    lineHeight:'64px',
                    cursor: 'pointer',
                    textAlign:'center',
                    paddingLeft:15,
                    paddingRight:15
                }}
                onClick = {()=>{toggleDropdown()}}>
                    {  }
                    <span style={{
                        transform: 'rotate(180deg)'
                    }}> {t(activeLang.name)} <img src={arrow_down} alt="v"/></span>
                </div>
                <div ref={dropdownRef} style={{
                    maxHeight:langDropdownState ? 'unset':'0px',
                    overflow:'hidden',
                    }}>  
                    {COUNTRIES.map((country) =>
                    <div key={'lang_box_'+country.lang}>
                        <Separator key={'lang_separator_'+country.lang} />
                        <DropdownTab key={'lang_'+country.lang} onClick={()=>{setLang(country.lang)}}>
                                {t(country.name)}
                        </DropdownTab>
                    </div>
                    )}
                </div>
            </LanguageBox>
        );
    }
    function handleLogoClick(){
        if(data.userController.user)
            headerCallback('myAssessments')
        else
            headerCallback('home')
    }

    function middlemanCallback(props){
        headerCallback(props)
    }
    return (
        <HeadBar>
            {mode !=='disclaimer' ?
            <Logo src={logo_img} onClick={handleLogoClick} style={{cursor:'pointer'}}/>
            :<></>}

            <div style = {{
                position:'absolute', 
                top:'0px', 
                right:'0px'
                }}>
                    <LanguageButton i18n={i18n} />
                    {data.userController.user == null ?
                    <>                        
                        <LoginButton callback={middlemanCallback} />
                        {/* <RegisterButton callback={callback} /> */}
                    </>
                :
                    <>
                        <SignedButton callback={middlemanCallback} userData={data.userController.user.userData} />
                        {/* <LogoutButton callback={callback} userData={data.userController.user.userData} /> */}
                    </>
                }
            </div>
        </HeadBar>
    );
    
}

function getUserName(data){
    return data.name[0]+'. '+data.surrname;
}
const LanguageBox = styled.div`
background: #FFFFFF;
&.language-box-open{
    // border: 1px solid #D1D5DB;
    box-shadow: 1px 1px 5px lightgrey, -1px -1px 5px lightgrey;
    border-radius: 8px
}
`
const UserProfileBox = styled.div`
    display:inline-block;
    margin-left:25px;
    cursor:pointer;    
    vertical-align: top;
    border-radius: 8px;
    width:84px;
    
    .user-name{
        margin:0px;
        line-height:64px;
        position:relative;
        padding-left:60px;
        height: 64px;

        .user-icon{
            width: 36px;
            // background-color: ${definedStyles.color1};
            // border: 3px solid ${definedStyles.color1};
            border-radius: 30px;
            position:absolute;
            top:50%;
            left:10px;
            transform: translate(0px, -50%);
        }

    }

    &.user-box-open{
        box-shadow:1px 1px 5px lightgray, -1px -1px 5px lightgray;
        background-color:white;
        min-width: 300px;
    }
    &.user-box-close{
        min-width:unset;
        .user-name{
            padding-left:24px;
            padding-right:24px;
            p{
                display:none;
            }
            .user-icon{
                left:50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    
`
const Separator = styled.hr`
    margin: 0px;
    color: gray;
`;
const DropdownTab= styled.div`
    padding:10px;
    padding-left:25px;
    cursor:pointer;
    img{        
        display: inline-block;
        width: 15px;
        position: relative;
        top:-2px;
        left:-8px;
    }
`;

const HeadBar = styled.div`
    height: 64px;
    width: 100%;
    z-index:5;
    position: absolute;
    top:0px;
    left:0px;
    background-color:white;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    @media (max-width: 450px) {
        // padding-top:70px
    }
`;

const StyledRegisterButtton = styled.div`
    padding: 25px;
    width:150px;
    text-align:center;
    cursor: pointer;
    display: inline-block;
    
    @media (max-width: 1000px) {
        // float:right;
        display:block;
        padding: 18px;
    }
`;
const StyledLoginButtton = styled.div`
    // padding: 25px;
    // width:250px;
    text-align:center;
    cursor: pointer;
    display: inline-block;
    // background-color: rgb(69, 152, 236);
    // color: white;
    // float:right;

    @media (max-width: 1000px) {
        // float:unset;
        // display:block;
        // padding: 18px;
    }
`;

const Logo = styled("img")`
    height: 34px;
    position: absolute;
    left:25px;
    top:20px;
    
    @media (max-width: 1000px) {
        width: 100px;
        top:15px;
    }
`;

export default Header;
