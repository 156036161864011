
import { project } from '../../data/project';
import { data } from '../../data/data';

function parseProducts() {    
    // const elements = project.elements;
    var results = {};
    for(let element of project.elements) {
        var R = parseFloat(element.R_val)
        var filteredProds = data.products.filter((prod)=>{
            // prod.app_type.values[0].at_id == element.subtype
            return prod.app_type.values.filter((sot)=>{
                // console.log(sot.at_id , element.app_type)
                 return sot.at_id == element.app_type
            }).length > 0
        })
        for(let prod of filteredProds) {
            
            var filteredSots = prod.sot.values[0].filter((sot) => {
                // console.log(prod, sot.id_systemic_properties_series_of_types, epdExistsForSot(prod, sot.id_systemic_properties_series_of_types))
                return parseFloat(sot.thickness)/parseFloat(sot.lambda) >= R && epdExistsForSot(prod, sot.id_systemic_properties_series_of_types)
            });
            if(filteredSots.length>0) {
                var prodCpy = JSON.parse(JSON.stringify(prod));
                prodCpy['available_sots'] = filteredSots;
                if(results[element.id] == undefined){
                    results[element.id] = [];
                }
                results[element.id].push(prodCpy);
            }
        }
    }
    return results;

}

function epdExistsForSot(prod, sot_id){
    if(prod.epd && prod.epd.values)
    {
        for(let epd of prod.epd.values) {
            if(epd.id_sot == sot_id) {
                return true;
            }
        }
    }
    return false;
}

export default parseProducts
