import React from 'react'
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { GWP_CALC_METHOD, project } from '../../data/project'
import ProductsList from './ProductsList'
import { definedStyles } from '../../data/definedStyles';
import NavButton from '../shared/NavButton';
import { STATES } from '../../data/config';
import Api from '../../api/api';
import { useCookies } from 'react-cookie';
import { data } from '../../data/data';
import lang from '../../data/lang';
import SectionHeader from '../shared/SectionHeader';
import CustomCheckbox from '../shared/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';

function ExportContainer({navCallback, customCallback}) {
    const [valid, setValid] = React.useState(true);
    const [cookies, setCookie] = useCookies(["carbon-user"]);
    const [showAlert, setAlert] = React.useState(0);
    const [selectedSolutions, setSelectedSolutions] = React.useState(
        project.solutions.map((solution, index) => {
            return solution.id;
        })
    );
    const { t } = useTranslation();
    console.log(selectedSolutions, project.solutions)
    function isUser(){
        return cookies['carbon-user'] && data.currentUser !== null;
    }
    const api = new Api();
    // var selectedSolutions = project.solutions.map((solution, index) => {
    //     return true;
    // })
    function callback(selectedSols){
        // selectedSolutions=selectedSols;
        console.log(selectedSols)
        setSelectedSolutions(selectedSols)
    }
    function areAllSelected(){
        for(let sol of project.solutions) {
            if(!selectedSolutions.includes(sol.id)) {
                return false;
            }
        }
        return true;
    }
    function checkboxCallback(name, value){
        console.log(value)
        var selectedSols = [];
        if(value)
            for(let sol of project.solutions) {
                selectedSols.push(sol.id)
            }

            console.log(selectedSols)
        setSelectedSolutions(selectedSols);
    }
    return (
        <>
        <SectionHeader navCallback={navCallback} prev={STATES.charts} 
            title = {'5/5 '+ t("Export & download")}
            subtitle = {t("step5 stepDescription")}/>
            <div style={{
                marginBottom:'50px',
                marginTop:'50px'
            }}>
                {
                project.gwp_calc_method == GWP_CALC_METHOD.static ?                        
                    <LCAInfoLabel>{t('Static LCA')}</LCAInfoLabel>:
                    <LCAInfoLabel>{t('Dynamic LCA')}</LCAInfoLabel>
                }
                {/* <Row style={{marginBottom:50}}>
                    <Col>
                        <CustomCheckbox 
                            value = {areAllSelected()}
                            name = {'selectedAllSolutions'}
                            labelText = {'All'}
                            callback = {checkboxCallback}
                        />
                    </Col>
                    <Col xs="auto">
                        <ExportPdfButton 
                            api={api} 
                            data={project} 
                            user={isUser()} 
                            user_id={data.currentUser} 
                            selectedSolutions={selectedSolutions} 
                        />
                    
                        
                    </Col>
                </Row> */}
                <ProductsList callback={callback} selectedSolutions={selectedSolutions} />
                <Actions api={api} customCallback={customCallback} setAlert={setAlert} data={project} user={isUser()} user_id={data.currentUser} />
                
            </div>
            
            <NavButton canProceed={valid} actions={{
                prev:{id:STATES.charts, text:'< Back'},
                next:{id:null, text:''},
            }}
            callback={navCallback}/>

            {showAlert == 1 ? 
                <StyledAlert severity="success">
                    <AlertTitle>{t("success")}</AlertTitle>
                    {t("step5 alert projectSaved")}
                </StyledAlert>
                :
                    (showAlert == 2 ?
                    <StyledAlert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        There was an error while saving your project. Please try again later
                    </StyledAlert>
                    :<></>)
            }
        </>
    )
}
function ExportPdfButton({api, data, user, user_id,selectedSolutions}){
    const { t } = useTranslation();
    
    function parseDataForPdf(){
        var parsedData = JSON.parse(JSON.stringify(data));        
        var solutions = [];
        console.log(selectedSolutions)
        for(let sol of data.solutions) {
            if(selectedSolutions.includes(sol.id)){           
                solutions.push(appendProdsUrls(sol))
            }
        }
        console.log(solutions)
        parsedData.solutions = solutions;
        return JSON.stringify(parsedData);
    }
    function appendProdsUrls(solution){
        for(let el_id in solution) {
            let el = solution.elems[el_id];
            if(el == null){
                continue;
            }

            el.url =  Api.getBimGeneratorUrl(el.prod_id, el.thickness);
            solution.elems[el_id] = el;
        }
        return solution;
    }
    return (
        <ButtonWrap style={{
                pointerEvents: user ? 'auto':'none',
                opacity: user ? '1': '0.5'
            }}>
            <form target="_blank" method="POST" action={api.printUrl()} >
                <Button type="submit" 
                >
                    {t('exportPdf')}
                </Button>
                <input hidden name="data" value={parseDataForPdf()} readOnly/>
            </form>
        </ButtonWrap>
    )
}
function Actions({api, data, user, user_id, setAlert, customCallback}){
    const formRef = React.useRef();
    const { t } = useTranslation();
    
    const [ oldProj, setProj ] = React.useState(data.proj_id !=null ? true: false);

    function handleSave(e, asNew=false){
        if(!user){
            console.log('no user')
            customCallback('sign-in')
            return false;
        }
        data.user_id = user_id
        
        if(data.user_id == undefined){ return false }
        if(asNew) { data.proj_id = null }

        console.log(data)
        api.saveProject(data).then((response)=>{
            if(response) {
                console.log(response)
                data.proj_id = response
                setProj(true) 
                setAlert(1);
                setTimeout(()=>{setAlert(0)},3000)
            }
        });
    }
    
    function appendProdsUrls(solution){
        for(let el_id in solution) {
            let el = solution[el_id];
            if(el == null){
                continue;
            }

            el.url =  Api.getBimGeneratorUrl(el.prod_id, el.thickness);
            solution.elems[el_id] = el;
        }
        return solution;
    }
    return (
        <ButtonsRow  className="d-flex justify-content-end">
            {oldProj ?
                <ButtonWrap style={{
                            pointerEvents: user ? 'auto':'none',
                            opacity: user ? '1': '0.5'
                        }}>
                    <Button onClick={(e)=>{handleSave(e, true)}} style={{
                        color:definedStyles.color4,
                        backgroundColor:'white'
                    }}>
                        {t('saveAsNew')}
                    </Button>
                </ButtonWrap>
            :''}
            <ButtonWrap style={{
                        // pointerEvents: user ? 'auto':'none',
                        // opacity: user ? '1': '0.5'
                    }}>
                <Button onClick={handleSave}>
                {oldProj ? t('saveChanges') : t('saveProject') }
                </Button>
            </ButtonWrap>
            
        </ButtonsRow>
    )
}

const LCAInfoLabel = styled.div`
    color:white;
    border-radius:8px;
    background-color:gray;
    display:inline;
    padding:4px 12px;
    font-size:12px;
    letter-spacing: 0.1rem;
`;
const ButtonsRow = styled.div`
    margin-top: 50px
`;
const ButtonWrap = styled.div`
    display: inline-block;
    box-sizing: border-box;
    margin-left: 15px;
`;

const Button = styled.button`
	padding: 10px 20px;
    background-color: ${definedStyles.color4};
    color: white;
    border: 1px solid ${definedStyles.color4};
    
    border-radius: 5px;
    min-width:150px;
    // font-family: 'Futura Std';
    font-style: normal;
    font-weight: 650;
    font-size: 20px;
    line-height: 24px;
`;

const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`
export default ExportContainer
