// import lang from "./lang";

export const COUNTRIES = [
    {
        id:1,
        name:'English',
        lang: 'en',
    },
    {
        id:2,
        name:'French',
        lang: 'fr',
    }
]
export const STATES = {
    landing: 0,
    projects_list: 1,
    form: 2,
    details: 3,
    solutions: 4,
    charts: 5,
    export: 6,
};

function getLang(lang) {
    for(let country of COUNTRIES) {
        if(country.lang == lang) {
            console.log(country)
            return country;
        }
    }
    return COUNTRIES[1];
}

var config = {
    currentUserHash: null,
    elementsLastId : 0,
    solutionsLastId : 0,
    activeLang: getLang(new URLSearchParams(window.location.search).get("lang")),
    appTypeOptions : [
        {
            id: 2,
            name: 'Wall',
        },
        {
            id: 1,
            name: 'Attic',
        },
    ],    
    typeOfBuilding: [
        { 
            id: 0,
            name: "--"
        },
        { 
            id: 1,
            name: "Individual house"
        },
        { 
            id: 2,
            name: "Collective building"
        },
        { 
            id: 3,
            name: "Non-residential building"
        },
        { 
            id: 4,
            name: "Industrial"
        },
        { 
            id: 5,
            name: "All buildings"
        },
        { 
            id: 6,
            name: "Others"
        },
    ],
    typeOfProject: [        
        {
            id: 0,
            name: "--"
        },
        {
            id: 1,
            name: "New"
        },
        {
            id: 2, 
            name:"Renovation"
        },
    ],
    impactCriteria:{
        1:{
            id: 1,                          // id 
            type: 'GWP',                    // nazwa skrotowa
            name:'Global warming',          // nazwa pelna
            //unit:'kg CO\u2082-EQ UF',       // jednostka
			unit:'kg CO\u2082-Eq',       // jednostka
            cycles: true,                   // wskaznik czy wartosc jest rozdzielona na cykle
            description: 'descGWP',
			order : 1,
        },
        2:{
            id: 2,
            type: 'FW',
            name:'Fresh water',
            unit:'m\u00B3',
            cycles: true,
            description: 'descFW',
			order : 4,
        },
        3:{
            id: 3,
            type: 'RE',
            name:'Renewable energy',
            unit:'MJ',
            cycles: true,
            description: 'descRE',
			order : 5,
        },
        4:{
            id: 4,
            type: 'NRE',
            name:'Non-renewable energy',
            unit:'MJ',
            cycles: true,
            description: 'descNRE',
			order : 6,
        },
        5:{
            id: 5,
            type: 'WD',
            name:'Waste disposal',
            unit:'kg',
            cycles: true,
            description: 'descWD',
			order : 7,
        },
        6:{
            id: 6,
            type: 'NHW',
            name:'Non-hazardous waste',
            unit:'kg',
            cycles: true,
            description: 'descNHW',
			order : 8,
        },
        7:{
            id: 7,
            type: 'RATIO',
            name:'Ratio of recycled material',
            unit:'%',
            cycles: false,
            description: 'descRATIO',
			order : 2,
        },
        8:{
            id: 8,
            type: 'BIOGENIC',
            name:'Biogenic stored carbon',
            unit:'kg C',
            cycles: false,
            description: 'descBIO',
			order : 3,
        },
        
        // 'IND19' => 1, //GWP
        // 'IND36' => 2, //Use of net fresh water
        // 'IND30' => 3, //use of renewable primary energy ressources
        // 'IND33' => 4, // use of non-renewable primary energy ressources
        // 'IND38' => 5, //Waste disposal
        // 'IND39' => 6, //Non-hazardous waste
    },
    lifeCycle:[
        {
            id: 0, 
            code:'D',
            name: 'Module D',
            text: '',
            color:['#02bd71','#e84b4b'],
            // 'D+' : '#02bd71',
            // 'D-' :'#e84b4b'
        },
        {
            id: 1, 
            code:'C4',
            name: 'C4',
            text:'End of life',
            color:'#ae1d77',
        },
        {
            id: 2, 
            code:'C2',
            name: 'C2',
            text:'Transport',
            color:'#006cb5'
        },
        {
            id: 3, 
            code:'A5',
            name: 'A5',
            text:'Manufacturing',
            color:'#925dbd',
        },
        {
            id: 4, 
            code:'A4',
            name: 'A4',
            text:'Manufacturing',
            color:'#bf79ba',
        },
        {
            id: 5, 
            code:'A13',
            name: 'A1 / A2/ A3',
            text:'Raw minerals extraction',
            color: '#009fed'
        },
    ]
}
console.log(config)

export {config};