export default class Api{
    // _url = window.location.protocol+'//localhost/knauf/bim/index_api.php';
    _url = window.location.protocol+'//database.bim-factory.com/index_api.php';
    
    // _print_url = window.location.protocol+'//test.hybrid.pl/knauf_insulation/carbon/api/print.php';
    static _bim_generator_url = window.location.protocol+'//database.bim-factory.com/bim_generator/form.php';


    getApplications(){
        return fetch(this._url+'?co=get_data&what=carbon_get_applications&country=6')
        .then((response) => response.json())
        
    }

    getProducts(){
		return fetch(this._url+'?co=get_data&what=carbon_get_products&country=6')
        //return fetch(this._url+'?co=get_data&what=carbon_get_products&kz=1&country=6')
        .then((response) => response.json())
        
    }
    getProjects(user_id) {
        return fetch(this._url+'?co=get_data&what=carbon_get_projects&user_id='+user_id)
        .then((response) => response.json())

    }
    
    static getBimGeneratorUrl(prod_id, thickness){
        return Api._bim_generator_url+'?id='+prod_id+'&thickness='+thickness;
    }
    deleteProject(proj_id){
        return fetch(this._url+'?co=get_data&what=carbon_delete_project&proj_id='+proj_id)
        .then((response) => response.json())
    }
    printUrl(){
        return this._url+'?co=get_data&what=carbon_print_project&country=6';
    }
    // }
    saveProject(data) {
        
        return new Promise((resolve, reject) => {
            var httpReq = new XMLHttpRequest(); 
            httpReq.onreadystatechange = () => { 
                if (httpReq.readyState === 4) { 
                    if (httpReq.status === 200) {
                        var data = JSON.parse(JSON.parse(httpReq.responseText, true), true);
                        console.log(data);
                        resolve(data); 
                    } else { 
                        reject(new Error(httpReq.statusText)); 
                    } 
                } 
            }
            var serialized_data = btoa(JSON.stringify(data))
            if(data.proj_id === null || data.proj_id === undefined){
                httpReq.open("POST", this._url+'?co=get_data&what=carbon_save_project', true); 
                httpReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                httpReq.send("data="+serialized_data); 
            }else{
                httpReq.open("POST", this._url+'?co=get_data&what=carbon_edit_project', true); 
                httpReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                httpReq.send("data="+serialized_data+"&proj_id="+data.proj_id); 
            }
            
        });
    }
}