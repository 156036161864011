import React from 'react'
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { definedStyles } from "../../data/definedStyles"
import  arrowRight from "../../assets/icons/button-arrow-right-white.svg"

function NavButton({canProceed, actions, callback}) {

    function ActionButton({ style, action, text, className }){
        if(action == null) {
            return '';
        }
        function setPage(){
            callback(action)

        }
        return <ButtonWrap>
                    <Button style={style} className = {className ? className : ''}
                        onClick={setPage}
                    >
                        <p>{text}</p>
                    </Button>
                </ButtonWrap>
    }


    return (
        <div className="nav-buttons-container">
                <ButtonsRow className="d-flex justify-content-between">
                    {/* {actions.prev ?
                    <ActionButton style={
                        {
                            backgroundColor: 'white',
                            color:'black',

                        }                    
                    } className = {'--back'} action={actions.prev.id} text={actions.prev.text} />
                    :<div></div>} */}
                    <div></div>
                    {actions.next ?
                    <ActionButton                     
                    action={actions.next.id} 
                    text={actions.next.text} style={{
                        pointerEvents:!canProceed?'none':'',
                        opacity:!canProceed?'0.5':'1',
                    }} />
                    :<div></div>}
                </ButtonsRow>
        </div>
    )
}

const ButtonsRow = styled.div`
`;
const ButtonWrap = styled.div`
    display: inline-block;
    box-sizing: border-box;
`;

const Button = styled.button`
    background-color: ${definedStyles.color4};
    color: white;
    border: 1px solid ${definedStyles.color4};
    text-transform: capitalize;
    padding: 15px 43px;
    border-radius: 8px;


    p{
        margin:0px;
        font-family: 'Futura Std';
        text-align:center;
        gap: 10px;
        // width: 152px;
        // height: 54px;
        font-family: 'Futura Std';
        font-style: normal;
        font-weight: 650;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        padding-right:25px;
        position:relative;

        &::after{
            content: url(${arrowRight});
            width: 12px;
            height: auto;
            margin-top: 1px;
            // border-top: 2px solid white;
            // border-right: 2px solid white;
            position: absolute;
            // transform: rotate(45deg) translate(0px, -50%);
            transform: translate(0px, -50%);
            top: 50%;
            right: 0px;
        };
        &.--back::after{        
            border-right: none;
            border-left: 2px solid black;
            transform: rotate(-45deg) translate(0px, -50%);
            top: 50%;
            left: 0px;
            right: auto;
            border-color:black;
        }
    }
    
`;

export default NavButton
