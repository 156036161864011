import React from 'react'
import { Row, Col } from "react-bootstrap";
// import styled from "styled-components";
import { data } from '../../data/data';
import { project } from '../../data/project';
import ProjectForm from '../dataForm/ProjectForm';
import arrow from "../../assets/icons/arrow_down.svg";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

function Info() {
    //console.log(project.form)
    const form = data.form;
    const formValues = project.form;

    const { t } = useTranslation();
    const [visible, setVisible] = React.useState(false);
    //console.log(form)
    function handleClick(e){
        setVisible(!visible)
    }
    return (
    <div style={{
        paddingLeft:'40px',
        paddingRight:'40px',
    }}>
        <Row className="d-flex justify-content-between align-items-center"
            onClick={handleClick}
            style={{
                backgroundColor:'white',
                boxShadow: '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
                borderRadius: '8px',
                paddingTop:10,
                paddingBottom:10,
                marginBottom:20,
                cursor: 'pointer',
            }}>
            <Col style={{
                fontWeight: '650',
                fontSize: '18px',
                lineHeight: '140%',             
                letterSpacing: '0.01em',
                fontFamily: 'Futura Std',
            }}>{t("step4 projectDetails")}</Col>
            <Col xs={'auto'}><Img className={visible ? "--open" : "--close"} src={arrow} /></Col>
        </Row>
        {visible ? 
            <Row>
                <ProjectForm form={form} formValues={formValues} editable={false} />
                
            </Row>
        : <></>}
    </div>
        
    )
}

function getSelectedValue(options, value){
    for(let option of options) {
        if (parseInt(option.id) === parseInt(value)) {
            return option.name;
        }
    }
    return '';
}
const Img = styled.img`
    &.--open{
        transform:rotate(180deg)
    }
`
export default Info
