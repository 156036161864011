import Api from "./api";
import { config } from '../data/config';
import { data } from "../data/data";
import { GWP_CALC_METHOD } from "../data/project";
// import { projects } from "../data/projects";
export default class DataFormatter{
    api = new Api();

    initData(init, user){
        // console.log(init);  
        var promises = [
            !init[0]?this.initApplicationData():true,
            !init[1]?this.initProducts():true,
            !init[2]?this.initSavedProjects(user):true,
        ];
        // console.log(promises)
        return Promise.all(promises)
    }

    initApplicationData(){
        var self = this;
        var dbData = this.api.getApplications();
        
        return new Promise(resolve => {
            dbData.then((dbData) => {
                self.formatApplicationData(dbData)
                resolve(true);
            });
        });
    }
    formatApplicationData(data) {
        console.log(data)
        config.appTypeOptions = data;
    }

    initProducts(){
        var dbData = this.api.getProducts();
		
        return new Promise(resolve => {
            dbData.then((dbData) => {
                //console.log(dbData)
                data.products = dbData;
				//console.log(JSON.stringify(dbData))
                resolve(true);
            });
        })
    }
    

    initSavedProjects(user_id) {
        // console.log(user_id)
        var promise = new Promise(resolve => {
            if(user_id){
                var dbData = this.api.getProjects(user_id);
                dbData.then((response) => {
                    console.log(response)
                    data.user_projects = this.formatProjects(response);
                    resolve(true)
                })
            } else {
                data.user_projects = [];
                resolve(false)
            }
        })
        return promise
    }
    // initSavedProjects(token) {
    //     console.log(token)
    //     var promise = new Promise(resolve => {
    //         // if(user && user.id !== undefined){
    //         if(token !== undefined && token !== null && token !== ''){
    //             const userApi = new userApi;
    //             var userApiPromis = userApi.get_data(token);
    //             userApiPromis.then((response2)=>{
    //                 var dbData = this.api.getProjects(user.id);
    //                 dbData.then((response) => {
    //                     //console.log(response)
    //                     data.user_projects = this.formatProjects(response);
    //                     data.currentToken = response2.token;
    //                     resolve(true);
    //                 })
    //             })
            
                
    //         } else {
    //             data.user_projects = [];
    //             resolve(false)
    //         }
    //     })
    //     return promise
    // }
    formatProjects(response) {
        var result = {};
        for(let project of response) {
            result[project.id] = {};
            var data = JSON.parse(project.data_json, true);
            console.log(data)
            result[project.id] = {
                id:project.id,
                user_id: project.user_id,
                project: {
                    elements:data.elements, solutions:data.solutions, form:data.form, gwp_calc_method:data.gwp_calc_method==undefined ? GWP_CALC_METHOD.static : data.gwp_calc_method
                },
                info:{
                    date: project.creation_date,
                    mod_date:project.modification_date,
                    gwp:{
                        min: project.gwp_min,
                        max: project.gwp_max
                    }
                }
            }
        }
        return result;
    }
}