import React from 'react'
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { project } from '../../data/project';
import { data } from '../../data/data';
import { definedStyles } from '../../data/definedStyles';
import { config } from '../../data/config';
import sharedMethods from '../shared/sharedMethods';
import lang from '../../data/lang';
import star from '../../assets/icons/favorite-star.svg'
import star_active from '../../assets/icons/favorite-star-active.svg'
import { useTranslation } from 'react-i18next';
import inies_ok_icon from '../../assets/icons/inies_aproved.svg'
import inies_not_ok_icon from '../../assets/icons/inies_not_aproved.svg'
import { Alert, AlertTitle } from '@mui/material';
// import star from '../../assets/icons/favorite-star.svg'
// import star from '../../assets/icons/favorite-star.svg'

export default function Summary() {
    
    const [solutions, setSolutions] = React.useState(sortSolutions(project.solutions));

    const elements = project.elements;

    const { t } = useTranslation();
    var index = 1;
    
    function sortSolutions(solutions){
        var favs = solutions.filter((sol)=>{
            return sol.fav
        })
        var other = solutions.filter((sol)=>{
            return !sol.fav
        })
        
        return favs.concat(other);
    }

    function handleFavSol(e,solution){
        solution.fav = !solution.fav;
        var newSolutions = solutions.map((sol)=>{
            if(solution.id == sol.id){
                return solution;
            }else {
                return sol;
            }
        })
        newSolutions = sortSolutions(newSolutions);
        setSolutions(newSolutions)
    }
    return (
        <div style={{
            width: '100%',
            overflowX: 'scroll',
            marginBottom:'50px',
            marginTop:'50px',
        }}>
            <Row style={{
                marginBottom:'30px',}}
            >
                <Col>
                    <Row style={{
                        flexWrap: 'nowrap',
                    }}>
                        <ElemCol>
                            <Row style={{
                                fontSize:'10px',
                                fontWeight: '600',
                                lineHeight: '34px',
                                alignItems: 'center',
                                letterSpacing: '0.05em',
                                // backgroundColor: '#F3F4F6',
                                borderTopLeftRadius: '4px',
                                borderTopRightRadius: '4px',
                                margin:0,
                                width:'100%'
                                }}>
                                
								<CustomHeader  style={{
                                borderTopLeftRadius: '4px'}}>
                                    {t('appType')}
                                </CustomHeader>
                                
								<CustomHeader style={{}}
								>{t('area')} (m<sup>2</sup>)</CustomHeader>
								
                                <CustomHeader style={{
                                borderTopRightRadius: '4px'
                                }}
                                >R (m<sup>2</sup>K/W)</CustomHeader>
                            </Row>
                        </ElemCol>
                        {solutions.map((solution, pos)=>(
                        <SolCol key={'solution_el_'+pos}>
                            <Row style={{
                                fontSize:'12px',
                                fontWeight: '600',
                                lineHeight: '30px',
                                alignItems: 'center',
                                letterSpacing: '0.05em',
                                borderTopLeftRadius: '4px',
                                borderTopRightRadius: '4px',
                                margin:0,
                                width:'100%',
                                backgroundColor: '#F3F4F6',
                                }}>
                                <CustomHeader style={{                                        
                                    borderTopLeftRadius: '4px',
                                    borderTopRightRadius: '4px' ,
                                    // backgroundColor: '#F3F4F6',
                                }}>
                                   {solution.name} {/*t('solution')} {pos+1*/}
                                </CustomHeader>
                                <Col xs={'auto'}>
                                    <Img src={solution.fav ? star_active : star} onClick={(e)=>{handleFavSol(e,solution)}}/>
                                </Col>
                            </Row>
                        </SolCol>
                        ))}
                    </Row>
                    {elements.map((element)=>(
                        <FillRow key={'element_fill_row_'+element.id} solutions={solutions} element={element} pos={index++}/>
                    ))}
                    <Row style={{
                        flexWrap: 'nowrap',
                    }}>
                        <ElemCol style={{
                                }}
                                ><Row></Row> 
                        </ElemCol>
                        {solutions.map((solution,pos)=>(
                            <SolCol style={{
                                }}
                                key={'solution_col_'+pos}>
                                <Row style={{
                                    width:'100%'
                                }}>
                                    <SumGWP solution={solution} />
                                </Row>
                            </SolCol>
                        ))}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
function SumGWP({solution}){
    const { t } = useTranslation();
    var GWPTotal = 0;
	console.log("-----------------TAB ----------------")
	//console.log(JSON.stringify(solution))
	console.log(solution)
	console.log("-----------------TAB ----------------")
    for(let el_index in solution.elems) {
        var elem=solution.elems[el_index];
		console.log("@@@ " + elem.GWP)
        if(elem) {
			console.log("######>>> " + elem.GWP)
            GWPTotal+=elem.GWP
		}
    };
	console.log("-------------------------------------")
	

    return <>
        <Col xs={6}></Col>    
        <Col style={{
            textAlign:'left',
            padding:'5px 10px',
            textTransform:'uppercase',
            fontWeight:'bold',
            fontFamily:'Futura Std',
        }}>
            {t('total')} 
        </Col>
        <Col  style={{
        textAlign:'left',
        padding:'5px 10px',        
        fontSize: '12px',
        lineHeight: '140%',
        letterSpacing: '0.04em',
        color: '#5A5A59',
        }}>
            <p>{t("GWP")} (kg CO<sub>2</sub>-Eq)</p>
            <p style={{
                color:definedStyles.color1,
                fontWeight: 'bold',
                fontFamily:'Futura Std',        
                fontSize: '14px',
                lineHeight: '150%',
                letterSpacing: '0.01em',
            }}>
                {Math.round(GWPTotal*1)/1}
            </p>
        </Col>
        <Col xs={'auto'}><p style={{width:'125px', padding:'10px', content:' '}}></p></Col>
    </>
}

function FillRow({solutions,element, pos}) {
    
    const [showAlert, setAlert] = React.useState(0);

    const { t } = useTranslation();

    function showINIESInfo(event, inies) {
        if(inies !== false){
            if(inies) {
                setAlert(inies);
            }else {
                setAlert(null);
            }
        }else {
            setAlert(0);
        }
        // setTimeout(()=>{setAlert(0)},3000)
    }

    var index=0;
    return <>
    
    {showAlert != 0 ? (
                showAlert != null ?
                
                    <StyledAlert severity="success">
                        <AlertTitle>{t("hoverAlert certifiedByInies Verified Title")}</AlertTitle>
                        {t("hoverAlert certifiedByInies Verified Text")+t(":")} {showAlert}
                    </StyledAlert>
                    :
                    
                    <StyledAlert severity="error">
                    <AlertTitle>{t("hoverAlert certifiedByInies Unverified Title")}</AlertTitle>
                </StyledAlert>
                )
                :
                <></>
    }
        <Row style={{
            flexWrap: 'nowrap'
        }}>
            <ElemCol style={{fontSize:15}}>
                <Row style={{
                    margin:0,
                    width:'100%'
                }}>
                    <CustomCol xs={5}>
                        <RowBar>{sharedMethods.getTypeName(element.app_type)}</RowBar>
                    </CustomCol>
                    <CustomCol>
                        <RowBar>{element.area}</RowBar>
                    </CustomCol>
                    <CustomCol>
                        <RowBar>{t('num',{val:element.R_val})}</RowBar>
                    </CustomCol>
                </Row>
            </ElemCol>
            
            {solutions.map((solution, sol_pos) => (
                solution.elems[element.id] && solution.elems[element.id].prod_id?
                
                <SolCol key={'col_'+element.id+'_'+sol_pos+'_'+pos} style={{fontSize:15}}>
                    <Row style={{
                        margin:0,
                        width:'100%',
                        borderBottom:'1px solid lightgray',
                    }}>
                        <Col>
                            <Row>
                                <SolColLabel xs={6}>{t('product')}</SolColLabel>
                                <SolColLabel>{t('thickness')} (mm)</SolColLabel>
                                <SolColLabel>{t("GWP")} (kg CO<sub>2</sub>-Eq)</SolColLabel>
                            </Row>
                            <Row>
                                <SolColData xs={6}>{sharedMethods.getProdName(solution.elems[element.id].prod_id)}</SolColData>
                                <SolColData>{solution.elems[element.id].thickness}</SolColData>
                                <SolColData style={{
                                    color:definedStyles.color1, 
                                    fontWeight:'bold',
                                }}>{t('num',{val:Math.round(100*solution.elems[element.id].GWP)/100})}</SolColData>
                            </Row>
                            <Row>
                                <SolColLabel xs={'auto'}>{t('lambda')} <span>{t('num',{val:solution.elems[element.id].lambda})}</span> W/mK</SolColLabel>
                                <SolColLabel xs={'auto'}>R: <span>{t('num',{val:solution.elems[element.id].R, minimumFractionDigits: 2})}</span> m<sup>2</sup>K/W</SolColLabel>
                                <SolColLabel xs={'auto'}>
                                    <a style={{
                                        position:'relative',
                                        color:definedStyles.color1
                                    }} href={getExportURL(solution.elems[element.id])} target="_blank">
                                        {/* <img src={open_icon} onClick={()=>{
                                            // exportAction(element)
                                        }}/> */}
                                        Voir produit
                                    </a>
                                    </SolColLabel>
                            </Row>
                        </Col>
                        <Col style={{minWidth:120,paddingRight:20,textAlign:"center"}} xs={'auto'}>
                            <div style={{cursor:'pointer'}}
                            
                                onMouseEnter={(event)=>{showINIESInfo(event,solution.elems[element.id].INIES)}}
                                onMouseLeave={(event)=>{showINIESInfo(event,false)}} 
                            
                            >{
								
                                solution.elems[element.id].INIES? 
                                <Img src={inies_ok_icon} />:
                                <Img src={inies_not_ok_icon} />
                            }</div>
							<div>
<div style={{cursor:'pointer'}} className={`${solution.elems[element.id].creation_method=="+ A2" || solution.elems[element.id].creation_method=="+A2" ? 'mmc_d_02' : 'mmc_d_01'}`} title={infoTxt(solution.elems[element.id].creation_method)}>
	{solution.elems[element.id].creation_method}
</div>
							</div>
                        </Col>
                    </Row>
                </SolCol>
            :
            <SolCol>
                <Row style={{
                        margin:0,
                        width:'100%',
                        borderBottom:'1px solid lightgray',
                    }}>
                    <Col>
                        <Row>
                            <SolColLabel xs={6}>{t('product')}</SolColLabel>
                            <SolColLabel>{t('thickness')} (mm)</SolColLabel>
                            <SolColLabel>{t("GWP")} (kg CO<sub>2</sub>-Eq)</SolColLabel>
                        </Row>
                        <Row>
                            <SolColData xs={6}>---</SolColData>
                            <SolColData>---</SolColData>
                            <SolColData>---</SolColData>
                        </Row>
                        <Row>
                            <SolColLabel xs={'auto'}>{t('lambda')} --- W/mK</SolColLabel>
                            <SolColLabel xs={'auto'}>R: --- m<sup>2</sup>K/W</SolColLabel>
                            <SolColLabel xs={'auto'}></SolColLabel>
                        </Row>
                    </Col>
                </Row>
            </SolCol>
            ))}
        </Row>
        </>
}

function infoTxt(param) {
	var s = "";
	switch(param) {
		default:
			s = "selon norme NF EN 15804+A1";
			break;
		case "+A2":
		case "+ A2":
			s = "selon norme NF EN 15804+A2";
			break;
	}
		
	return s;
}

function getExportURL(solutionElement) {
    if(solutionElement.prod_id == '' || solutionElement.prod_id == null) return null;
    
    var prod = getProductById(data.products, solutionElement.prod_id)
    return prod.website != undefined && prod.website != null && prod.website != '' ? prod.website: null;
}
function getProductById(products, id) {
    for(let product of products) {
        // //console.log(product, id)
        if (product.id_products == id) {
            return product;
        }
    }
    return null;
};
// function getProdName(prod_id){
//     const products = data.products;
//     console.log(products)
//     for(let product of products) {
//         if (product.id_products === prod_id) {
//             return product.name;
//         }
//     }
//     return null;
// }

// function getTypeName(type) {
//     var typeObj = getTypeById(type)
//     if(typeObj === null){
//         return '--';
//     }
//     return typeObj.name 
// }
// function getTypeById(id) {
//     for(let type of config.appTypeOptions) {
//         if(parseInt(type.at_id) === parseInt(id)) {
//             return type;
//         }
//     }
//     return null;
// }


const StyledAlert = styled(Alert)`
    position: fixed;
    z-index: 100;
    border-radius: 8px;
    bottom: 20px;
    right: 20px;
`

const Img = styled.img`
    width:35px;
    height:35px;
    padding:10px;
    border-radius:4px;
    background-color:white;
    cursor:pointer;
    // position: relative;
    // top: -5px;
`;
const SolCol= styled.div`
    width:800px !important;
    min-width:800px !important;
    max-width:800px !important;
    display:inline-block;
    margin-left:40px;
`;
const ElemCol= styled.div`
    width:500px !important;
    min-width:500px !important;
    max-width:500px !important;
    display:inline-block;
`;
const CustomCol=styled(Col)`
    padding-top:30px;
    padding-bottom:30px;
    border-bottom:1px solid lightgray

`;
const CustomHeader=styled(Col)`
    font-family: 'Futura Std';
    overflow: hidden;
    padding:10px 15px;    
    background-color: #F3F4F6;
    // background-color: lightgray;
    // margin-top:5px;
    // margin-bottom:5px;


`;
const SolColData = styled(Col)`
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.01em;
    color: #1B1B1A;
    padding-top:5px;
    padding-bottom:5px;
`
const SolColLabel = styled(Col)`
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: #5A5A59;
    padding-top:4px;
    padding-bottom:4px;

    span{
        color:${definedStyles.color1};
        font-weight:bold;
    }
`
const RowBar = styled.p`
    width:100%;
    margin:0px;
`;
