import React from "react";
// import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Checkbox from "react-custom-checkbox";
import { definedStyles } from "../../data/definedStyles";
import checkmark from '../../assets/icons/check.svg'
function CustomCheckbox(props) {
    var name = props.name
    var value = props.value
    var labelText = props.labelText
    var callback= props.callback
    var style = props.style
    
    function handleChange(value) {
        //console.log(value)
        callback(name, value);
    }

    return (
        <div style={{position:'relative'}}>
            {props.children != undefined ? <>{props.children}</> : <></>}
            <Checkbox 
                name = {name}
                checked = {value}
                
                icon={
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        backgroundColor: definedStyles.color1,
                        alignSelf: "stretch",
                      }}
                    >
                      <img src={checkmark} />
                    </div>
                  }

                onChange = {(value) => {handleChange(value)} }
                borderColor = {definedStyles.color1}

                containerClassName = 'd-flex align-items-start'
                borderRadius={3}
                style={{
                    marginTop:'3px',

                }}
                labelStyle = {style}
                label = {labelText}
            />
        </div>
    );
}
export default CustomCheckbox;
