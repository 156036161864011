import React from 'react'
import { project } from '../../data/project';
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

import download_icon from '../../assets/icons/download.svg'
import Api from '../../api/api';
import sharedMethods from '../shared/sharedMethods';
import { definedStyles } from '../../data/definedStyles';
import { useTranslation } from 'react-i18next';

export default function SolutionPage({solution, pos}) {
    
    const elements = project.elements; 
    const { t } = useTranslation();

    console.log(solution)

    function exportAction(solutionElement) {
    }


    function getExportURL(solutionElement) {
            return Api.getBimGeneratorUrl(solutionElement.prod_id, solutionElement.thickness)
    }

    return (
    <>
        {elements.map((element)=>(
            <Row key={"element_row_for_solution_"+element.id} style={{
              paddingTop:10,
              paddingBottom:10,
              borderBottom:'1px solid lightgray',
            }}
            className="d-flex align-items-center"
            >
                <Col>
                    <Row>
                        <SolColLabel>{t("application")}</SolColLabel>
                        <SolColLabel>{t("product")}</SolColLabel>
                        <SolColLabel>{t("thickness")} (mm)</SolColLabel>
                        <SolColLabel>Lambda (W/mK)</SolColLabel>
                        <SolColLabel>R (m<sup>2</sup>K/W)</SolColLabel>
                        <SolColLabel>{t("GWP")} (kg CO<sub>2</sub> - Eq)</SolColLabel>
                    </Row>
                    
                    <Row key={"element_row_for_solution_"+element.id}>
                        <SolColData>{t(sharedMethods.getTypeName(element.app_type))}</SolColData>
                        <SolColData>{sharedMethods.getProdName(solution.elems[element.id].prod_id)}</SolColData>
                        { solution.elems[element.id] === null || solution.elems[element.id].prod_id === null?<>
                        <SolColData>--</SolColData>
                        <SolColData>--</SolColData>
                        <SolColData>--</SolColData>
                        <SolColData>--</SolColData>
                        </>:<>
                        <SolColData>{t('num',{val:solution.elems[element.id].thickness})}</SolColData>
                        <SolColData>{t('num',{val:solution.elems[element.id].lambda})}</SolColData>
                        <SolColData>{t('num',{val:solution.elems[element.id].R})}</SolColData>
                        <SolColData><span>{t('num',{val:solution.elems[element.id].GWP})}</span></SolColData>
                        </>
                        }
                    </Row>
                    
                </Col>
                { solution.elems[element.id] === null || solution.elems[element.id].prod_id === null? 
                <Col xs={'auto'}>
                    <BIMButton
                    style={{
                        pointerEvents:'none',
                        opacity:'0'
                    }}>
                            BIM 
                            <img src={download_icon}/>
                        </BIMButton>
                </Col>
                :
                    <Col xs={'auto'}>
                        <BIMButton href={getExportURL(solution.elems[element.id])} target="_blank">
                            BIM 
                            <img src={download_icon} onClick={()=>{
                                exportAction(solution.elems[element.id])
                            }}/>
                        </BIMButton>
                    
                    </Col>
                }
            </Row>
        ))}
    </>
    )
}
const BIMButton = styled.a`
    padding: 10px 25px;
    gap: 10px;
    background: ${definedStyles.color1};;
    border-radius: 8px;font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;    
    letter-spacing: 0.01em;    
    color: #FFFFFF;
    text-decoration:none;

    img{
        margin-left: 5px;
        width:18px;        
        position:relative;
        top:-2px;
    }
`
const SolColData = styled(Col)`
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.01em;
    color: #1B1B1A;
    padding-top:10px;
    padding-bottom:10px;

    span{
        color:${definedStyles.color1};
        font-weight:bold;
        font-size: 20px;
        line-height: 27px;
    }
`
const SolColLabel = styled(Col)`
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: #5A5A59;
    padding-top:4px;
    padding-bottom:4px;

    span{
        color:${definedStyles.color1};
        font-weight:bold;
    }
`
