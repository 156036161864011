import React from "react";
import logo from "../assets/images/logo.svg";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";
import UserContainer from "./user/userContainer";
import policy_pdf from "../assets/Politique de gestion des cookies.pdf";
import tou_pdf_fr from "../assets/2022.07.01 - CGU KI Impact.pdf";
import tou_pdf_en from "../assets/21.07.2024 - KI Impact - Terms of Use EN.pdf";
import lang from "../data/lang";
import { useTranslation } from "react-i18next";


function Footer({ mode, callback }) {
    
    const { t } = useTranslation();
	var tou_pdf = tou_pdf_en;
	if(t('lang') == "fr")
		tou_pdf = tou_pdf_fr;
	
	
	
    return (
		
        <FootBar>
            <Container style={{ textAlign: "center"}} fluid>
                
            <Row className="justify-content-between">
                    <Col lg={7} xs={12}>
                        <Row>
                            <Col md="auto" sm={6} xs={6}>
                                <Link className="footer-link"
                                    href="https://www.knaufinsulation.fr/"
                                    target="_blank"
                                >
                                    {t('footerLink1')}
                                </Link>
                            </Col>
                            <Col md="auto" sm={6} xs={6}>
                                <Link  className="footer-link"
                                href={tou_pdf} target="_blank" >
                                    {t('footerLink2')}
                                </Link>
                            </Col>
                            <Col md="auto" sm={6} xs={6}>
                                <Link className="footer-link"
                                    href="https://www.knaufinsulation.fr/politique-de-confidentialite"
                                    target="_blank"
                                >
                                    {t('footerLink3')}
                                </Link>
                            </Col>
                            <Col md="auto" sm={6} xs={6}>
                                <Link  className="footer-link"
                                href='https://www.knaufinsulation.fr/politique-en-matière-de-cookies' target="_blank">
                                    {t('footerLink4')}
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5} xs={12}>
                        <Row>
                        <Col className="comp-info">
                            © 2021 Knauf Insulation. {t('footerBottomText')}
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </FootBar>
    );
}
const FootBar = styled.div`
    padding: 20px;
    .comp-info{
        font-size: 12px; 
        text-align:right;
    }
    @media (max-width: 450px) {
        // padding-top:70px
    }
    @media (max-width: 991px){
        text-align:center;
        .footer-link,
        .comp-info{
            margin-top:3px;
            text-align:center;
        }
    }
    
`;
const Link = styled("a")`
    text-decoration: none;
    color: gray;
    font-size: 12px;
    :hover {
        color: black;
    }
`;

export default Footer;
