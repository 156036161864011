import React from 'react'
import styled from 'styled-components'
import copy_icon from '../../assets/icons/duplicate.svg';
// import trash_icon from '../../assets/images/trash.svg';
import trash_icon from '../../assets/icons/trash.svg'
import { config } from '../../data/config';
import { data } from '../../data/data';
import { project } from '../../data/project';
import {TextField, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import { useTranslation } from 'react-i18next';


const FInput=({value, name, type, id, min, max, callback}) => {
    //console.log('input render')
    function onChange(event){
        // var element = JSON.parse(JSON.stringify(elem));
        if(min!=undefined && event.target.value<min){
            event.target.value=min;
        }else if(max!=undefined && event.target.value > max){
            event.target.value = max;
        }
        callback(event.target.name, event.target.value);
    }

    return <input 
        key={'input_'+name+'_'+id}
        name={name} 
        value={value} 
        type={type}
        onChange={onChange}
        style = {{
            width:"100%",
            padding: '12px 10px',
            borderRadius: '5px',
            borderWidth:'1px',
            fontSize: '15px',
            border: '1px solid lightgray'
        }}
    />
}
const FSelect = ({options, parent, value, name, callback}) => {
    function onChange(event){
        callback(event.target.name, event.target.value)
    }
    
    const { t } = useTranslation();
    return (
    <select 
        name={name} 
        value={value} 
        onChange={onChange} 
        style ={{        
            width:"100%",
            padding: "12px 10px",
            borderRadius: "5px",
            borderWidth:"1px",
            marginTop:'3px',
            marginBottom:'3px',
            fontSize: '15px',
            border: '1px solid lightgray'
        }}
        >
            {options.sort((a,b)=>{ return t(a.name) > t(b.name) ? 1: -1 }).map((o) => (
                <option key={o.at_id} value={o.at_id}>
                    {t(o.name)}
                </option>
            ))}
            
        </select>
    )
}

function ElementRow({no, element, callback}) {
    const [elem, setElem] = React.useState(element);

    const appTypeOptions = config.appTypeOptions;
    React.useEffect(() => {
        callback('edit', elem)
    }, [elem])

    function onClick(action) {
        callback(action, elem)
    }   
    
    function adjustElement(element) {
        var type = getTypeById(element.app_type);
        
        if(project.form.typeOfProject == 2){
            element.R_rec = type.r_rec_renovation
        }else if(project.form.typeOfProject == 1){
            element.R_rec = type.r_rec_new
        }else {
            element.R_rec = null;
        }
        if(element.R_rec === null) {
            element.R_rec = '--';
        }
        
        return element        
    }

    function getTypeById(id){
        for(let type of appTypeOptions){
            if(type.at_id == id){
                return type;
            }
        }
        return null;
    }
    
    function handleInputChange(name, value){
        var element = JSON.parse(JSON.stringify(elem));
        element[name] = value;
        //console.log(value)
        element = adjustElement(element);
        setElem(element)
        // callback('edit', element)
    }
    return (
        window.innerWidth > 767 ? 
            <Tr>
                {/*<Td>#{no}</Td>*/}
                <Td>
                    <FSelect 
                        options={appTypeOptions}
                        callback={handleInputChange}
                        parent={null}
                        value={element.app_type} 
                        name={"app_type"} 
                    />
                </Td>
                {/* <Td>
                    <Select 
                        options={appTypeOptions}
                        callback={handleInputChange}
                        parent={element.app_type}
                        value={element.subtype} 
                        name={"subtype"} 
                    />
                </Td> */}
                <Td style={{
                    
                }}>
                    <FInput 
                        key={"area_"+element.id}
                        callback={handleInputChange}
                        name="area"
                        id={element.id}
                        type='number'
                        value={elem.area} 
                        min={0}
                    />
                </Td>
                
                <Td style={{
                    
                }}>
                    <FInput 
                        key={"R_val_"+element.id}
                        callback={handleInputChange}
                        name="R_val" 
                        id={element.id}
                        type='number'
                        value={elem.R_val} 
                        min={0} max={15}
                    />
                </Td>                
                <Td>{elem.R_rec}</Td>
                <Td>
                    <ControlIcon onClick={()=>onClick('copy')} src={copy_icon} alt="Copy" />
                </Td>
                <Td>
                    <ControlIcon onClick={()=>onClick('delete')} src={trash_icon} alt="Delete"  />
                </Td>
            </Tr> 
        :
        <Tr>
            <Td>#{no}</Td>
            <Td>
                <FSelect 
                    options={appTypeOptions}
                    callback={handleInputChange}
                    parent={null}
                    value={element.app_type} 
                    name={"app_type"} 
                />
            </Td>
            <Td style={{
                
            }}>
                <FInput 
                    key={"area_"+element.id}
                    callback={handleInputChange}
                    name="area"
                    id={element.id}
                    type='number'
                    value={elem.area} 
                    min={0}
                />
            </Td>
            <Td style={{
                
            }}>{elem.R_rec}</Td>
            <Td style={{
                
            }}>
                <FInput 
                    key={"R_val_"+element.id}
                    callback={handleInputChange}
                    name="R_val" 
                    id={element.id}
                    type='number'
                    value={elem.R_val} 
                    min={0} max={15}
                />
            </Td>
            
            <Td>
                    <div>
                        <ControlIcon  onClick={()=>onClick('copy')} src={copy_icon} alt="Copy" />
                    </div>
                    <div>
                        <ControlIcon onClick={()=>onClick('delete')} src={trash_icon} alt="Delete"/>
                    </div>
                </Td>
        </Tr> 
    )
    

}


const ControlIcon = styled.img`

    width: 55px;
    height: 55px;
    background: #FFFFFF;
    /* KI/Line/ON LightBG */
    cursor:pointer;
    border: 1px solid #E6E8F0;
    border-radius: 5px;
    padding: 13px;
    gap: 10px;

`
const Td = styled.td`
    text-align: center;
    padding:12px 12px;
    border-bottom: 1px solid lightgray;
`;
const Tr = styled.tr`
`;



export default React.memo(ElementRow);
