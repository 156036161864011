import React from 'react'

import styled from 'styled-components';
import { definedStyles } from '../../data/definedStyles';
function CustomRadio({callback, name, radio_value, options}) {
    
    const [value, setValue] = React.useState(radio_value);  

    var onChange = function (e) {
        var val = parseInt(e.target.value)
        callback(e.target.name, val);
        setValue(val);
    };

    return (
        <RadioButtonsContainer>
            { options.map((option)=>{
                return <label htmlFor={name+"_"+option.id}>
                    <input
                        id={name+"_"+option.id}
                        type="radio"
                        name={name}
                        value={option.id}
                        checked={option.id == value}
                        onChange={onChange}
                    />
                    <span className="graphical-radio"></span>
                    {option.label}
                </label>
            })}
        </RadioButtonsContainer>
    )
}
// how to style radio buttons?
{/* <ul>
    <li>
        <label>
            <input class="wpProQuiz_questionInput" type="radio" name="question_1_2" value="3" />
            <span class="graphical-radio"></span>
            Non riesco a lasciarlo solo
        </label>
    </li>
</ul>


input[type="radio"] {
    display: none;
}

.graphical-radio {
    background: gray;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

input[type="radio"]:checked + .graphical-radio {
    background: red;
} */}



const RadioButtonsContainer = styled.div`
    label{
        display:block;
        cursor:pointer;
    }
    input[type="radio"] {
        display: none;
    }

    .graphical-radio {
        margin-right:10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 2px solid ${definedStyles.color1};
        position: relative;
        top: 5px;
        cursor:pointer;
    }

    input[type="radio"]:checked + .graphical-radio::after {
        content:'';
        width:10px;
        height:10px;
        border-radius:100%;
        background-color:${definedStyles.color1};
        position: absolute;
        top: 3px;
        left: 3px;
    }
`;





export default CustomRadio